.INCGetInTouch {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 246;
}

.INCGetInTouch_blurDiv {
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(7.1px);
    position: absolute;
    top: 0;
    left: 0;
}

.react-dropdown-select {
    position: relative;
    border: none !important;
    /* width: 35vh !important; */
    justify-content: space-between !important;
    margin-left: 18px;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    /* Optional: space between type number input and the buttons */
}

/* .INCGetintouch_inputfeilds_single .react-dropdown-select {
    width: 77vh !important;

} */

.react-dropdown-select-item-selected {
    background: #767676 !important;
}

.react-dropdown-select svg {
    margin-left: 0px;
    margin-right: 5px !important;
}

.react-dropdown-select-dropdown-handle {
    color: #fff;
    margin: 0 !important;
}

.react-dropdown-select:focus-within {
    outline: 0;
    box-shadow: none !important;
}

.react-dropdown-select-clear {
    line-height: 25px;
    margin: 0 10px;
    cursor: pointer;
    color: #ffffff !important;
}

.react-dropdown-select-content {
    color: #ffffff;
}

.react-dropdown-select-input::placeholder {
    color: rgba(255, 255, 255, 0.4);
}

.react-dropdown-select-dropdown {
    background-color: #020a14 !important;
    color: #ffffff;
    text-align: left;
    width: 100% !important;
}

.react-dropdown-select-dropdown::-webkit-scrollbar {
    height: 3px;
}


.react-dropdown-select-input {
    color: #fff;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 19.5px;
    text-align: left;
    width: 100% !important;
}

.INCGetintouch_container {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.INCGetintouch_form_container {
    height: 85%;
    width: 85%;
    background: black;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}


.INCGetintouch_asset_container {
    height: 100%;
    width: 35%;
    background: rgba(12, 20, 29, 1);
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.INCGIT_lower_asset_background {
    height: 40%;
    width: 100%;
    background: rgba(255, 255, 255, 0.04);
}

.INCGetintouch_asset_container img {
    position: absolute;
    top: 18%;
}

.INCGetintouch_form_layout {
    width: 65%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(2, 10, 20, 1);
    position: relative;
}

.INCGetintouch_form_layout_container {
    height: 90%;
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2%;
}

.INCGetintouch_input_feild_container {
    height: 70%;
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 3%;
}

.INCGetintouch_form_heading_container {
    width: 37.6%;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
    display: flex;
    height: 40px;
    justify-content: center;
    color: var(--primary-text-color);
    border-bottom: 2px solid rgba(255, 255, 255, 0.12);
}

.INCGetintouch_inputfeilds_single {
    background: rgba(255, 255, 255, 0.06);
    width: 100%;
    height: 55px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.INCGetintouch_inputfeilds_part {
    width: 100%;
    height: 55px;
    display: flex;
    gap: 5%;
}

.INCGetintouch_inputfeild_division_part {
    height: 100%;
    width: 47.5%;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.06);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.INCGetintouch_inputfeilds_single.extract {
    height: 110px;
}

.INCGetintouch_input {
    height: 90%;
    padding: 0px;
    margin: 0px;
    /* padding-inline: 0px !important; */
    border: 0px solid;
    background: transparent;
    margin-left: 25px;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 19.5px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    width: 72%;
    resize: none;
}

.INCGetintouch_input:focus {
    outline: none;
}

.INCGetintouch_input::placeholder {
    color: rgba(255, 255, 255, 0.4);
}

.INCGetintouch_input_feild_container svg {
    margin-right: 25px;
}

input.INCGetintouch_input.name {
    width: 85%;
}

.INCGetintouch_input.message {
    height: 85% !important;
    width: 90%;
}

.INCGetintouch_input.message::-webkit-scrollbar {
    width: 5px;
}

.INCGetintouch_inputfeilds_single .select_option {
    color: red;
}

.react-dropdown-select-content {
    width: 90%;
    white-space: nowrap;
    flex-wrap: nowrap !important;
    text-overflow: ellipsis;
    overflow: hidden;
}

.INCGetintouch_submit_container {
    height: 50px;
    width: 150px;
    /* background: rgba(255, 255, 255, 0.12); */
    border-radius: 8px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.0024em;
    text-align: left;
    color: var(--primary-text-color);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 25px;
}

.errorGredient {
    border: 1px solid transparent;
    /* Solid border with transparent color */
    border-image-source: linear-gradient(90deg, #EE4661 0%, #BB2B3A 100%);
    border-image-slice: 1;
    /* Ensures the entire gradient is used */
    border-radius: 10px;
    /* Adjust the radius to your preference */
}

.INCGetintouch_inputfeilds_single select,
.INCGetintouch_inputfeild_division_part select {
    height: 80%;
    width: calc(100% - 25px);
    background: transparent;
    border: 0px solid;
    color: #ffffff;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    margin-right: 25px;
    padding-left: 25px;
}

.INCGetintouch_inputfeilds_single select:focus,
.INCGetintouch_inputfeild_division_part select:focus {
    outline: none;
}

.INCGetintouch_inputfeilds_single option,
.INCGetintouch_inputfeild_division_part option {
    background-color: #f0f0f0;
    /* Background color of the option elements */
    color: #333;
    width: 50%;
    /* Text color of the option elements */
    box-sizing: border-box;
    /* Ensures padding is included in the width */
}

/* Hover state for the option elements */
.INCGetintouch_inputfeilds_single option:hover,
.INCGetintouch_inputfeild_division_part option:hover {
    background-color: #ddd;
    /* Background color on hover */
}

/* Selected state for the option elements */
.INCGetintouch_inputfeilds_single option:checked,
.INCGetintouch_inputfeilds_single option:focus,
.INCGetintouch_inputfeilds_single option:active,
.INCGetintouch_inputfeild_division_part option:checked,
.INCGetintouch_inputfeild_division_part option:focus,
.INCGetintouch_inputfeild_division_part option:active {
    background-color: #bbb;
    /* Background color when selected */
    color: #fff;
    /* Text color when selected */
}

.INCGetintouch_form_layout_container::-webkit-scrollbar {
    display: none;
}

.INCGetintouch_form_cancel {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.INCGetintouch_form_layout_container_scroll {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 2%;
}



.button {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: rgba(255, 255, 255, 0.12);
    color: #fff;
    font-size: 18px;
    border-radius: 40px;
    text-align: center;
    box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.4);
    position: relative;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 1s;
}

.button.btnColor {
    background: linear-gradient(180deg, #EE4661 41.09%, #92161A 145.93%);

}

.INCGetintouch_submit_container .check-box {
    width: 35%;
    height: 100%;
    border-radius: 40px;
    box-shadow: 0 0 12px -2px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    right: -40px;
    opacity: 0;
}

.INCGetintouch_submit_container .check-box svg {
    width: 55%;
    margin: 13px;
}

.INCGetintouch_submit_container svg path {
    stroke-width: 3;
    stroke: #fff;
    stroke-dasharray: 34;
    stroke-dashoffset: 34;
    stroke-linecap: round;
}

.button.btnColor.active {
    background: linear-gradient(180deg, #00C2FF 0%, #0083CC 100%);
    transition: 1s;
}

.active .check-box {
    right: 0;
    opacity: 1;
    transition: 1s;
}

.active p {
    margin-right: 35%;
    transition: 1s;
}

.active svg path {
    stroke-dashoffset: 0;
    transition: 1s;
    transition-delay: 1s;
}

@media screen and (max-width:1367px) {
    .INCGetintouch_asset_container img {
        position: absolute;
        top: 13%;
        width: 75%;
    }

    .INCGetintouch_submit_container {
        height: 40px;
        width: 130px;
    }
}

@media screen and (max-width:1080px) {
    .INCGetintouch_asset_container {
        display: none;
    }

    .INCGetintouch_form_layout {
        width: 100%;
    }

    .INCGetintouch_form_container {
        height: 70%;
        width: 85%;
    }

    .INCGetintouch_form_layout_container {
        height: 90%;
        width: 75%;
    }

    .INCGetintouch_input_feild_container {
        height: 75%;
        width: 100%;
        gap: 6%;
    }

}


@media screen and (max-width:540px) {
    .INCGetintouch_form_container {
        height: 90vh;
        /* margin-bottom: 12%; */
        width: 87%;
    }

    .react-dropdown-select-clear {
        position: relative;
        z-index: 5;
    }

    .INCGetintouch_form_cancel {
        position: sticky;
        top: 10px;
        left: 90%;
    }

    .INCGetintouch_form_heading_container {
        width: 50.6%;
        line-height: 40px;

    }

    .INCGetintouch_form_layout_container {
        height: unset;
    }

    .INCGetintouch_form_layout {
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .INCGetintouch_form_layout_container {
        height: 90%;
        width: 90%;
        overflow: scroll;
    }

    .INCGetintouch_inputfeilds_part {
        width: 100%;
        height: unset;
        display: flex;
        gap: 20px;
        flex-direction: column;
    }

    .INCGetintouch_input_feild_container {
        height: unset;
        gap: 20px;
    }

    .INCGetintouch_inputfeild_division_part {
        height: 55px;
        width: 100%;
    }

    .INCGetintouch_submit_container {
        height: 35px;
        width: 122px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .INCGetintouch_form_layout_container_scroll {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        width: 90%;
    }

    .INCGetintouch_submit_container {
        height: 70px;
        width: 122px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .react-dropdown-select-input::placeholder {
        font-family: Poppins;
        font-size: 13px;
        font-weight: 500;
        line-height: 19.5px;
        text-align: left;
        color: #FFFFFF66;
    }

    input::placeholder {
        font-family: Poppins;
        font-size: 13px;
        font-weight: 500;
        line-height: 19.5px;
        text-align: left;
        color: #FFFFFF66;
    }

    .react-dropdown-select-content span,
    .react-dropdown-select-content input {
        font-family: Poppins;
        font-size: 15px;
        font-weight: 500;
        line-height: 19.5px;
    }

    .react-dropdown-select-content input::placeholder {
        font-family: Poppins;
        font-size: 15px;
        font-weight: 500;
        line-height: 19.5px;
    }
}
.INCPlacementFeature {
    height: 100%;
    width: 100%;
}

.INCPlacementFeature_container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.INCPlacementFeature_box {
    width: 87%;
    height: 62.5%;
    background: rgba(2, 10, 20, 1);
    border-radius: 35px;
    border: 1px solid rgba(62, 62, 62, 1)
}

.INCPlacementFeature_box_left {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 7%;
    padding-left: 5%;
}

.INCPlacementFeature_box {
    width: 87%;
    height: 62.5%;
    background: rgba(2, 10, 20, 1);
    border-radius: 35px;
    border: 1px solid rgba(62, 62, 62, 1);
    display: flex;
    align-items: center;
    flex-direction: row;
}

.INCPlacementFeature_box.feature2 {
    flex-direction: row-reverse;
}

.INCPlacementFeature_box.feature2 .INCPlacementFeature_box_left {
    margin-right: 5%;
}

.INCPlacementFeature_box_right {
    width: 35%;
    width: 50%;
}

.INCPlacementFeature_heading {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: -0.002em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}

.INCPlacementFeature_heading2 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -0.0024em;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
}

.INCPlacementFeature_feature_programs {
    display: flex;
    gap: 2%;
}

.INCPlacementFeature_li {
    height: 110px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.002em;
    text-align: left;
    color: var(--primary-text-color);
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: max-content;
    justify-content: center;
    padding: 0px 26px;
    border: 1px solid rgba(62, 69, 77, 1);
    border-radius: 15px;
}

.INCPlacementFeature.feature2 .INCPlacementFeature_box_left {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 7%;
    padding-left: 0%;
}

@media screen and ((min-width:1025px) and (max-width:1367px)) {

    .INCPlacelan_heading,
    .INCPlacelan_heading.heading2 {
        font-size: 40px;
        line-height: 50.75px;
    }

    .INCPlacelan_heading.heading3 {
        font-size: 45px;
        line-height: 55.9px;
    }

    .INCPlacelan_text {
        font-size: 18px;
        line-height: 30.5px;
    }

    .INCPlacementFeature_heading2 {
        font-size: 16px;
        line-height: 32px;
    }

    .INCPlacementFeature_heading {
        font-size: 28px;
        line-height: 40px;
    }

    .INCPlacementFeature_li {
        font-size: 16px;
        line-height: 22.2px;
    }

    .INCPlacementFeature_li svg {
        width: 30px;
        height: auto;
    }

    .INCPlacementFeature_box_left {
        gap: 5%;
    }

    .INCPlacementFeature_subHeading {
        font-size: 18px;
        line-height: 25px;
    }

    .INCPlacementFeature_box_right img {
        width: 52%;
        height: auto;
    }

    .INCPlacelan_part.part2 img {
        width: 67%;
        margin-left: 10%;
    }

}

@media screen and (max-width:1024px) {

    .INCPlacementFeature_box_left,
    .INCPlacementFeature.feature2 .INCPlacementFeature_box_left {
        width: 90%;
        height: 55%;
    }

    .INCPlacementFeature_box {
        flex-direction: column;
    }

    .INCPlacementFeature_box {
        width: 87%;
        height: 80%;
    }

    .INCPlacementFeature_box_left {
        padding-left: 0%;
    }

    .INCPlacementFeature_heading {
        text-align: center;
    }

    .INCPlacementFeature_heading2 {
        text-align: center;

    }

    .INCPlacementFeature_li {
        align-items: center;
        text-align: center;
    }

    .INCPlacementFeature_box.feature2 {
        flex-direction: column-reverse;
    }

    .INCPlacementFeature_subHeading {
        text-align: center;
    }

    .INCPlacementFeature_feature_programs {
        justify-content: center;
    }
}

@media screen and (max-width:620px) {
    .INCPlacementFeature_box {
        height: 90%;
    }

    .INCPlacementFeature_heading {
        font-size: 18px;
        line-height: 28px;
    }

    .INCPlacementFeature_heading2 {
        font-size: 12px;
        line-height: 21.6px;
    }

    .INCPlacementFeature_feature_programs {
        gap: 2%;
        flex-direction: column;
        align-items: center;
    }

    .INCPlacementFeature_li svg {
        width: 24.44px;
        height: auto;
    }

    .INCPlacementFeature_feature_programs {
        gap: 10px;
    }

    .INCPlacementFeature_li {
        font-size: 10px;
        line-height: 14.2px;
        gap: 10px;
        width: 210px;
        height: 61px;
    }

    .INCPlacementPage_dot_container {
        left: 1vw;
        top: 14vh;
        display: flex;
        flex-direction: column;
    }

    .INCPlacementFeature_box_left {
        padding-left: 0%;
        height: 60%;
        gap: 5%;
    }

    .INCPlacementFeature_box_right {
        width: 100%;
    }

    .INCPlacementFeature_box_right img {
        width: 75%;
    }

    .INCPlacementFeature_box_right {
        width: 100%;
        height: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .INCPlacementFeature_box.feature2 {
        flex-direction: column;
    }

    .INCPlacementFeature_subHeading {
        font-size: 15px;
        line-height: 25px;
    }
}
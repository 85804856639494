.INCBottomNavbar {
    width: 100%;
    height: 20vh;
    background: rgba(3, 15, 30, 1);
    position: relative;
    z-index: 1;
}

.INCBottomNavbar_container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.INCBottomNavbar_part {
    width: 90%;
    height: 50%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid rgba(234, 238, 243, 0.2);
    padding-bottom: 5px;
}

.INCBottomNavbar_google {
    width: 150px;
    height: 40px;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.INCBottomNavbar_google svg {
    height: 22px;
    width: auto;
    margin: 0px 12px;
    margin-left: 20px;
}

.INCBottomNavbar_google .INC_platformName {
    font-family: Open Sans;
    font-size: 13.33px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: black;
}

.INCBottomNavbar_app_download_button_container {
    display: flex;
    gap: 15px;
    flex-direction: row;
}

.INCBottomNavbar_app_download_heading {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
}


.INCBottomNavbar_text {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
    cursor: pointer;
}

.INCBottomNavbar_right_container svg {
    width: 40px;
    height: auto;
    margin-left: 30px;
}

.INCAppdownload_footer_lower_part_content {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    text-align: center;
    color: #ffffff;
}

.INCBottomNavbar_left_container {
    display: flex;
    color: var(--primary-text-color);
    align-items: flex-end;
    gap: 5%;
    width: 52%;
}

@media screen and (max-width:1367px) {
    .INCBottomNavbar_app_download_heading {
        font-size: 13px;
    }

    .INCBottomNavbar_google {
        width: 120px;
        height: 32px;
    }

    .INCBottomNavbar_google svg {
        height: 13px;
    }

    .INCBottomNavbar_google .INC_platformName {
        font-size: 10.33px;
    }

    .INCBottomNavbar_text {
        font-size: 15px;
    }

    .INCAppdownload_footer_lower_part_content {
        font-size: 15px;
    }

    .INCBottomNavbar_right_container svg {
        width: 30px;
        margin-left: 23px;
    }

    .INCBottomNavbar_app_download_button_container {
        margin-bottom: 10px;
    }

    .INCBottomNavbar {
        height: 25vh;
    }
}

@media screen and (max-width:1024px) {
    .INCBottomNavbar {
        height: 50vh;
    }

    .INCBottomNavbar_left_container {
        flex-direction: column;
        align-items: center;
    }

    .INCBottomNavbar_app_info_holder {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .INCBottomNavbar_part {
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 15%;
    }

    .INCBottomNavbar_app_info_holder {
        gap: 10%;
    }

    .INCBottomNavbar_left_container {
        gap: 15%;
    }

    .INCBottomNavbar_right_container svg {
        width: 40px;
    }

    .INCAppdownload_footer_lower_part_content {
        font-size: 20px;
    }

    .INCBottomNavbar_text {
        font-size: 20px;
    }

    .INCBottomNavbar_left_container {
        gap: 15%;
        height: 62%;
    }

    .INCBottomNavbar_part.part1 {
        height: 70%;
    }
}

@media screen and (max-width:620px) {
    .INCAppdownload_footer_lower_part_content {
        font-size: 14px;
    }

    .INCBottomNavbar_text {
        font-size: 10px;
    }

    .INCBottomNavbar_app_download_button_container {
        gap: 10px;
        flex-direction: column;
    }

    .INCBottomNavbar {
        height: 60vh;
    }

    .INCBottomNavbar_app_info_holder {
        gap: 0%;
    }

    .INCBottomNavbar_left_container {
        gap: 0%;
        height: unset;
    }

    .INCBottomNavbar_right_container svg {
        width: 30px;
    }

    .INCBottomNavbar_part.part1 {
        height: 60%;
    }

    .INCBottomNavbar_part {
        height: 40%;
    }
}
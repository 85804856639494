.INCProjectDetail {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    overflow: hidden;
}

.INCProjectDetail_container {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(0 0 0 / 20%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.INCProjectDetail_holder {
    width: 550px;
    height: 700px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
}


.INCProjectDetail_title_holder .INCFlutterProject_name {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 600;
    line-height: 27.5px;
    text-align: left;
}

.INCProjectDetail_title_holder .INCFlutterProject_logo_similar img {
    margin-right: 15px;
    width: 50px;
    background: #5d5d5d;
    border-radius: 7px;
}

.INCProjectDetail_description {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.03em;
    text-align: left;
}

.INCProjectDetail_text_content_holder .INCFlutterProject_logo_similar {
    margin-top: 15px;
}

.INCProjectDetail_description {
    margin-top: 15px;
    margin-right: 3%;
}

.INCProjectDetail_container {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(0 0 0 / 20%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.INCProjectDetail_holder svg {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 21;
}

.INCProjectDetail_holder svg {
    position: absolute;
    right: 4%;
    top: 2%;
    z-index: 21;
}

.INCProjectDetail_text_content_holder {
    width: 95%;
    margin-left: 5%;
    text-align: left;
    overflow: scroll;
}

/* Target WebKit-based browsers */
.INCProjectDetail_text_content_holder::-webkit-scrollbar {
    width: 5px;
    /* width of the scrollbar */
    position: absolute;
    left: 0;
}

/* Track */
.INCProjectDetail_text_content_holder::-webkit-scrollbar-track {
    background: transparent;
    /* color of the track */
}

/* Handle */
.INCProjectDetail_text_content_holder::-webkit-scrollbar-thumb {
    background: #cde2fa;
    border-radius: 15px;
    /* color of the scrollbar handle */
}

/* Handle on hover */
.INCProjectDetail_text_content_holder::-webkit-scrollbar-thumb:hover {
    background: hsl(212, 100%, 67%);
    /* color of the scrollbar handle when hovered */
}

@media screen and (max-width:1600px) {
    .INCProjectDetail_holder {
        width: 440px;
        height: 650px;
    }

    .INCProjectDetail_thumbnail_holder img {
        width: 100%;
        height: auto;
    }

}

@media screen and (max-width:620px) {

    .INCProjectDetail_holder {
        width: 90%;
        height: 90%;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 15px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .INCProjectDetail_thumbnail_holder img {
        width: 100%;
        height: 100%;
    }

    .INCProjectDetail_title_holder .INCFlutterProject_logo_similar img {

        width: 40px;
    }

    .INCProjectDetail_description {
        margin-top: 15px;
        margin-right: 4%;
    }

    .INCProjectDetail_title_holder .INCFlutterProject_name {
        margin-top: 15px;
    }
}
.INCWhatisincubator .swiper {
    width: 545px;
    /* height: 300px; */
    padding: 50px;
    margin: 0;
}

.INCWhatisincubator .swiper-slide {
    background-position: center;
    background-size: cover;
}

.INCWhatisincubator .swiper-slide img {
    display: block;
    width: 545px;
}

.INCWhatisincubator_container {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.INCWhatisincubator_boxes {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}

.INCWhatisincubator_heading {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 600;
    line-height: 55px;
    text-align: left;
    color: #ffffff;
}

.INCWhatisincubator_text {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #FFFFFFCC;
    margin-top: 25px;
}

.INCWhatisincubator_text_feilds_container {
    width: 427px;
    margin-left: 170px;
}

.INCWhatisincubator_box1 {
    width: 80%;
    height: 602px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("/public/assets/images/about/whatisincubators/backgroundTrapazoid.png");
    display: flex;
    align-items: center;
}

.INCWhatisincubator_box2 {
    display: flex;
    position: absolute;
    /* width: 73%; */
    height: 100%;
    left: 55%;
}

@media screen and (max-width:1600px) {
    .INCWhatisincubator_box1 {
        width: 73%;
        height: 555px;

    }

    .INCWhatisincubator_boxes {
        width: 75%;
    }

    .INCWhatisincubator_heading {
        font-size: 33px;
        line-height: 48px;

    }

    .INCWhatisincubator_heading svg {
        height: 230px;
        width: auto;
    }

    .INCWhatisincubator_text_feilds_container {
        width: 427px;
        margin-left: 170px;
    }

    .INCWhatisincubator_heading svg {
        height: auto;
        width: 200px;
    }

    .INCWhatisincubator_heading {
        font-size: 33px;
        line-height: 48px;
        display: flex;
        align-items: center;
    }

    .INCWhatisincubator_text {
        font-size: 18px;
        line-height: 34px;

    }

    .INCWhatisincubator_text_feilds_container {
        margin-left: 110px;
    }

    .INCWhatisincubator .swiper-slide img {
        width: 480px;
    }



    .INCWhatisincubator .swiper {
        padding: 15px;
    }

    .INCWhatisincubator_box2 {

        left: 50%;
    }

    .INCWhatisincubator_box1 {
        width: 85%;
        height: 488px;
    }

    .INCWhatisincubator {
        margin-top: 30px;
    }

    .INCWhatisincubator_boxes {
        width: 70%;
    }

    .INCWhatisincubator_text_feilds_container {
        margin-left: 90px;
    }
}

@media screen and (max-width:1367px) {
    .INCWhatisincubator {
        margin-top: 66px;
    }

    .INCWhatisincubator_boxes {
        width: 65%;
    }

    .INCWhatisincubator_box1 {
        width: 70%;
        height: 400px;
    }

    .INCWhatisincubator_box1 {
        width: 70%;
        height: 435px;
    }

    .INCWhatisincubator_heading {
        font-size: 25px;
        line-height: 35px;
    }

    .INCWhatisincubator_heading svg {
        height: auto;
        width: 160px;
    }

    .INCWhatisincubator_text {
        font-size: 15px;
        line-height: 27px;
        margin-top: 20px;
    }


    .INCWhatisincubator_text_feilds_container {
        width: 360px;
        margin-left: 60px;
    }

    .INCWhatisincubator .swiper-slide img {
        width: 400px;
    }

    .INCWhatisincubator .swiper {
        padding: 15px;
        margin-top: 10px;
    }
}

@media screen and (max-width:1025px) {
    .INCWhatisincubator_boxes {
        width: 88%;
    }

    .INCWhatisincubator_box1 {
        width: 70%;
        height: 470px;
    }

    .INCWhatisincubator .swiper {
        padding: 15px;
        margin-top: 5%;
    }

    .INCWhatisincubator {
        margin-top: 0px;
    }

}

@media screen and (max-width:840px) {
    .INCWhatisincubator_boxes {
        width: 92%;
    }

    .INCWhatisincubator_text_feilds_container {
        width: 300px;
        margin-left: 50px;
    }

    .INCWhatisincubator_box2 {
        left: 47%;
    }

    .INCWhatisincubator .swiper-slide img {
        width: 370px;
    }

    .INCWhatisincubator_box1 {
        width: 75%;
        height: 440px;
    }

    .INCWhatisincubator_boxes {
        width: 88%;
    }

    .INCWhatisincubator_text {
        font-size: 14px;
        line-height: 22px;
        margin-top: 20px;
    }

    .INCWhatisincubator_heading svg {
        height: auto;
        width: 145px;
    }

    .INCWhatisincubator_heading {
        font-size: 22px;
        line-height: 33px;
    }

    .INCWhatisincubator_text_feilds_container {
        width: 270px;
        margin-left: 50px;
    }

    .INCWhatisincubator {
        margin-top: 80px;
    }
}

@media screen and (max-width:620px) {
    .INCWhatisincubator_boxes {
        width: 88%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .INCWhatisincubator_heading svg {
        height: auto;
        width: 112px;
    }

    .INCWhatisincubator_heading {
        font-size: 25px;
        line-height: 55px;
    }

    .INCWhatisincubator_text {
        font-size: 12px;
        line-height: 21px;
        margin-top: 20px;
    }

    .INCWhatisincubator_text_feilds_container {
        width: 270px;
        margin-left: 25px;
    }

    .INCWhatisincubator_box1 {
        width: 100%;
        height: 325px;
    }

    .INCWhatisincubator_box2 {
        left: unset;
        position: relative;
        height: unset;
        width: 100%;
    }

    .INCWhatisincubator .swiper {
        padding: 0px;
        width: 100%;
    }

    .INCWhatisincubator .swiper-slide img {
        width: 100%;
    }

    .INCWhatisincubator_text_feilds_container {
        width: 89%;
        margin-left: 25px;
    }

    .INCWhatisincubator_heading svg {
        height: auto;
        width: 142px;
    }
}
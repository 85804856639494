.INCSyllabus {
    width: 100%;
}

.INCSyllabus_container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.INCSyllabus_uppar_part {
    width: 100%;
    /* height: 7.57vh; */
    width: 85%;
    margin-bottom: 5%;
}

.INCSyllabus_pagename {
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 50px;
    text-align: left;
    color: var(--primary-text-color);
}

.INCSyllabus_heading {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 600;
    line-height: 55px;
    letter-spacing: -0.0024em;
    text-align: left;
    font-size: clamp(2.3rem, 3.5vw, 2.3em);
    color: var(--primary-text-color);
}

.INCSyllabus_heading span {
    color: rgba(0, 194, 255, 1);
}

.INCSyllabus_moto {
    font-family: Poppins;
    font-size: 1.7rem;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: -0.0024em;
    text-align: left;
    color: rgba(255, 255, 255, 0.7);
}

.INCSyllabus_lower_part {
    width: 85%;
    display: flex;
    flex-direction: row;
}

.INCSyllabus_phases_tab_container {
    width: 35%;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 1vh;
    top: 175px;
    position: sticky;
}

.INCSyllabus_phase_tab_list1 {
    width: 97%;
    background: #020a14;
    height: 100px;
    border-radius: 10px;
    /* opacity: 0px; */
    /* background: linear-gradient(90deg, #6557DD 0.01%, rgba(2, 10, 20, 0) 100%); */
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    padding-top: 10px;
}

.INCSyllabus_phase_tab_list1_svg_container {
    width: 18%;
}

.INCSyllabus_phase_tab_list1_text_heading {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    line-height: 35px;
    letter-spacing: -0.0024em;
    text-align: left;
    color: #FFFFFF;
}

.INCSyllabus_phase_tab_list1_text_heading span {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: unset;
    letter-spacing: -0.0024em;
    text-align: left;
    margin-left: 10px;
    color: #00C2FF;
}

.INCSyllabus_phase_tab_list1_text_purpose {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: -0.0024em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}

.INCSyllabus_phase_info_container {
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.INCSyllabus_phase_info_box {
    width: 95%;
    border: 1px solid rgba(83, 83, 83, 1);
    border-radius: 20px;
    /* border: 1px solid; */
    margin-bottom: 3%;
}

.INCSyllabus_phase_info_content_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.INCSyllabus_phase_info_heading {
    width: 94%;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 400;
    line-height: 90px;
    letter-spacing: -0.0024em;
    text-align: left;
    color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid rgba(83, 83, 83, 1);
}

.INCSyllabus_phase_info_text_content {
    width: 95%;
    padding-left: 15px;
    margin-top: 2%;
}

.INCSyllabus_phase_info_text_content_li {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -0.0024em;
    text-align: left;
    margin-bottom: 3%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: rgba(255, 255, 255, 0.8);
}

.INCSyllabus_phase_info_heading .span1 {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: -0.0024em;
    text-align: left;
    margin-right: 5px;
    white-space: nowrap;
}

.INCSyllabus_phase_info_heading .span2 {
    color: rgba(0, 194, 255, 1);
    white-space: nowrap;
}

.INCSyllabus_phase_tab_list1.in_view {
    background: linear-gradient(90deg, #6557DD 0.01%, rgba(2, 10, 20, 0) 100%);

}

.INCSyllabus_phases_tabs_action_container {
    width: 100%;
    height: -moz-fit-content;
    height: 60vh;
    display: flex;
    flex-direction: column;
    gap: 3vh;
    overflow: scroll;
    position: relative;
}

.INCSyllabus_phases_tabs_action_container::-webkit-scrollbar {
    width: 0px;
}

.INCSyllabus_gredient_box {
    background: linear-gradient(180deg, rgba(2, 10, 20, 0) 0%, rgba(2, 10, 20, 0.81) 64.25%, #020A14 100%);
    position: absolute;
    width: 100%;
    height: 25%;
    bottom: 0;
}

.INCSyllabus_curriculum_button {
    border-radius: 30px 0px 0px 0px;
    margin-left: 9%;
    width: 288px;
    height: 60px;
    padding: 13px 30px 13px 30px;
    gap: 14px;
    border-radius: 30px;
    /* opacity: 0px; */
    background: rgba(255, 255, 255, 0.2);
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.0024em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    border: 0px solid;
}

.INCSyllabus_curriculum_button:hover {
    background: linear-gradient(180deg, #EE4661 41.09%, #92161A 145.93%);
    transition: 1s ease, opacity 0.3s ease, transform 0.3s ease;
}

@media screen and (max-width:1600px) {
    .INCSyllabus_moto {
        font-size: 1.4rem;
        line-height: 45px;
    }

    .INCSyllabus_phase_info_text_content_li {
        margin-bottom: 1%;
    }

    .INCSyllabus_phases_tab_container {
        width: 55%;

    }

    .INCSyllabus_phases_tabs_action_container {
        gap: 2vh;
    }

    .INCSyllabus_phase_info_heading {
        width: 94%;
        font-size: 17px;
        line-height: 30px;
    }

    .INCSyllabus_phase_info_heading .span1 {
        font-size: 22px;
        line-height: 50px;
    }

    .INCSyllabus_phase_info_heading .span2 {
        width: 20%;
        display: flex;
        padding-top: 10px;
    }
}

@media screen and (max-width:1367px) {
    .INCSyllabus_pagename {
        font-size: 1.2rem;
        line-height: 45px;
    }

    .INCSyllabus_heading {
        font-size: 40px;
        line-height: 55px;
        font-size: clamp(2rem, 3.5vw, 2em);
    }

    .INCSyllabus_moto {
        font-size: 1.4rem;
        line-height: 45px;
    }

    .INCSyllabus_phases_tab_container {
        top: 150px;
    }

    .INCSyllabus_phase_info_heading .span1 {
        font-size: 20px;
        line-height: 50px;
    }

    .INCSyllabus_lower_part {
        width: 90%;
    }

    .INCSyllabus_phase_info_heading {
        width: 94%;
        font-size: 16px;
        line-height: 50px;
        padding-bottom: 12px;
    }

    .INCSyllabus_phase_info_heading .span1 {
        font-size: 18px;
        line-height: 50px;
    }

    .INCSyllabus_phase_info_text_content_li {
        font-size: 16px;
    }
}

@media screen and (max-width:1024px) {
    .INCSyllabus_phase_info_heading .span1 {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 300;
        line-height: 40px;
        letter-spacing: -0.0024em;
        text-align: left;
    }


    .INCSyllabus_phase_info_heading .arrow {
        position: absolute;
        right: 20px;
        top: 40%;
    }

    .INCSyllabus_phase_info_heading .span2Head {
        display: flex;
        align-items: center;
    }

    .INCSyllabus_phase_info_heading {
        display: flex;
        flex-direction: column;
        font-family: Poppins;
        font-size: 25px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: -0.0024em;
        text-align: left;
        width: 100%;
        padding-bottom: 12px;
        padding-top: 16px;
        position: relative;
        border-radius: 20px 0px 0px;
        padding-left: 20px;
    }

    .INCSyllabus_phase_info_heading .span2 {
        color: rgba(0, 194, 255, 1);
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: -0.0024em;
        text-align: left;
        margin-left: 10px;
        padding-top: 0px;
    }

    .INCSyllabus_moto {
        font-size: 1.4rem;
        line-height: 35px;
    }

    .INCSyllabus_lower_part {
        width: 85%;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .INCSyllabus_phase_info_text_content_li {
        line-height: 30px;
    }

    .INCSyllabus_phase_info_box {
        overflow: hidden;
    }

    .INCSyllabus_phase_info_heading.active {
        background: linear-gradient(90deg, #6557DD 0.01%, rgba(2, 10, 20, 0) 100%);
        border-bottom: 0px solid;
    }
}

@media screen and (max-width:620px) {
    .INCSyllabus_pagename {
        font-size: 12px;
        line-height: 28px;
    }

    .INCSyllabus_uppar_part {
        width: 90%;
    }

    .INCSyllabus_heading {
        line-height: 28px;
        font-size: 18px;
    }

    .INCSyllabus_moto {
        font-size: 14px;
        line-height: 24px;
    }

    .INCSyllabus_lower_part {
        width: 90%;
    }

    .INCSyllabus_phase_info_box {
        width: 100%;
    }

    .INCSyllabus_phase_info_heading {
        font-size: 16px;
        line-height: 23.11px;
    }

    .INCSyllabus_phase_info_heading .span2 {
        margin-left: 5px;
    }

    .INCSyllabus_phase_info_heading .span1 {
        font-size: 12px;
        line-height: 25px;
        white-space: normal;
    }

    .INCSyllabus_phase_info_heading .arrow {
        width: 15px;
        height: auto;
    }

    .INCSyllabus_phase_info_heading {
        padding-bottom: 8px;
        padding-top: 8px;
        padding-left: 18px;
    }

    .INCSyllabus_phase_info_text_content_li {
        line-height: 20px;
        font-size: 11px;
    }

    .INCFLutterPrequisite_heading {
        font-size: 18px;
        line-height: 28px;
    }
}
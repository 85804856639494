.swiper-wrapper {
    align-items: center;
}

.INCtestimonials {
    height: 100%;
    width: 100%;
    background: var(--primary-color);
    position: relative;
}

.INCtestimonials_container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.testimonial_content_container {
    width: 75%;
    height: 80%;
    position: relative;
}

.INCBackgroundTestimonials_holder {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.INCTestimonial_background_svg1 {
    position: absolute;
    top: 0;
    left: 0;
}

.INCtestimonial_text br {
    display: none;
}

.INCTestimonial_background_svg2 {
    position: absolute;
    right: 0;
    bottom: 0;
}

.INCTestimonials_content_holder {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2%;
}

.INCTestiminials_heading span {
    color: rgba(0, 194, 255, 1);
}

.INCTestiminials_heading {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 600;
    line-height: 60px;
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    /* margin-bottom: 2%; */
    color: rgba(255, 255, 255, 1);
}

.INCTestimonial_text_holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 50%;
    justify-content: center;
}

.INCtestimonial_text {
    width: 59%;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 300;
    line-height: 39.6px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    transition: opacity 800ms;
}

.INCTestimonial_quote_svg {
    width: 95px;
}

.INCtestimonial_text span {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 50px;
    text-align: center;
}

.INCtestimonials_images_holder {
    display: flex;
    align-items: center;
    justify-content: center;
}

.INCTestimonials_info_holder {
    color: #ffffff;
    margin-top: 30px;
}

.INCTestimonials_name {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
}

.INCTestimonials_role {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
}

.INCtestimonials_images_holder {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 90px;
    position: relative;
}

.INCTestimonials_images_info_holder {
    width: 60%;
}


.INCTestimonials_images_info_holder .outer-slide {
    transform: scale(1);
    transition: transform 0.5s ease;
    z-index: 0;
}

.INCTestimonials_images_info_holder .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.INCTestimonials_images_info_holder .swiper-slide img {
    width: 60px;
    transition: 1500ms ease;
}

.INCTestimonials_images_info_holder .swiper-slide-next img,
.INCTestimonials_images_info_holder .swiper-slide-prev img {
    width: 75px;
    transition: 1500ms ease;
}

.INCTestimonials_images_info_holder .swiper-slide-active img {
    transition: 1500ms ease;
    width: 88px;
    margin-left: 2px;
}

.INCTestimonials_images_info_holder .INCtestimonials_images_holder_svg {
    position: absolute;
    top: -9px;
    z-index: 2;
    pointer-events: none;
    /* margin-left: 18px; */
}

/* 
.INCTestimonials_images_info_holder .swiper-slide-next {
    margin-right: 60px !important;
}

.INCTestimonials_images_info_holder .swiper-slide-prev {
    margin-left: 10px !important;
} */

.INCTestimonials_images_info_holder .swiper {
    height: 95px;
}

.INCTestimonials_info_holder {
    transition: opacity 800ms;
}

.fade-in {
    opacity: 1;
}

.fade-out {
    opacity: 0;
}


@media screen and ((max-width: 1600px)) {
    .INCTestiminials_heading {
        font-size: 2.5em;
        line-height: 55px;
    }

    .INCtestimonial_text br {
        display: none;
    }

    .INCtestimonial_text {
        line-height: 30.6px;
    }

    .INCtestimonial_text span {
        font-size: 20px;
        line-height: 34px;
    }

    .INCtestimonial_text {
        font-size: 18px;
        line-height: 27px;
    }

    .testimonial_content_container {
        width: 75%;
        height: 87%;
        position: relative;
    }

    .INCTestimonials_images_info_holder .swiper-slide img {
        width: 50px;
    }

    .INCTestimonials_images_info_holder .swiper-slide-next img,
    .INCTestimonials_images_info_holder .swiper-slide-prev img {
        width: 65px;
    }

    .INCTestimonials_images_info_holder .swiper-slide-active img {
        width: 75px;
    }

    .INCTestimonials_images_info_holder .INCtestimonials_images_holder_svg {
        top: -2px;
        height: 91px;
    }

    .INCTestimonials_info_holder {
        margin-top: 12px;
    }

    .INCTestimonials_name {
        font-size: 22px;
        line-height: 30px;
    }

    .INCTestimonials_role {
        font-size: 18px;
        line-height: 26px;
    }
}

@media screen and ((max-width:1367px)) {
    .INCTestiminials_heading {
        font-size: 34px;
        line-height: 45px;
    }

    .INCtestimonial_text {
        line-height: 30.6px;
        font-size: 17px;
        line-height: 30px;
    }

    .INCtestimonial_text span {
        font-family: Poppins;
        font-size: 19px;
        font-weight: 500;
        line-height: 40px;
        text-align: center;
    }

    .INCTestimonials_images_info_holder .swiper-slide img {
        width: 40px;
    }

    .INCTestimonials_images_info_holder .swiper-slide-next img,
    .INCTestimonials_images_info_holder .swiper-slide-prev img {
        width: 55px;
    }

    .INCTestimonials_images_info_holder .swiper-slide-active img {
        width: 65px;
    }

    .INCTestimonials_images_info_holder .INCtestimonials_images_holder_svg {
        top: 3px;
        height: 80px;
    }

    .INCTestimonials_name {
        font-size: 19px;
        line-height: 24px;
    }

    .INCTestimonials_role {
        font-size: 16px;
        line-height: 26px;
    }

    .INCTestimonials_info_holder {
        margin-top: 5px;
    }

    .INCTestimonials_content_holder {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 2%;
        margin-top: 4%;
    }

    .INCtestimonial_text span {
        font-size: 18px;
        line-height: 37px;
    }

    .wide,
    .mini-wide {
        width: 90% !important;

    }

    .wide {
        font-size: 15px !important;
        line-height: 25px !important;
    }

    .wide span {
        font-size: 17px !important;
        line-height: 21px !important;
    }
}

/*tablet*/
@media screen and ((max-width: 620px)) {

    .INCTestimonial_quote_svg svg {
        width: 21px;
    }

    .testimonial_content_container {
        width: 100%;
        height: 85%;
    }

    .INCTestimonial_quote_svg {
        width: unset;
        margin: 0px 10px;
    }

    .INCTestiminials_heading {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 12px;
    }

    .INCTestimonials_images_info_holder {
        width: 95%;
    }

    .INCTestimonial_text_holder {

        margin-bottom: 25px;
        height: 60%;
    }

    .INCtestimonial_text {
        font-size: 12px;
        line-height: 25px;
    }

    .INCtestimonial_text span {
        font-size: 14px;
        line-height: 24px;
    }

    .wide {
        font-size: 10px !important;
        line-height: 18px !important;
    }

    .wide span {
        font-size: 12px !important;
        line-height: 18px !important;
    }

    .INCTestimonials_images_info_holder .swiper {
        height: 80px;
    }

    .INCTestimonials_images_info_holder .swiper-slide img {
        width: 25px;
    }

    .INCTestimonials_images_info_holder .swiper-slide-next img,
    .INCTestimonials_images_info_holder .swiper-slide-prev img {
        width: 37px;
    }

    .INCTestimonials_images_info_holder .swiper-slide-active img {
        width: 55px;
    }

    .INCTestimonials_images_info_holder .INCtestimonials_images_holder_svg {
        top: 16px;
        height: 55px;
    }

    .INCTestimonials_images_info_holder .INCtestimonials_images_holder_svg {
        top: 0px;
        height: 70px;
    }

    .INCTestimonials_name {
        font-size: 15px;
        line-height: 21px;
    }

    .INCTestimonials_role {
        font-size: 11px;
        line-height: 20px;
    }

    .INCTestimonial_background_svg2 {
        width: 80px;
    }

    .INCtestimonial_text {
        width: 90%;
    }
}
.INCFlutter_landingpage {
    /* height: 140%; */
    width: 100%;
}

.INCFlutter_landingpage_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 75px;
}

.INCFlutter_landingpage {
    background-image: url("/public/assets/images/flutter/landingpage/FlutterlandingBackground.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
}

.INCFlutter_landingpage_heading_container {
    width: 50%;
    margin-top: 140px;
    scale: 1;
    animation: scaleUp 2s 1;
}



@keyframes scaleUp {
    0% {
        scale: 0;
    }

    100% {
        scale: 1;
    }
}

.INCFlutter_landingpage_heading_container p {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: -0.0024em;
    text-align: center;
    color: var(--primary-text-color);
    margin: 0px;
}

.INCFlutter_landingpage_image_container {
    width: 100%;
    min-height: 70vh;
}

img.INCFlutter_landingpage_mobile_images_gropup {
    width: 80vw;
    position: relative;
    top: 0vw;
    animation: imgAnimation 2s 1;
}

@keyframes imgAnimation {
    0% {
        width: 15vw;
        top: 30vw;
    }

    100% {
        width: 80vw;
        top: 0vw;
    }
}


.INCFlutter_landingpage_heading_container span {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 600;
    line-height: 72px;
    letter-spacing: -0.0024em;
    text-align: center;
    color: rgba(0, 194, 255, 1);

}

.INCFlutter_landingpage_info {
    height: 90px;
    width: 267px;
    color: var(--primary-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border: 1px solid rgba(51, 239, 160, 0.4);
    border-radius: 10px;
    flex-direction: column;
}

.INCFlutter_landingpage_info.start_date {
    border: 1px solid rgba(51, 239, 160, 1);
    animation: startDate 3s infinite;
}

@keyframes startDate {
    50% {
        box-shadow: 0px 0px 14px 0px rgba(51, 239, 160, 1);
    }
}

.INCFlutter_landingpage_info.duration {
    border: 1px solid rgba(142, 129, 253, 1);
    animation: duration 3s infinite;
}

@keyframes duration {
    50% {
        box-shadow: 0px 0px 14px 0px rgba(142, 129, 253, 1);
    }
}

.INCFlutter_landingpage_info.fees {
    border: 1px solid rgba(250, 205, 73, 1);
    animation: fees 3s infinite;
}

@keyframes fees {
    50% {
        box-shadow: 0px 0px 14px 0px rgba(250, 205, 73, 1);
    }
}



.INCFlutter_landingpage_info_container {
    display: flex;
    gap: 20px;
    flex-direction: row;
}

.INCFlutter_landingpage_info_text {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: -0.0024em;
    text-align: left;
}

.INCFlutter_landingpage_info_text span {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: -0.0024em;
    text-align: center;
}

.INCFlutter_landingpage_info_heading {
    font-family: Poppins;
    font-size: 17px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: -0.0024em;
    text-align: left;
    color: rgba(255, 255, 255, 0.8);
    display: flex;
    gap: 5px;
    align-items: center;
}

.INCFlutter_landingpage_button_container {
    margin-bottom: 75px;
    cursor: pointer;
}

.INCFlutter_landingpage_registerNow_btn {
    background: linear-gradient(180deg, #EE4661 41.09%, #92161A 145.93%);
    box-shadow: 0px 10px 50px 0px rgba(238, 70, 97, 0.3);
    width: 191px;
    height: 50px;
    border-radius: 8px;
    /* opacity: 0px; */
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.0024em;
    text-align: left;
    color: var(--primary-text-color);
    display: flex;
    align-items: center;
    justify-content: center;

}

.INCFlutter_landingpage_info_heading svg {
    width: 20px;
    height: auto;
}

@media screen and ((max-width:1537px)) {
    .INCFlutter_landingpage_heading_container {
        width: 50%;
        margin-top: 140px;
        scale: 1;
        animation: scaleUp 2000ms;
    }
}

@media screen and (max-width:1367px) {
    .INCFlutter_landingpage_heading_container {
        width: 60%;
    }

    .INCFlutter_landingpage_heading_container p {
        font-family: Poppins;
        font-size: 25px;

    }

    .INCFlutter_landingpage_heading_container span {
        font-size: 36px;
    }

    .INCFlutter_landingpage_info {
        height: 70px;
        width: 230px;
    }

    .INCFlutter_landingpage_registerNow_btn {
        width: 160px;
        height: 40px;
        font-size: 15px;
    }
}

@media screen and ((max-width:1024px)) {
    .INCFlutter_landingpage_heading_container {
        width: 85%;
    }

    .INCFlutter_landingpage_info_heading {
        line-height: 35px;
    }

    .INCFlutter_landingpage_info_text {
        line-height: 35px;
    }

    .INCFlutter_landingpage_info_text span {
        line-height: 33px;
    }

    .INCFlutter_landingpage_image_container {
        min-height: 40vh;
    }


}

@media screen and ((max-width:820px)) {
    .INCFlutter_landingpage_heading_container {
        width: 95%;
    }

    .INCFlutter_landingpage_info {
        height: 70px;
        width: 220px;
    }

    .INCFlutter_landingpage_info_heading {
        font-size: 18px;
        line-height: 35px;
        text-align: left;
        display: flex;
        align-items: center;
    }

    .INCFlutter_landingpage_info_text {
        font-family: Poppins;
        font-size: 18px;
        line-height: 32px;
        text-align: left;
    }

    .INCFlutter_landingpage_info_text span {
        font-family: Poppins;
        font-size: 14px;
        line-height: 28px;
        text-align: left;
    }
}

@media screen and ((max-width:620px)) {
    .INCFlutter_landingpage_heading_container p {
        font-size: 20px;
        line-height: 45px;
    }

    .INCFlutter_landingpage_heading_container span {
        font-family: Poppins;
        font-size: 27px;
        line-height: 60px;

    }

    .INCFlutter_landingpage_info_container {
        display: flex;
        gap: 20px;
        flex-direction: column;
    }

    .INCFlutter_landingpage_heading_container {
        margin-top: 80px;
    }

    .INCFlutter_landingpage_image_container {
        min-height: 30vh;
    }

    .INCFlutter_landingpage_container {
        gap: 15px;
    }

    .INCFlutter_landingpage_button_container {
        margin-top: 15px;
    }
}
.inc_navbar_menu.couresNavbar {
    display: flex;
    gap: 10%;
}

.incub_navbar.couresNavbar {
    height: auto;
    background: rgba(3, 15, 30, 1);
}


.INCGetintouch_btn.couresNavbar {
    background: none;
    border: 1px solid rgba(255, 255, 255, 0.4);
    cursor: default
}

.INCGetintouch_btn.couresNavbar span {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: 0.05em;
    text-align: left;
}

.inc_navbar_logo.couresNavbar {
    width: 100%;
    padding: 7px 0px;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.inc_navbar_menu.couresNavbar {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
}

.INCNavbar_course_date {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
    color: var(--primary-text-color);
}

.INCNavbar_btn_container {
    display: flex;
    gap: 10%;
    flex-direction: row;
    align-items: center;
}

.flutter_Navbar_upparpart {
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    margin-right: 6%;
}

ul.INCCoursepageList {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    margin: 4px;
}

.INCCoursepageList_container {
    width: 70%;
    overflow: scroll;
}

.INCCoursepageList_container::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.INCCoursepageList li {
    cursor: pointer;
    white-space: nowrap;
}

.INCCoursepageList hr {
    width: 40%;
    background: #00C2FF;
    height: 2px;
    box-shadow: 0px 0px 3px 0px #00C2FF;
    border: 0px solid;
    margin-top: 0;
}

.INCNavbar_course_info_container {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
    color: var(--primary-text-color);
    display: flex;
    gap: 12px;
    align-items: flex-start;
}

.flutter_Navbar_lowerpart {
    display: flex;
    justify-content: space-between;
    color: var(--primary-text-color);
}

ul.INCCoursepageList {
    display: flex;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
    gap: 55px;
}

.incub_navbar.INCCourseNavbar {
    transition: 1500ms ease;
    top: -30%;
}

.INCNavbar_btn_holder {
    display: flex;
    gap: 20px;
}

@media screen and (max-width:1600px) {
    .flutter_Navbar_lowerpart {
        align-items: center;
    }

    ul.INCCoursepageList {
        margin: 0px;
    }
}

@media screen and (max-width:1460px) {
    .INCCoursepageList_container {
        width: 50%;
    }
}

@media screen and (max-width:1367px) {
    ul.INCCoursepageList {
        gap: 30px;
    }

    .INCNavbar_course_info_container {
        font-size: 16px;
        line-height: 34px;
    }

    .INCNavbar_course_info_container svg {
        width: 80px;
        height: auto;
    }

    .INCNavbar_course_date {
        font-size: 16px;
        line-height: 34px;
    }

    .INCGetintouch_btn {
        width: 166px;
        height: 36px;
        font-size: 14px;
        line-height: 28.8px;
    }

    .inc_navbar_logo img {
        width: 135px;
    }

    .flutter_Navbar_upparpart {
        margin-right: 8%;
    }

    ul.INCCoursepageList {
        gap: 30px;
        margin: 2px 0px;
    }


}

@media screen and (max-width:1024px) {
    .INCNavbar_course_info_container {
        font-size: 11px;
        line-height: 32px;
        display: none;
    }

    .INCGetintouch_btn {
        width: 130px;
        height: 30px;
        font-size: 12px;
        line-height: 23.8px;
    }

    .INCGetintouch_btn.couresNavbar span {
        font-size: 7px;
        line-height: 50px;
    }

    .INCNavbar_btn_container {
        gap: 5%;

    }

    .flutter_Navbar_upparpart {
        margin-right: 10%;
    }

    .INCNavbar_course_date {
        font-size: 12px;
        line-height: 20px;
    }

    .inc_navbar_logo.couresNavbar {

        padding: 0px;

    }

    .INCCoursepageList li {
        cursor: pointer;
        white-space: nowrap;
        font-family: Poppins;
        font-size: 13.43px;
        font-weight: 400;
        line-height: 30.21px;
        text-align: left;
    }

    .inc_navbar_logo img {
        width: 140px;
        height: auto;
    }


    .INCGetintouch_btn {
        width: 120px;
        height: 30px;
        font-size: 12px;
        line-height: 23.8px;
        border-radius: 5px;
    }

}

@media screen and (max-width:620px) {
    .flutter_Navbar_lowerpart {
        flex-direction: column-reverse;
    }

    .INCNavbar_btn_holder {
        display: flex;
        gap: 5px;
    }

    .inc_navbar_logo img {
        width: 90px;
    }

    .INCGetintouch_btn {
        border-radius: 5px;
        font-size: 12px;
        height: 26px;
        line-height: 23.8px;
        width: 100px;
    }

    .INCNavbar_btn_container {
        width: 100%;
        justify-content: space-between;
    }

    ul.INCCoursepageList {
        padding: 0px;
        margin-top: 10px;
    }

    .INCCoursepageList_container {
        width: 100%;
    }

    .INCNavbar_btn_container.inactive {
        width: 60%;
        justify-content: space-between;
    }
}
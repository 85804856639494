.INCFlutterProjects {
    width: 100%;
    display: block;
}

.INCFlutterProjects_uppar_part {
    /* height: 7.57vh; */
    width: 85%;
    margin-bottom: 3%;
    margin-top: 3%;
}

.INCFlutterProjects_container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.INCFlutterProjects_pagename {
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 60px;
    text-align: left;
    color: var(--primary-text-color);
}

.INCFlutterProjects_heading {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -0.0024em;
    text-align: left;
    font-size: clamp(2.3rem, 3.5vw, 2.3em);
    color: var(--primary-text-color);
    width: 70%;
}

.INCFlutterProjects_heading span {
    color: rgba(0, 194, 255, 1);
}

.INCFlutterProjects_moto {
    font-family: Poppins;
    font-size: 2rem;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: -0.0024em;
    text-align: left;
    color: rgba(255, 255, 255, 0.7);
}

.INCFlutterProjects_lower_part {
    width: 85%;
    display: flex;
    /* justify-content: flex-start; */
}

.INCFlutterProject_list_container {
    width: 100%;
    /* height: 100%; */
    border-radius: 10px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    justify-items: center;
    color: #ffffff;
    gap: 5%;
    margin-bottom: 5%;
}

.INCFlutterProject_info_container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.INCFlutterProject_info_container img {
    border-radius: 0px;
}

.INCFlutterProject_info {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
    width: 100%;
    margin-bottom: 5%;
}

.INCFlutterProject_name {
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    line-height: 32.5px;
    text-align: left;
    max-width: 65%;
}

.INCFlutterProject_logo_similar img {
    margin-right: 15px;
    width: 50px;
}

.INCFlutterProject_textinfo {
    font-family: Poppins;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.03em;
    text-align: left;
}

.INCFlutterProjects_bottom_part {
    width: 100%;
    height: 15vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

button.INCFlutterProjects_load_more {
    width: 138px;
    height: 50px;
    border-radius: 25px;
    background: rgba(255, 255, 255, 0.2);
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: center;
    color: var(--primary-text-color);
    pointer-events: all;
    border: 0px solid;
}

.INCFlutterProjects_load_more:hover {
    background: linear-gradient(180deg, #EE4661 41.09%, #92161A 145.93%);

}

.INCFlutterProject_textinfo {
    color: rgba(255, 255, 255, 0.8);
}

.INCFlutterProject_textinfo span {
    color: rgb(122, 122, 255);
    cursor: pointer;
}

@media screen and (max-width:1367px) {
    .INCFlutterProjects_pagename {
        font-size: 1.2rem;
        line-height: 48px;
    }

    .INCFlutterProjects_heading {
        font-size: clamp(2rem, 3.5vw, 2em);
        line-height: 48px;
    }

    .INCFlutterProject_name {
        font-size: 25px;
    }

    .INCFlutterProject_textinfo {
        font-size: 1rem;
    }
}

@media screen and (max-width:1024px) {
    .INCFlutterProjects_heading {
        width: 90%;
    }

    .INCFlutterProject_list_container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .INCFlutterProject_name {
        font-size: 20px;
    }

    .INCFlutterProjects_container {
        margin-bottom: 30%;
    }
}

@media screen and (max-width:620px) {
    .INCFlutterProjects_pagename {
        font-size: 12px;
        line-height: 28px;
    }

    .INCFlutterProjects_container {
        margin-bottom: 5%;
    }

    .INCFlutterProjects_heading {
        font-size: 18px;
        line-height: 28px;
    }

    .INCFlutterProject_list_container {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .INCFlutterProject_name {
        font-size: 18px;
        line-height: 22px;
    }

    .INCFlutterProject_logo_similar img {
        margin-right: 15px;
        width: 34px;
    }

    .INCFlutterProject_textinfo {
        font-size: 12px;
        line-height: 18px;
    }

    .INCFlutterProject_list_container {
        gap: 70px;
    }

    .INCFlutterProjects_bottom_part {
        height: 8vh;
        align-items: flex-end;
    }

    button.INCFlutterProjects_load_more {
        width: 125px;
        height: 36px;
        font-size: 12px;
        line-height: 28.8px;
    }

    .INCYoutubeFrame_holder {
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
    }

    .INCYoutubeFrame_container iframe {
        height: 29% !important;
        width: 100% !important;
        border-radius: 20px;
        position: relative;
        z-index: 2;
    }

    .ytp-overflow-panel .ytp-watch-later-icon {
        height: 34px !important;
    }

    .ytp-overflow-panel .ytp-share-icon {
        width: 53px !important;
        height: 34px !important;
        margin-bottom: 10px;
    }

    .ytp-big-mode .ytp-share-title,
    .ytp-overflow-panel .ytp-share-title {
        font-size: 15px !important;
    }

    .ytp-big-mode .ytp-watch-later-title,
    .ytp-overflow-panel .ytp-watch-later-title {
        font-size: 14px !important;
    }
}
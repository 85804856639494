.INC_loadbackground {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    overflow: hidden;
}

.INC_blurBack_container {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    background-image: url("/public/assets/images/landingPage/IncblurBackground.png");
    background-size: cover;
    background-position: center;
    animation: fadeOut 4s infinite;
    /* Adjust the duration as needed */
    animation-delay: 0.5s;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.incub_navbar_load {
    width: 100%;
    /* background: #030F1E; */
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 20;
}

.inc_navbar_container_load {
    height: 67.4%;
    width: 93.1%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

ul.inc_navbar_items_load {
    padding: 0;
    display: flex;
    gap: 30px;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
    color: #FFFFFF99;

}

.INCGetintouch_btn_load {
    width: 185px;
    height: 40px;
    gap: 10px;
    box-shadow: 4px 4px 30px 0px #00000033;
    border-radius: 8px;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: left;
    background: linear-gradient(180deg, #EE4661 41.09%, #92161A 145.93%);
    cursor: pointer;
}

.inc_navbar_logo_img {
    height: auto;
    width: 158px;
    position: relative;
    animation: blogo 3s 1;
    top: 0px;
    left: 0px;
    z-index: 2;
}

@keyframes blogo {
    0% {
        height: auto;
        width: 158px;
        position: relative;
        top: 40vh;
        left: 40vw;
        scale: 2;
        z-index: 2;
    }

    10% {
        height: auto;
        width: 158px;
        position: relative;
        top: 40vh;
        left: 40vw;
        scale: 2;
        z-index: 2;
    }

    100% {
        height: auto;
        width: 158px;
        position: relative;
        top: 0px;
        left: 0px;
        z-index: 2;
    }
}

.inc_navbar_menu_load {
    position: relative;
    left: 70%;
    animation: navContent 2.5s 1;
    animation-delay: 0.7s;
    z-index: 1px;
}



@keyframes navContent {
    0% {
        left: 70%;
        z-index: 1px;
    }

    90% {
        left: 0%;
        z-index: 1px;
    }

    100% {
        left: 0%;
        z-index: 1px;
    }
}

@media screen and ((min-width:1537px)) {
    .incub_navbar_load {
        height: 65px;

    }

    ul.inc_navbar_items {
        padding: 0;
        display: flex;
        gap: 30px;
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 36px;
        text-align: left;
        color: #FFFFFF99;
    }
}
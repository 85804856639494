.INCFAQ {
    /* min-height: 120%; */
    width: 100%;
    position: relative;
    z-index: 1;
    background-color: var(--primary-color);
    margin-top: -2px;
}

.INCFAQ_outer_layer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.INCFAQ_container {
    width: 85%;
}

.INCFAQ_heading_Container {
    height: 225px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
}

.INCFAQ_heading_Container p {
    font-family: Poppins;
    font-size: 2rem;
    font-weight: 400;
    line-height: 43.6px;
    text-align: left;
    margin: 0;
    color: rgba(0, 194, 255, 1);
}

.INCFAQ_heading_Container h1 {
    font-family: Poppins;
    font-size: 50px;
    font-weight: 600;
    line-height: 76px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin: 0;
}

.INCFAQ_text_content_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 4%;
    margin-top: 45px;
}

.INCFAQ_text_content {
    width: 100%;
    min-height: calc(130px - 40px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background: linear-gradient(112.08deg, #313843 0%, #101926 89.08%); */
    box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
    border-radius: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 30px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    position: relative;
}

.INCFAQ_text_container {
    margin-left: 5%;
    text-align: left;
    margin-right: 10%;
}

.INCFAQ_Q {
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 45px;
    text-align: left;
    color: var(--primary-text-color);
}

.INCFAQ_qdescription {
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 10px;
    display: none;
}

.INCFAQ_text_content_section {
    width: 48%;
}

.INCFAQ_hr {
    position: absolute;
    height: 35%;
    transform: rotate(180deg);
    border: 2.14px solid rgba(0, 194, 255, 1);
    box-shadow: 0px 0px 3.21px 0px rgba(0, 194, 255, 1);
    top: 24px;
    left: -5px;
    opacity: 0;
    animation: fadeInBorder 1000ms ease-in-out;
}

.INCFAQ_svg_container {
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: 30px;
    min-height: 43px;
    top: 22px;
    /* height: 168px; */
    position: absolute;
    right: 0;
    height: 100;
}

.INCFAQ_text_content.INCFAQ_onIndex {
    border: 0px solid rgba(255, 255, 255, 0.2);
    background: linear-gradient(138deg, #313843 0%, #101926 89.08%);
    animation: fadeIn 1000ms ease-in-out;
    transition: all 1000ms ease-in-out;
}

.INCFAQ_qdescription {
    display: none;
    opacity: 0;
    transition: opacity 1000ms ease-in-out;
    height: fit-content;
    animation: fadeInText 1000ms ease-in-out;
}

.INCFAQ_onIndex .INCFAQ_qdescription {
    display: block;
    opacity: 1;
}

.INCFAQ_hr.INCFAQ_onIndex {
    opacity: 1;
}

@keyframes fadeInBorder {
    0% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0.4;
        border: 0px solid rgba(255, 255, 255, 0.2);
    }

    100% {
        opacity: 1;
    }
}


@media screen and ((max-width: 1600px)) {
    .INCFAQ_heading_Container p {
        font-size: 1.7rem;
    }

    .INCFAQ_heading_Container h1 {
        font-size: 2.5rem;
        line-height: 70px;
    }

    .INCFAQ_heading_Container {
        height: 120px;
        margin-top: 40px;
    }

    .INCFAQ_text_content_container {
        margin-top: 30px;
    }

    .INCFAQ_Q {
        font-size: 1.4rem;
        margin-right: 10px;
    }

    .INCFAQ_qdescription {
        font-family: Poppins;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 33px;
        text-align: left;
        color: rgba(255, 255, 255, 0.6);
        margin-top: 10px;
        display: none;
    }

    .INCFAQ_text_content {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .INCFAQ_svg_container {
        top: 35px;
    }

    .INCFAQ_svg_container svg {
        height: 33px;
        width: auto;
    }
}


@media screen and ((max-width: 1367px)) {
    .INCFAQ_Q {
        font-size: 1.2rem;
        margin-right: 10px;
        line-height: 35px;
    }

    .INCFAQ_qdescription {
        font-size: 1rem;
    }

    .INCFAQ_text_content {
        margin-bottom: 25px;
    }

    .INCFAQ_svg_container svg {
        height: 33px;
        width: auto;
    }
}

@media screen and ((max-width: 1024px)) {
    .INCFAQ {
        min-height: 65%;
    }

    .INCFAQ_heading_Container {
        margin-top: 60px;
    }
}


@media screen and ((max-width: 620px)) {

    .INCFAQ_text_content_container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .INCFAQ_text_content_section {
        width: 100%;
    }

    .INCFAQ_Q {
        font-size: 0.7rem;
        margin-right: 30px;
        line-height: 22px;
    }

    .INCFAQ_svg_container {
        top: 20px;
        margin-right: 20px;
    }

    .INCFAQ_qdescription {
        font-size: 0.6rem;
        line-height: 20px;
        margin-right: 35px;
    }

    .INCFAQ_text_content {
        min-height: calc(100px - 40px);
    }

    .INCFAQ_heading_Container {
        height: 85px;
    }

    .INCFAQ_heading_Container h1 {
        font-size: 1.2rem;
        line-height: 35px;
    }

    .INCFAQ_heading_Container p {
        font-size: 1rem;
        line-height: 25.6px;
    }

    .INCFAQ_hr {
        height: 25%;
        border: 1.25px solid rgba(0, 194, 255, 1);
        top: 24px;
        left: -1px;
    }
}

@media screen and ((width: 620px)) {
    .INCEvents {
        height: 135%;
    }
}
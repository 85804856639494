.INCFlutterRoadmap_page {
    height: 100%;
    width: 100%;
}

.incub_navbar.couresNavbar.roadmap {
    height: auto;
    background: rgba(3, 15, 30, 1);
    position: sticky;
    top: 0;
    box-shadow: 0px 0px 40px 0px rgba(255, 255, 255, 0.1);
    z-index: 250;
}

.INCFlutterRoadmap_animationscreen svg {
    position: relative;
    z-index: 1;
    margin-top: 60px;
    width: 70%;
    height: auto;
}

/* .INCFlutter_landingpage_heading_container {
    width: 43%;
    margin-top: 140px;
    scale: 1;
    animation: scaleUp 2000ms
} */

.INCFlutterParabolic_effect {
    position: absolute;
    width: 28%;
    height: 0%;
    top: 20%;
    border-radius: 50%;
    left: 38%;
    background: rgb(0 137 236);
    box-shadow: 0px 0px 733px 32px rgb(0 137 236);
}

svg.INCFlutterRoadmap_left_svg {
    display: block;
    position: absolute;
    top: 45%;
    left: 3%;
}

img.INCFlutterRoadmap_right_svg {
    display: block;
    position: absolute;
    top: 25%;
    right: 10%;
    rotate: 20deg;
}

.INCFlutterRoadmapPage_heading_cobntainer {
    width: 100%;
    text-align: center;
    margin-top: 15%;
}

.INCFlutterRoadmapPage_heading {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0.03em;
    text-align: center;
    color: rgba(255, 255, 255, 1);
}

.INCFlutterRoadmapPage_heading_text {
    /* background: rgba(255, 255, 255, 0.6); */
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0.03em;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
}

.INCNavbar_course_info_container span {
    color: rgba(0, 194, 255, 1);

}

.flutter_Navbar_upparpart.roadmap {
    margin-right: 0px;
}


@media screen and (max-width:769px) {
    .flutter_Navbar_upparpart.roadmap img {
        width: 120px;
    }

    .flutter_Navbar_upparpart.roadmap .INCNavbar_course_info_container {
        font-size: 15px;
        gap: 5px;
    }

    .flutter_Navbar_upparpart.roadmap .INCGetintouch_btn {
        width: 150px;
        height: 35px;
        font-size: 13px;
    }
}

@media screen and (max-width:620px) {
    svg.INCFlutterRoadmap_left_svg {
        width: 25px;
        height: auto;
        top: 15%;
    }

    img.INCFlutterRoadmap_right_svg {
        width: 25px;
        height: auto;
    }

    .flutter_Navbar_upparpart.roadmap .INCNavbar_course_info_container {
        font-size: 9px;
        gap: 5px;
    }

    .flutter_Navbar_upparpart.roadmap .INCGetintouch_btn {
        width: 80px;
        height: 21px;
        font-size: 8px;
    }

    .flutter_Navbar_upparpart.roadmap img {
        width: 70px;
    }

    .flutter_Navbar_upparpart.roadmap .INCNavbar_course_info_container {
        font-size: 7px;
        gap: 5px;
    }

    .flutter_Navbar_upparpart {
        height: 30px;
    }

    .INCNavbar_btn_container.inactive.roadmap {
        justify-content: end;
    }
}
.INCFlutterPage {
    height: 100vh;
    width: 100vw;
    overflow: scroll;
    overflow-x: hidden;
}

.FlutterLanding {
    width: 100%;
}

.INCFlutterResources {
    width: 100%;
    height: 100%;
}

.INCFlutterFeature {
    height: 100%;
    width: 100%;
}

.FlutterRoadmap {
    height: 100%;
    width: 100%;
}

.FlutterSyllabus {
    width: 100%;
}

.FlutterPrequisite {
    height: 100%;
    width: 100%;
}

.FlutterProjects {
    width: 100%;
    display: block;
}

.FlutterYoutube {
    width: 100%;
    position: relative;
}

.FlutterCertificate {
    width: 100vw;
    height: 100vh;
}

.FlutterFAQ {
    min-height: 120%;
    width: 100%;
}

.FlutterGetInTouch {
    height: 100%;
    width: 100vw;
}

@media screen and (max-width: 1024px) {
    .INCFlutterResources {
        width: 100%;
        height: 50%;
    }

    .FlutterPrequisite {
        height: 85%;
        width: 100%;
    }

    .FlutterFAQ {
        min-height: 65%;
    }
}

@media screen and (max-width: 620px) {
    .INCFlutterResources {
        width: 100%;
        height: 35%;
    }

    .FlutterRoadmap {
        height: 70%;
    }

    .FlutterPrequisite {
        height: 130%;
    }

    .FlutterGetInTouch {
        height: auto;
        width: 100vw;
    }
}
.INCMobile_navbar {
    position: absolute;
    width: 100%;
    background: rgba(3, 15, 30, 1);
    height: 70px;
    /* display: flex; */
    z-index: 5;
}

.INCMobile_navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-top: 10px; */
    height: 100%;
}

.INCMobile_navbar_logoContainer {
    display: flex;
    align-items: center;
    margin-left: 37px;
}

.INCMobile_navbar_logoContainer img {
    width: 140px;
    height: auto;
}

.INCNavbar_mobile_Content_Container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.INCMobile_nav_burger {
    margin-right: 20px;
}

.INCMobile_nav_content_holder {
    height: 360px;
    width: 300px;
    position: absolute;
    /* border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.05) 54%, rgba(255, 255, 255, 0.2) 100%); */
    right: 0px;
    border-radius: 0px 0px 0px 41px;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    top: 0px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    z-index: 5;
}

.INCMbile_cross_cancel {
    height: 70px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.INCMbile_cross_cancel svg {
    margin-right: 19px;
    margin-bottom: 8px;
    cursor: pointer;
}

.INCMobile_nav_content_items {
    height: calc(100% - 70px);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 55px;
}

.INCMobile_nav_content_item {
    font-family: Poppins;
    font-size: 23.72px;
    font-weight: 400;
    line-height: 47.45px;
    text-align: left;
    color: #ffffff;
}

.INCMobile_nav_getintouch_btn {
    width: 214.17px;
    height: 46.13px;
    gap: 11.53px;
    border-radius: 38.06px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffff;
    box-shadow: 4.61px 4.61px 34.6px 0px rgba(0, 0, 0, 0.2);
    background: linear-gradient(180deg, #EE4661 41.09%, #92161A 145.93%);
    font-family: Poppins;
    font-size: 18.45px;
    font-weight: 500;
    line-height: 33.21px;
    text-align: left;
}


@media screen and ((max-width: 415px)) {
    .INCMobile_navbar {
        height: 50px;
        z-index: 5;
    }

    .INCMobile_navbar_logoContainer {
        margin-left: 20px;
    }

    .INCMobile_navbar_logoContainer img {
        width: 90px;
        height: auto;
    }

    .INCMobile_nav_burger svg {
        height: 28px;
        width: auto;
    }

    .INCMbile_cross_cancel {
        height: 50px;

    }

    .INCMobile_nav_content_holder {
        height: 300px;
        width: 220px;
        border-radius: 0px 0px 0px 25px;

    }

    .INCMobile_nav_content_item {
        font-size: 18px;
        line-height: 30.45px;
    }

    .INCMobile_nav_content_holder {
        height: 240px;
        width: 200px;
    }

    .INCMobile_nav_getintouch_btn {
        width: 162px;
        height: 35px;
        font-size: 14px;
        gap: 5.25px;
    }

    .INCMobile_nav_getintouch_btn svg {
        width: 12.25px;
        height: 12.25px;
        font-size: 14px;

    }

    .INCMbile_cross_cancel svg {
        height: 30px;
        width: 30px;
    }

    .INCMobile_nav_content_items {
        gap: 28px;
    }

    .INCMobile_navbar {
        height: 50px;
        z-index: 5;
        position: fixed;
    }
}
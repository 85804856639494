.incub_navbar {
    width: 100%;
    /* background: #030F1E; */
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    overflow: hidden;
    z-index: 20;
    top: 0;
    transition: 1000ms ease;
}

.inc_navbar_logo {
    cursor: pointer;
}

.inc_navbar_items hr {
    margin: 0;
    height: 2px;
    width: 30px;
    border-radius: 10px;
    background: rgba(0, 194, 255, 1);
    border: 0px solid;
    box-shadow: 0px 0px 3px 0px rgba(0, 194, 255, 1);
}

/* 
.incub_navbar {
    transition: 1000ms ease;
    top: -100px;
} */
.landing_navbar {
    transition: 1000ms ease;
    top: -10%;
}

.incub_navbar.colorBack {
    background: #030F1E;
}

.incub_navbar.colorBack {
    transition: 1000ms ease;
    top: 0;
}

.inc_navbar_container {
    height: 67.4%;
    width: 93.1%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

ul.inc_navbar_items {
    padding: 0;
    display: flex;
    gap: 30px;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
    color: #FFFFFF99;

}

/* .INCGetintouch_btn {
    width: 185px;
    height: 40px;
    gap: 10px;
    opacity: 0px;
    box-shadow: 4px 4px 30px 0px #00000033;
    border-radius: 8px;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: left;
    background: linear-gradient(180deg, #EE4661 41.09%, #92161A 145.93%);
    cursor: pointer;
    border: 0px solid
} */

.INCGetintouch_btn {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.0024em;
    text-align: left;
    background: linear-gradient(180deg, #EE4661 41.09%, #92161A 145.93%);
    width: 185px;
    height: 40px;
    box-shadow: 4px 4px 30px 0px #00000033;
    color: #FFFFFF;
    border: 0px solid;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    cursor: pointer;
}

.INCGetintouch_inputfeilds_single select {
    height: 80%;
    width: 100%;
    background: transparent;
    border: 0px solid;
    color: #ffffff;
}

.inc_navbar_logo img {
    height: auto;
    width: 158px;
    position: relative;
    top: 0px;
    left: 0px;
}

.inc_navbar_items li {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and ((min-width:1537px)) {
    .incub_navbar {
        height: 65px;

    }

    ul.inc_navbar_items {
        padding: 0;
        display: flex;
        gap: 30px;
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 36px;
        text-align: left;
        color: #FFFFFF99;
    }
}

.incub_navbar {

    position: fixed;

}
.INCFlutterPrequisite {
    height: 100%;
    width: 100%;
}

.INCFlutterPrequisite_container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.INCFlutterPrequisite_container_holder {
    height: 80%;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.INCFLutterPrequisite_content_box_heading span {
    color: rgba(0, 194, 255, 1);
}

.INCFLutterPrequisite_heading {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 600;
    line-height: 55px;
    letter-spacing: -0.0024em;
    text-align: center;
    font-size: clamp(2.3rem, 3.5vw, 2.3em);
    color: var(--primary-text-color);
}

.INCFLutterPrequisite_heading span {
    color: rgba(0, 194, 255, 1);

}

.INCFLutterPrequisite_heading_text {
    font-family: Poppins;
    font-size: 1.7rem;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: -0.0024em;
    text-align: left;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 20px;
}

.INCFLutterPrequisite_content_box_container {
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3%;
    margin-top: 20px;
}

.INCFLutterPrequisite_content_box_heading span {
    color: #00C2FF;
}

.INCFLutterPrequisite_content_box_inner_text div {
    width: 90%;
}

.INCFLutterPrequisite_content_box_heading {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    line-height: 35px;
    letter-spacing: -0.0024em;
    text-align: center;
    color: #FFFFFF;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 85%;
    border-bottom: 1.15px solid rgba(83, 83, 83, 1);
}

.INCFLutterPrequisite_content_box {
    width: 35%;
    height: 95%;
    color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 15px;
    border: 1.15px solid rgba(83, 83, 83, 1);
}

.INCFLutterPrequisite_content_box_text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 81%;
    flex-direction: column;
    width: 90%;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -0.0024em;
}

.INCFLutterPrequisite_content_box_inner {
    width: 100%;
    /* background: antiquewhite; */
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.INCFLutterPrequisite_content_box_inner_text svg {
    height: 50px;
    width: 30px;
}

.INCFLutterPrequisite_content_box_inner_text {
    display: flex;
    text-align: left;
    border-bottom: 1.15px solid rgba(37, 37, 37, 1);
    gap: 7px;
    height: 20.33%;
    margin-bottom: 5%;
    width: 100%;
}

.INCFlutterProject_logo_similar {
    display: flex;
    flex-direction: row;
}

.INCFLutterPrequisite_content_box_inner_text.part3 {
    border-bottom: 0px solid rgba(37, 37, 37, 1);
}

@media screen and (max-width:1600px) {
    .INCFLutterPrequisite_content_box {
        width: 50%;
        height: 90%;
        color: #ffffff;
        display: flex;
        align-items: center;
        flex-direction: column;
        border-radius: 15px;
        border: 1.15px solid rgba(83, 83, 83, 1);
    }

    .INCFLutterPrequisite_content_box_text {
        font-size: 18px;
        line-height: 28px;
    }

    .INCFLutterPrequisite_heading_text {
        font-size: 1.4rem;
        line-height: 45px;
        margin-top: 10px;
    }

    .INCFlutterPrequisite {
        height: 120%;
    }
}

@media screen and (max-width:1367px) {
    .INCFLutterPrequisite_heading {
        font-size: clamp(2rem, 3.5vw, 2em);
    }

    .INCFLutterPrequisite_heading_text {
        font-size: 1.4rem;
        margin-top: 7px;
    }

    .INCFLutterPrequisite_content_box_text {
        font-size: 16px;
        line-height: 30px;
    }
}

@media screen and (max-width:1024px) {
    .INCFLutterPrequisite_content_box_heading {
        font-family: Poppins;
        font-size: 22px;
        font-weight: 600;
        line-height: 35px;
        letter-spacing: -0.0024em;
        text-align: center;
        color: #FFFFFF;
        height: 15%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 85%;
        border-bottom: 1.15px solid rgba(83, 83, 83, 1);
    }

    .INCFlutterPrequisite {
        height: 100%;
        width: 100%;
    }

    .INCFlutterPrequisite_container_holder {
        height: 95%;
        width: 95%;
    }

    .INCFLutterPrequisite_heading_text {
        line-height: 35px;
        text-align: center;
    }

    .INCFLutterPrequisite_content_box_text {
        font-size: 18px;
        line-height: 28px;
    }

    .INCFLutterPrequisite_content_box {
        width: 50%;
        height: 85%;
    }

    .INCFLutterPrequisite_content_box_container {
        height: 60%;
        width: 100%;
    }
}

@media screen and (max-width:620px) {
    .INCFLutterPrequisite_heading {
        font-size: 18px;
        line-height: 28px;
    }

    .INCFLutterPrequisite_heading_text {
        line-height: 24px;
        font-size: 14px;
    }

    .INCFLutterPrequisite_content_box_container {
        flex-direction: column;
    }

    .INCFLutterPrequisite_content_box {
        width: 90%;
        height: 85%;
    }

    .INCFLutterPrequisite_content_box_container {
        height: 80%;
    }

    .INCFlutterPrequisite {
        height: 100%;
    }

    .INCFLutterPrequisite_content_box_heading {
        font-size: 16px;
        line-height: 37px;
        gap: 5px;
    }

    .INCFLutterPrequisite_content_box_text {
        font-size: 10.84px;
        line-height: 21.37px;
    }
}
.enquiry {
    background-color: #020A14;
}

.enquiry .INCGetintouch_form_layout_container_scroll {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 0%;
    justify-content: center;
}

.enquiry .INCGetintouch_form_container {
    height: 80%;
    width: 80%;
    border: 2px solid #ffffff40;
}

.enquiry .INCGetintouch_inputfeild_division_part {
    width: 48.5%;
}

.enquiry .INCGetintouch_inputfeilds_part {
    gap: 3%;
}

.enquiry .INCGetintouch_asset_container {
    height: 100%;
    width: 35%;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: #0C141D;
    flex-direction: column;
}

.enquiry .INCGetintouch_asset_container span {
    color: #00C2FF;
}


.enquiry .INCGetintouch_heading_container {
    width: 75%;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    text-align: left;
    color: var(--primary-text-color);
    margin-left: 15%;
}

.NCGetintouch_heading_info_text {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    margin-right: 50px;
}

.NCGetintouch_heading_info {
    margin-left: 15px;
}

.INCGetintouch_asset_container_dot_background {
    position: absolute;
    top: 10%;
    left: 0%;
    width: 100%;
}

.INCGetintouch_heading_info_content_container {
    color: var(--primary-text-color);
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 40px;
    margin-top: 90px;
    margin-left: 15%;
}

.enquiry .INCGetintouch_input_feild_container {
    height: 60%;
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 3%;
}

.NCGetintouch_heading_info_content {
    display: flex;
    text-align: left;
    align-items: center;
}

.enquiry .INCGIT_lower_asset_background {
    background: rgba(255, 255, 255, 0);
}

.enquiry .INCGetintouch_heading_container {
    width: 75%;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    text-align: left;
    color: var(--primary-text-color);
}

.INCGetInTouch_enquiry_page {
    width: 100%;
}

.enquiry .INCGetInTouch_blurDiv {

    background: rgba(255, 255, 255, 0);

}

.INCGetInTouch.enquiry {
    height: 100vh;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    z-index: 246;
}



@media screen and (max-width:1080px) {
    .enquiry .INCGetintouch_asset_container {
        display: none;
    }

    .enquiry .INCGetintouch_form_layout {
        width: 100%;
    }

    .enquiry .INCGetintouch_form_container {
        height: 70%;
        width: 85%;
    }

    .enquiry .INCGetintouch_form_layout_container {
        height: 90%;
        width: 75%;
    }

    .enquiry .INCGetintouch_input_feild_container {
        height: 75%;
        width: 100%;
        gap: 6%;
    }

}


@media screen and (max-width:540px) {
    .enquiry .INCGetintouch_form_container {
        height: 90vh;
        /* margin-bottom: 12%; */
        width: 87%;
    }

    .enquiry .react-dropdown-select-clear {
        position: relative;
        z-index: 5;
    }

    .enquiry .INCGetintouch_form_cancel {
        position: sticky;
        top: 10px;
        left: 90%;
    }

    .enquiry .INCGetintouch_form_heading_container {
        width: 50.6%;
        line-height: 40px;

    }

    .enquiry .INCGetintouch_form_layout_container {
        height: unset;
    }

    .enquiry .INCGetintouch_form_layout {
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .enquiry .INCGetintouch_form_layout_container {
        height: 90%;
        width: 90%;
        overflow: scroll;
    }

    .enquiry .INCGetintouch_inputfeilds_part {
        width: 100%;
        height: unset;
        display: flex;
        gap: 20px;
        flex-direction: column;
    }

    .enquiry .INCGetintouch_input_feild_container {
        height: unset;
        gap: 20px;
    }

    .enquiry .INCGetintouch_inputfeild_division_part {
        height: 55px;
        width: 100%;
    }

    .enquiry .INCGetintouch_submit_container {
        height: 35px;
        width: 122px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .enquiry .INCGetintouch_form_layout_container_scroll {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        width: 90%;
    }

    .enquiry .INCGetintouch_submit_container {
        height: 40px;
        width: 122px;
        margin-top: 20px;
        margin-bottom: 0px;
    }

    .enquiry .react-dropdown-select-input::placeholder {
        font-family: Poppins;
        font-size: 13px;
        font-weight: 500;
        line-height: 19.5px;
        text-align: left;
        color: #FFFFFF66;
    }

    .enquiry input::placeholder {
        font-family: Poppins;
        font-size: 13px;
        font-weight: 500;
        line-height: 19.5px;
        text-align: left;
        color: #FFFFFF66;
    }

    .enquiry .react-dropdown-select-content span,
    .enquiry .react-dropdown-select-content input {
        font-family: Poppins;
        font-size: 15px;
        font-weight: 500;
        line-height: 19.5px;
    }

    .enquiry .react-dropdown-select-content input::placeholder {
        font-family: Poppins;
        font-size: 15px;
        font-weight: 500;
        line-height: 19.5px;
    }
}
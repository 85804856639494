.INCFeatures {
    width: 100%;
    height: 100%;
    position: sticky;
    top: 0;
}

.INCFeatures_container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.INCFeature_heading {
    /* width: 87vw; */
    height: 16%;
    display: flex;
    align-items: flex-end;
    margin-left: 8%;
    margin-right: 10%;
}

.INCFeature_heading_container {
    font-family: Poppins;
    font-size: 50px;
    font-weight: 600;
    line-height: 68px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}

.INCFeature_container {
    height: 84%;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.INCFeature_dot_container {
    height: 100%;
    width: 11%;
}

.INCFeature_info_container {
    width: 91%;
    height: 100%;
}

.INCFeature_content_container {
    border: 2px solid rgba(255, 255, 255, 0.2);
    width: 1450px;
    height: 660px;
    border-radius: 40px;
    background: rgba(2, 10, 20, 1);
    margin-left: 3%;
    margin-top: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.INCFeature_dot_box {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.INCFeature_dot_box svg {
    height: 18px;
    width: auto;
    margin-right: 40px;
}

.INCFeature_asset_container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.INCFeature_text_content {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 4%;
    width: 40%;
    height: 100%;
    gap: 45px;
}

.INCFeature_text_content_heading {
    font-family: Poppins;
    font-size: 2.8rem;
    font-weight: 600;
    line-height: 52.5px;
    text-align: left;
    width: 98%;
    margin-top: 100px;

}

.INCFeature_text_content_heading.feature1 {
    margin-left: 6%;
}

.INCFeature_text_content_heading span {
    color: rgba(0, 194, 255, 1);
}

.INCFeature_text_content_list {
    margin-top: 44px;
    width: 82%;
}

.INCFeature_text_conent_list_li {
    display: flex;
    align-items: center;
    font-family: Poppins;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: rgba(255, 255, 255, 0.8);
    height: 87px;
}

.INCFeatureList_litext {
    margin-bottom: 25px;
}

.INCFeatureList_litext_feature2 {
    align-items: center;
    font-family: Poppins;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 35px;
    text-align: left;
    color: rgba(255, 255, 255, 0.8);
    width: 94%;
}

.INCFeature_text_content.feature2 {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    margin-top: 10%;
    width: 50%;
    height: 100%;
    gap: 45px;
    padding-left: 100px;
    /* justify-content: center; */
}

.INCFeature_dot_box.feature2 {
    margin-top: 35px;
}

.INCFeature_dot_box.feature3 {
    margin-top: calc(35px + 35px);
}

.INCFeature_dot_box.feature4 {
    margin-top: calc(35px + 35px + 35px);
}

.INCFeature_dot_box.feature5 {
    margin-top: calc(35px + 35px + 35px + 35px);
}

.INCFeature_dot_box.feature6 {
    margin-top: calc(35px + 35px + 35px + 35px + 35px);
}

.INCFeature_dot_box.feature7 {
    margin-top: calc(35px + 35px + 35px + 35px + 35px + 35px);
}

.INCFeature_dot_box.feature8 {
    margin-top: calc(35px + 35px + 35px + 35px + 35px + 35px + 35px);
}

.INCFeature_text_content.feature8 {
    width: 100%;
    align-items: center;
}

.INCFeature_text_content.feature8 .INCFeature_text_content_heading {
    text-align: center;
    width: 100%;
}


.INCFeature8_benifits_lists_svg {
    height: 68px;
    width: 75px;
    background: rgba(27, 34, 43, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
}

.INCFeature_benifits_li_text {
    text-align: left;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    width: 265px;
}

.INCFeature8_benifits_lists_li {
    display: flex;
    align-items: center;
    gap: 25px;
}

.INCFeature8_benifits_container {
    width: 75%;
    height: 50%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 9px 60px;
}

.INCFeature8_benifits_lists {
    width: 450px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 17px;
    background: rgba(2, 10, 20, 1);
    transition: 600ms ease;
}

.INCFeature8_benifits_lists.li1 {
    border: 1.5px solid rgba(255, 87, 34, 0.3);
    animation: mymove1 5s infinite;

}

@keyframes mymove1 {
    50% {
        box-shadow: 0px 0px 35px 0px rgba(255, 87, 34, 0.5);
    }
}

/* .INCFeature8_benifits_lists.li1:hover {
    box-shadow: 0px 0px 35px 0px rgba(255, 87, 34, 0.3);
    transition: 600ms ease;

} */

.INCFeature8_benifits_lists.li2 {
    border: 1.5px solid rgba(250, 205, 73, 0.3);
    animation: mymove2 5s infinite;
}

@keyframes mymove2 {
    50% {
        box-shadow: 0px 0px 35px 0px rgba(250, 205, 73, 0.5);
    }
}

/* .INCFeature8_benifits_lists.li2:hover {
    box-shadow: 0px 0px 35px 0px rgba(250, 205, 73, 0.3);
    transition: 600ms ease;
} */

.INCFeature8_benifits_lists.li3 {
    border: 1.5px solid rgba(142, 129, 253, 0.3);
    animation: mymove3 5s infinite;
}

@keyframes mymove3 {
    50% {
        box-shadow: 0px 0px 35px 0px rgba(142, 129, 253, 0.5);
    }
}

/* .INCFeature8_benifits_lists.li3:hover {
    box-shadow: 0px 0px 35px 0px rgba(142, 129, 253, 0.3);
    transition: 600ms ease;
} */

.INCFeature8_benifits_lists.li4 {
    border: 1.5px solid rgba(51, 239, 160, 0.2);
    animation: mymove4 5s infinite;
}

@keyframes mymove4 {
    50% {
        box-shadow: 0px 0px 35px 0px rgba(51, 239, 160, 0.5);
    }
}

/* .INCFeature8_benifits_lists.li4:hover {
    box-shadow: 0px 0px 35px 0px rgba(51, 239, 160, 0.5);
    transition: 600ms ease;
} */
.INCLogbook_btn {
    height: 45px;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: left;
    width: 210px;
    background: #6a6a6a;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    transition: 300ms ease-in;
    cursor: pointer;
}

.INCLogbook_btn:hover {
    background: linear-gradient(180deg, #EE4661 41.09%, #92161A 145.93%);
    transition: 1000ms ease-in;
}

.INCFeature_asset_container img {
    width: 95%;
    height: auto;
}

.INCFeature_asset_container img.img1 {
    width: 85%;
    height: auto;
}

.INCFeature_asset_container img.INC_girl_animate_img {
    width: 85%;
    height: auto;
}

.INCFeatures_animated_girlImg_holder {
    position: relative;
    width: 95%;
    height: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.INCFeature_asset_container.feature2 {
    height: 100%;
}

.INCFeatures_animated_girlImg_holder img {
    position: absolute;
    width: 65%;
    height: auto;
    /* left: 20%; */
    /* top: 5%; */
}

.INCFeatures_animated_girlImg_holder .INC_girl_img3 {
    width: 65%;
}

.INCFeatures_animated_girlImg_holder img.INC_girl_img3 {
    position: absolute;
    width: 65%;
    height: auto;
    top: 5%;
    /* Set the initial top value */
    transition: top 700ms ease-in, width 700ms ease-in;
    /* Apply transition to top and width */
}

.INCFeature_text_content.feature1 {
    gap: 0;
}

.INCFeatures_animated_girlImg_holder:hover img.INC_girl_img3 {
    width: 68%;
    top: 1%;
}

.INCFeatures_animated_girlImg_holder img.INC_girl_img1,
.INCFeatures_animated_girlImg_holder img.INC_girl_img2,
.INCFeatures_animated_girlImg_holder img.INC_girl_img4,
.INCFeatures_animated_girlImg_holder img.INC_girl_img5 {
    position: absolute;
    width: 65%;
    height: auto;
    top: 5%;
    /* Set the initial top value */
    transition: top 700ms ease-in, width 700ms ease-in;
    /* Apply transition to top and width */
}

.INCFeatures_animated_girlImg_holder:hover img.INC_girl_img1 {
    top: 8%;
}

@media screen and ((max-width:1600px)) {
    .INCFeature_dot_container {
        height: 100%;
        width: 8%;
    }

    .INCFeature_content_container {
        width: 90%;
        height: 85%;
    }

    .INCFeature_asset_container img.img1 {
        width: 75%;
        height: auto;
    }

    .INCFeature_asset_container img {
        height: auto;
        width: 80%;
    }

    .INCFeatureList_litext_feature2 {
        font-size: 1.1rem;
        line-height: 30px;
    }

    .INCFeature_text_content_heading {
        font-size: 2rem;
        line-height: 45.5px;
        margin-top: 5%;
    }

    .INCFeature_text_content.feature1 .INCFeature_text_content_heading {
        margin-left: 6%;
    }

    .INCFeature_text_content {
        color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 4%;
        width: 40%;
        height: 90%;
        gap: 4%;

    }

    .INCFeature_text_content_list {
        margin-top: 6%;
        width: 72%;
    }

    .INCFeature_text_content.feature1 {
        gap: 0px;
    }


    .INCFeature_text_conent_list_li {
        display: flex;
        align-items: center;
        font-family: Poppins;
        font-size: 1.1rem;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        color: rgba(255, 255, 255, 0.8);
        height: 87px;
    }

    .INCFeature_text_content.feature2 {
        margin-top: 4%;
        width: 50%;
        height: 85%;
        gap: 30px;
        padding-left: 100px;
    }

    .INCFeature8_benifits_container {
        width: 92%;
        height: 50%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 9px 60px;
    }

    .INCFeature_heading {
        margin-left: 8%;
    }

    .INCFeature_heading_container {
        font-size: 2.5em;
        line-height: 55px;
    }

}

@media screen and ((max-width:1367px)) {

    .INCFeature_text_content.feature2 {
        margin-top: 2%;
        width: 50%;
        height: 85%;
        gap: 13px;
        padding-left: 100px;
    }

    .INCFeature_text_content_heading {
        font-size: 2rem;
        line-height: 43.5px;
        margin-top: 3%;
    }

    .INCFeatureList_litext_feature2 {
        font-size: 1rem;
        width: 94%;
        line-height: 30px;
    }

    .INCLogbook_btn {
        height: 43px;
        font-size: 15px;
        line-height: 28.8px;
        width: 200px;
        border-radius: 40px;
    }

    .INCFeature_content_container {
        width: 90%;
        height: 83%;
    }

    .INCFeature_text_content {
        margin-top: 0%;
    }

    .INCFeature_dot_box svg {
        height: 18px;
        width: auto;
        margin-right: 15px;
    }

    .INCFeature8_benifits_container {
        gap: 25px 60px;
    }

    .INCFeature_asset_container {
        height: 90%;
    }

    .INCFeature_text_content {
        margin-top: 5%;
    }

    .INCFeature_text_conent_list_li {
        font-size: 1rem;
        font-weight: 400;
        line-height: 23px;
        height: 72px;
    }

    .INCFeature_svg_container img {
        width: 100px;
        height: auto;
    }

    .INCFeature_text_content_list {
        margin-top: 6%;
        width: 100%;
    }

    .INCFeature_benifits_li_text {
        font-size: 15px;
        line-height: 25px;
        width: 265px;
    }

    .INCFeature8_benifits_lists_svg {
        height: 55px;
        width: 64px;
        border-radius: 10px;
    }

    .INCFeature8_benifits_lists {
        width: 411px;
        height: 85px;
    }

    .INCFeature_asset_container img {
        height: auto;
        width: 60%;
    }

}


@media screen and ((max-width:1080px)) {
    .INCFeature_dot_container {
        height: 100%;
        width: 10%;
    }

    .INCFeature_dot_box svg {
        height: 18px;
        width: auto;
        margin-right: 10px;
    }

    .INCFeature_content_container {
        flex-direction: column-reverse;
    }

    .INCFeature_text_content.feature1 {
        gap: 0px;
        width: 80%;
        align-items: center;
        justify-content: center;
    }

    .INCFeature_text_content {
        margin-top: 5%;
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .INCFeature_text_content_heading {
        font-size: 1.5rem;
        line-height: 40.5px;
        margin-top: 3%;
        text-align: center;
    }

    .INCFeatureList_litext_feature2 {
        font-size: 1rem;
        width: 94%;
        line-height: 30px;
        text-align: center;
    }

    .INCFeature_asset_container {
        height: 90%;
        width: 80%;
    }

    .INCFeature_text_content.feature2 {
        margin-top: 2%;
        width: 80%;
        height: 85%;
        gap: 13px;
        padding-left: 0px;
    }

    .INCFeature_text_content {
        gap: 10%;
    }

    .INCFeature_content_container {
        border-radius: 22px;
    }
}

@media screen and ((max-width:620px)) {
    .INCFeature_dot_box svg {
        height: 14px;
        width: auto;
        margin-right: 3px;
    }

    .INCFeature_heading_container {
        font-size: 1.7rem;
    }

    .INCFeature_text_content_heading {
        font-size: 1.4rem;
        line-height: 30.5px;
    }

    .INCFeatures.INCFeature1 .INCFeature_text_content_heading {
        margin-top: 10%;
    }

    .INCFeature_text_content.feature2 .INCFeature_text_content_heading {
        margin-top: 3%;
    }

    .INCFeatureList_litext_feature2 {
        margin-bottom: 5%;
    }



    .INCFeature_text_content.feature8 .endFeature {
        margin-top: 3%;
    }

    .INCFeature_text_conent_list_li {
        height: 60px;
    }

    .INCFeature_svg_container img {
        width: 66px;
    }

    .INCFeatureList_litext {
        margin-bottom: 22px;
    }

    .INCFeature_text_conent_list_li {
        font-size: 0.7rem;
        line-height: 16px;
        height: 60px;
    }

    .INCFeature_asset_container {
        height: 90%;
        width: 100%;
    }

    .INCFeature_asset_container img {
        height: auto;
        width: 75%;
    }

    .INCFeature_content_container {
        margin-left: 10px;
    }

    .INCFeatureList_litext_feature2 {
        font-size: 0.7rem;
        width: 94%;
        line-height: 22px;
        text-align: center;
    }

    .INCLogbook_btn {
        height: 35px;
        font-size: 12px;
        line-height: 27.8px;
        width: 170px;
        border-radius: 40px;
    }

    .INCFeature_text_content {
        gap: 5%;
    }

    .INCFeature_text_content_heading {
        font-size: 1rem;
        line-height: 25.5px;
    }

    .INCFeature_benifits_li_text {
        font-size: 10px;
        line-height: 18px;
        width: 75%;
    }

    .INCFeature8_benifits_lists_svg {
        height: 35px;
        width: 48px;
        border-radius: 5px;
    }

    .INCFeature8_benifits_lists_svg svg {
        width: 18px;
    }

    .INCFeature8_benifits_lists {
        width: 450px;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        border-radius: 17px;
        background: rgba(2, 10, 20, 1);
        transition: 600ms ease;
        padding-left: 8%;
    }

    .INCFeature_text_content.feature8 {
        width: 95%;
        align-items: center;
    }

    .INCFeature_info_container {
        width: 89%;
        height: 100%;
    }

    .INCFeature_text_content.feature2 {
        margin-top: 0%;
        width: 95%;
        height: 85%;
        gap: 13px;
        padding-left: 0px;
        margin-bottom: 10px;
    }

    .INC_girl_animate_img {
        width: 50% !important;
    }

    .INCFeature_text_content {
        margin-top: 2%;
        width: 85%;
    }

    .INCFeature8_benifits_lists_svg {
        height: 30px;
        width: 38px;
        border-radius: 5px;
    }

}
.INCTeamInfo_heading {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 600;
    line-height: 60px;
    text-align: left;
    color: var(--primary-text-color);
    margin-top: 70px;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    width: 80%;
}

.INCTeamInfo_heading span {
    color: #00C2FF;
}

.INCTeamInfo {
    width: 100%;
    height: 130vh;
}

.INCTeamInfo_container {
    height: 100%;
    width: 100%;
    background-image: url("/public/assets/images/about/teamInfo/teaminfobackground.png");
    background-size: 100% 95%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

img.star_background {
    position: absolute;
    top: 0px;
    left: 0px;
}

.INCTeamInfo_heading_design_circle {
    width: 100px;
    height: 100px;
    background: #FFFFFF0F;
    border-radius: 70px;
    position: absolute;
    left: -40px;
}

.INCTeamInfo_profile_container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.INCTeamInfo_head_profile_info_container {
    position: relative;
}

.INCTeamInfo_head_profile_info_container img {
    width: 219.05px;
    height: auto;
}

.INCDirector_info img {
    width: 380px;
    height: auto;
}

.INCTeamInfo_head_image_designation.director {
    font-size: 16px;
}

.INCTeamInfo_head_profile_info_container_holder {
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.INCTeamInfo_head_profile_info_container_bucket {
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.INCTeamInfo_head_image_info {
    width: 100%;
    color: var(--primary-text-color);
    position: absolute;
    left: 0;
    transition: 1000ms ease;
    opacity: 1;
    display: none;

}

.INCTeamInfo_head_image_info_shadow_holder {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.INCTeamInfo_head_image_info_shadow_holder svg {
    position: absolute;
    top: -165px;
    pointer-events: none;
}

.INCTeamInfo_head_image_heading {
    position: relative;
    z-index: 1;
    font-family: Poppins;
    font-size: 17.31px;
    font-weight: 600;
    line-height: 25.97px;
    text-align: left;
    color: var(--primary-text-color);
    width: 80%;

}

.INCTeamInfo_head_image_designation {
    position: relative;
    z-index: 1;
    font-family: Poppins;
    font-size: 13.85px;
    font-weight: 500;
    line-height: 20.77px;
    text-align: left;
    color: #D9D9D9;
    width: 80%;
    margin-bottom: 15px;
}

.INCTeamInfo_head_image_container {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.INCTeamInfo_head_bio_container {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(153, 153, 153, 0) 0%, rgba(255, 255, 255, 0.9) 57.44%);
    top: 0;
    border-radius: 15px;
    transition: 1000ms ease;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.INCDirector_info .INCTeamInfo_head_bio_container {
    background: #FFFFFFF2;
}

.INCTeamInfo_head_container:hover .INCTeamInfo_head_image_info {
    display: block;
    transition: 1000ms ease;
    opacity: 0;
}

.INCTeamInfo_head_container:hover .INCTeamInfo_head_bio_container {
    transition: 1000ms ease;
    opacity: 1;
}

.INCTeamInfo_head_bio_text {
    width: 100%;
    /* height: 65%; */
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-top: 10px;
    text-align: left;
    /* padding-left: 15px; */
    margin-bottom: 15px;
}

.INCTeamInfo_head_bio_text_heading {
    font-family: Poppins;
    font-size: 19.66px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
    color: #000000CC;
}

.INCTeamInfo_head_bio_text_designation {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: #00000099;
    display: flex;
    gap: 3px;
    flex-direction: row;
    align-items: center;
}


.INCTeamInfo_head_bio_text_designation .span1 {
    display: flex;
    gap: 2px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 27.2px;
    text-align: left;
    color: #000000CC;
}

.INCTeamInfo_head_bio_text_designation .span3 {
    display: flex;
    gap: 2px;
    font-weight: 500;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.03em;
    text-align: left;
}

.INCTeamInfo_head_bio_text_designation .span2 {
    display: flex;
    position: relative;
    align-items: center;
    gap: 2px;
}

.INCTeamInfo_head_bio_text_designation .span2 svg {
    position: relative;
    margin-left: 2px;
}

.INCTeamInfo_lead_profile_info_container.part3 {
    display: none;
}

.INCTeamInfo_head_bio_text_descreaption li {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 23.8px;
    letter-spacing: 0.03em;
    text-align: left;

}

.INCTeamInfo_head_bio_text_descreaption li span {
    font-weight: 600;
}



.INCTeamInfo_head_bio_text_info {
    position: relative;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 18px;
}

.INCTeamInfo_head_bio_text_info svg {
    position: absolute;
    left: 0;
}

.INCTeamInfo_head_bio_heading {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.INCTeamInfo_instructiors {
    display: flex;
    margin-top: 60px;
    flex-direction: row;
    gap: 60px;
    align-items: flex-end;
}

.INCTeamInfo_lead_profile_info_container {
    position: relative;
}

.INCTeamInfo_lead_profile_info {
    position: relative;
    width: fit-content;
}

.INCTeamInfo_lead_profile_info_img_container {
    position: relative;
}

.INCTeamInfo_lead_profile_info_img {
    width: 195.7px;
    height: auto;
}

.INCTeamInfo_lead_profile_name {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
}

.INCTeamInfo_lead_profile_info_profile_container {
    position: absolute;
    top: 0;
    left: 0;
    background: url("/public/assets/images/about/teamInfo/Polygon\ 16\ background.png");
    background-repeat: no-repeat;
    background-size: 100% 98%;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: 1000ms ease;
    opacity: 0;

}

.INCTeamInfo_lead_profile_designation {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
}

.INCTeamInfo_lead_profile_info:hover .INCTeamInfo_lead_profile_info_profile_container {
    transition: 1000ms ease;
    opacity: 1;
}


.INCTeamInfo_lead_profile_info_container {
    position: relative;
    display: flex;
    gap: 40px;
    width: 100%;
}

.INCTeamInfo_lead_profile_info_container_parts {
    display: flex;
    flex-direction: column;
    width: -moz-fit-content;
    width: 67%;
    margin-top: 18%;
}

.INCTeamInfo_lead_profile_info_container.part1 {
    margin-right: 15px;
}


.INCTeamInfo_lead_profile_info_container.part2 {
    justify-content: flex-end;
}

.INCTeamInfo_heading_text {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #FFFFFFCC;
    margin-top: 20px;
}

@media screen and (max-width:1600px) {
    .INCTeamInfo_heading {
        font-size: 32px;
        line-height: 41px;
    }

    .INCTeamInfo_heading_design_circle {
        width: 72px;
        height: 72px;
        left: -32px;
    }

    .INCTeamInfo_head_image_container img {
        width: 250px;
        height: auto;
    }

    .INCDirector_info img {
        width: 380px;
        height: auto;
    }

    .INCTeamInfo_container {
        height: unset;

    }

    .INCTeamInfo {

        height: unset;
    }

    .INCTeamInfo_lead_profile_info_container_parts {
        width: 60%;
        margin-top: 10%;
    }

}

@media screen and (max-width:1367px) {
    .INCTeamInfo_head_image_container img {
        width: 180px;
        height: auto;
    }

    .INCTeamInfo_head_bio_heading svg {
        width: 115px;
    }

    .INCTeamInfo_head_bio_heading .svg1 {
        width: 125px;
        height: auto;
    }

    .INCTeamInfo_head_bio_heading .svg2 {
        width: 35px;
        height: auto;
    }

    .INCTeamInfo_head_bio_text_heading {
        font-size: 16px;
        line-height: 24px;
    }

    .INCTeamInfo_head_bio_text_descreaption li {
        font-size: 12px;
        line-height: 16.8px;

    }

    .INCTeamInfo_head_bio_text_designation {
        font-size: 10px;
        line-height: 12px;
    }

    .INCTeamInfo_head_bio_text_heading {
        font-size: 13px;
        line-height: 20px;
    }

    .INCTeamInfo_head_bio_text {

        margin-bottom: 0px;
    }

    .INCTeamInfo_head_bio_text_designation .span1 {
        font-size: 12px;
        line-height: 18.2px;

    }

    .INCTeamInfo_head_bio_text_designation .span2 svg {
        position: relative;
        width: 70px;
    }

    .INCTeamInfo_head_bio_text_designation .span3 {
        font-size: 9px;
        line-height: 14px;

    }

    .INCTeamInfo_head_bio_text {

        gap: 5px;
    }

    svg.directorName {
        width: 225px !important;
    }

    .INCTeamInfo_head_bio_text_designation {

        font-size: 12px;
        line-height: 17px;

    }

    img.INCTeamInfo_lead_profile_info_img {
        width: 160px;
        height: auto;
    }

    .INCTeamInfo_head_profile_info_container_holder {
        gap: 40px;
    }

    .INCTeamInfo_lead_profile_info_container_parts {
        width: 65%;
    }

    .INCTeamInfo_lead_profile_name {
        font-size: 14px;
        line-height: 18px;
    }

    .INCTeamInfo_lead_profile_designation {
        font-size: 10px;
        line-height: 15px;

    }

    .INCDirector_info img {
        width: 300px;
        height: auto;
    }
}

@media screen and (max-width:1024px) {
    .INCTeamInfo_instructiors {
        display: grid;
        margin-top: 60px;
        gap: 26px;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
    }

    .INCTeamInfo_container {
        background-image: url("/public/assets/images/about/teamInfo/teaminfobackgroundtab.png");
        background-size: 100% 95%;
        background-repeat: no-repeat;
    }

    .INCTeamInfo_heading {
        font-size: 25px;
        line-height: 31px;
    }

    img.INCTeamInfo_lead_profile_info_img {
        width: 150px;
        height: auto;
    }

    .INCTeamInfo_lead_profile_info_container_parts {
        width: 100%;
    }

    .INCTeamInfo_instructiors {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width:620px) {
    .INCTeamInfo_heading {
        font-size: 23px;
        line-height: 31px;
    }

    .INCTeamInfo_container {
        background-image: url("/public/assets/images/about/teamInfo/teaminfobackgroundmob.png");
        background-size: 100% auto;
    }

    .INCTeamInfo_heading_design_circle {
        width: 50px;
        height: 50px;
        left: -20px;
    }

    .INCTeamInfo_head_image_container img {
        width: 135px;
        height: auto;
    }

    .INCTeamInfo_head_image_heading {
        font-size: 11.31px;
        line-height: 17.97px;

    }

    .INCTeamInfo_head_image_designation {
        font-size: 9.85px;
        line-height: 13.77px;
    }

    .INCTeamInfo_head_bio_container {
        border-radius: 10px;

    }

    .INCTeamInfo_head_bio_heading .svg1 {
        width: 87px;
        height: auto;
    }

    .INCTeamInfo_head_bio_heading .svg2 {
        width: 30px;
        height: auto;
    }

    .INCTeamInfo_head_bio_text_heading {
        font-size: 14px;
        line-height: 14px;
    }

    .INCTeamInfo_head_bio_text_designation {
        font-size: 11px;
        line-height: 15px;
    }

    .INCTeamInfo_head_bio_text_info svg {
        height: 172px;
    }

    .INCTeamInfo_head_bio_text {
        margin-top: 2px;
        padding-left: 3px;
    }

    svg.directorName {
        width: 210px !important;
    }

    .INCDirector_info .INCTeamInfo_head_image_container img {
        width: 300px;
        height: auto;
    }

    /* .INCWhatisincubator {
        margin-top: 20px;
    } */

    .INCTeamInfo_head_bio_text_info svg {
        height: 30px;
        width: auto;
    }

    .INCTeamInfo_lead_profile_info_container.part3 {
        display: flex;
    }

    .INCTeamInfo_lead_profile_info_container.part1 .third {
        display: none;
    }

    .INCTeamInfo_lead_profile_info_container.part2 .first {
        display: none;
    }

    img.INCTeamInfo_lead_profile_info_img {
        width: 140px;
        height: auto;
    }

    .INCTeamInfo_lead_profile_info_container {
        gap: 23px;
        justify-content: center;
    }

    .INCTeamInfo_lead_profile_info_container_parts {
        width: 110%;
        gap: 12px;
    }

    .INCTeamInfo_lead_profile_info_container.part3 {
        display: flex;
        justify-content: center;
    }

    .INCTeamInfo_lead_profile_info_container.part2 {
        justify-content: center;
    }

    .INCTeamInfo_lead_profile_name {
        font-size: 13px;
        line-height: 16px;
    }

    .INCTeamInfo_lead_profile_designation {
        font-size: 10px;
        line-height: 12px;
        font-weight: 300;
    }

    .INCTeamInfo_lead_profile_info_profile_container svg {
        width: 25px;
        height: auto;
        margin-top: 5px;
    }

    .INCTeamInfo_lead_profile_info:hover .INCTeamInfo_lead_profile_info_profile_container {
        gap: 5px;
    }

    .INCTeamInfo_container {
        background-size: 100% auto;
    }

    .INCTeamInfo_lead_profile_info_container_parts {
        margin-top: 20%;
    }



    .INCEventGallary_images_container {
        display: flex;
        flex-direction: column;
    }

    .INCTeamInfo_head_profile_info_container_bucket {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    .INCTeamInfo_head_image_container img {
        width: 240px;
        height: auto;
    }

    .INCTeamInfo_head_bio_text_descreaption ul {
        padding-left: 25px;
        margin-top: 10px;
    }

    .INCTeamInfo_head_bio_text_descreaption li {
        font-size: 11px;
        line-height: 18.8px;
        margin-right: 10px;
    }

    .INCTeamInfo_head_bio_text_info {
        margin-left: 15px;
        padding-left: 10px;
    }

    .INCTeamInfo_head_bio_heading {
        margin-top: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        padding: 0px 20px;
    }

    .INCTeamInfo_head_bio_text {
        margin-top: 2px;
        padding-left: 3px;
        margin-bottom: 15px;
    }

    .INCDirector_info .INCTeamInfo_head_bio_text {
        margin-bottom: -3px;
    }

    .INCTeamInfo_head_bio_text_designation .span1 {
        align-items: center;
    }

    .INCTeamInfo_info_text_holder {
        padding-left: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .INCTeamInfo_heading_text {
        text-align: center;
    }

    .INCAboutLanding_journey::-webkit-scrollbar {
        width: 10px;
        /* width of the scrollbar */
        position: absolute;
        left: 0;
    }

    /* Track */
    .INCAboutLanding_journey::-webkit-scrollbar-track {
        background: transparent;
        /* color of the track */
    }



    /* Handle */
    .INCAboutLanding_journey::-webkit-scrollbar-thumb {
        background: #007aff;
        border-radius: 15px;
        /* color of the scrollbar handle */
    }

    /* Handle on hover */
    .INCAboutLanding_journey::-webkit-scrollbar-thumb:hover {
        background: #f77802;
        /* color of the scrollbar handle when hovered */
    }

    .non-view {
        display: none;
        opacity: 1;
    }

    .inview {
        display: flex;
        opacity: 1;
    }



}
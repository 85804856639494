section {
    position: relative;
}

.progress {
    position: fixed;
    left: 0;
    right: 0;
    height: 5px;
    background: var(--accent);
    bottom: 100px;
}

.parallax {
    overflow: hidden;
    letter-spacing: -2px;
    line-height: 0.8;
    margin: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
}

.parallax .scroller {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 64px;
    display: flex;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
}

.parallax span {
    display: block;
    /* margin-right: 30px; */
}

.INCCampusTour_heading_container span {
    color: #00C2FF;

}

.INCScrollFramer img {
    margin: 20px;
    width: 400px;
    height: auto;
    border-radius: 15px;
}

.INCCampusTour_swiper_image_container {
    margin-bottom: 65px;
}

.INCCampusTour_heading_container {
    font-family: Poppins;
    font-size: 45px;
    font-weight: 600;
    line-height: 60px;
    text-align: center;
    color: #ffffff;
    margin-top: 100px;
    margin-bottom: 50px;
}

@media screen and (max-width:1600px) {
    .INCCampusTour_swiper_image_container img {
        width: 350px;
    }

    .INCScrollFramer img {
        margin: 20px;
        width: 290px;
        height: auto;
    }

    .INCCampusTour {
        margin-bottom: 20px;
    }

    .INCCampusTour_heading_container {
        font-size: 32px;
        line-height: 41px;
        margin-top: 75px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width:1367px) {
    .INCScrollFramer img {
        margin: 20px;
        width: 255px;
        height: auto;
    }

    .INCScrollFramer img {
        margin: 7px;
        width: 255px;
        height: auto;
    }
}

@media screen and (max-width:1025px) {
    .INCCampusTour_heading_container {
        font-size: 25px;
        line-height: 50px;
        margin-top: 75px;
        margin-bottom: 0px;
    }
}
.INCPlacelan_container {
    height: 100%;
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
}

.INCPlacelan_part {
    width: 50%;
    height: 100%;
}

.INCPlacelan_text_container {
    color: var(--primary-text-color);
    width: 70%;
    position: relative;
    z-index: 2;
    margin-top: 5%;
}

.INCPlacelan_heading {
    font-family: Poppins;
    font-size: 50px;
    font-weight: 500;
    line-height: 70.75px;
    text-align: left;
}

.INCPlacelan_heading.heading2 {
    font-family: Poppins;
    font-size: 50px;
    font-weight: 400;
    line-height: 70.75px;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
}

.INCPlacelan_background_highlight {
    position: absolute;
    width: 0px;
    height: 0px;
    /* background: aliceblue; */
    top: 35%;
    z-index: 1;
    left: 35%;
    box-shadow: 1px 0px 512px 179px rgb(0 137 236 / 31%);
}

.INCPlacelan_heading.heading3 {
    font-family: Oooh Baby;
    font-size: 60px;
    font-weight: 600;
    line-height: 84.9px;
    letter-spacing: 0.1em;
    text-align: left;
    color: rgba(0, 194, 255, 1);

}

.INCPlacelan_text {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 500;
    line-height: 38.5px;
    text-align: left;
    color: rgba(255, 255, 255, 0.51);
    margin-top: 20px;
}

.INCPlacelan_scroll_text {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.81px;
    text-align: left;
    color: rgba(255, 255, 255, 0.8);
    margin-top: 10%;
}

.INCPlacelan_downarrow_container {
    position: relative;
    width: max-content;
    display: flex;
    flex-direction: column;
    margin-top: 5%;
}

.INCPlacelan_part.part1 {
    width: 40%;
    padding-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

}

.INCPlacelan_part.part2 {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
}

.INCPlacelan_part.part2 img {
    width: 75%;
    /* height: 80%; */
}

.arrow-container {
    display: block;
    width: 100px;
    height: 150px;
    /* position: absolute; */
    /* top: 50%; */
    /* left: 50%; */
    transition: all .4s ease;
    position: relative;
}


/* .arrow-container .arrow-container:hover {
    cursor: pointer;
} */

.arrow-container:hover .arrow {
    top: 50%;
}

.arrow-container:hover .arrow:before {
    transition: all .4s ease;
    content: '';
    display: block;
    position: absolute;
    transform-origin: bottom right;
    background: #fff;
    width: 2px;
    /* Assuming $arrow-line-width is 2px */
    height: 50px;
    /* Assuming $arrow-line-length is 50px */
    border-radius: 10px;
    transform: translate(-50%, -50%) rotateZ(-30deg);
}

.arrow-container:hover .arrow:after {
    transition: all .4s ease;
    content: '';
    display: block;
    position: absolute;
    transform-origin: bottom left;
    background: #fff;
    width: 2px;
    /* Assuming $arrow-line-width is 2px */
    height: 50px;
    /* Assuming $arrow-line-length is 50px */
    border-radius: 10px;
    transform: translate(-50%, -50%) rotateZ(30deg);
}

.INCPlacelan_scroll_text {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.81px;
    text-align: left;
    color: rgba(255, 255, 255, 0.8);
}

.arrow-container .arrow {
    position: absolute;
    left: 50%;
    transition: all .4s ease;
}

.arrow-container .arrow:before,
.arrow-container .arrow:after {
    transition: all .4s ease;
    content: '';
    display: block;
    position: absolute;
    transform-origin: bottom right;
    background: #fff;
    width: 6px;
    /* Assuming $arrow-line-width is 2px */
    height: 50px;
    /* Assuming $arrow-line-length is 50px */
    border-radius: 10px;
    transform: translate(-50%, -50%) rotateZ(-45deg);
}

.arrow-container .arrow:after {
    transform-origin: bottom left;
    transform: translate(-50%, -50%) rotateZ(45deg);
}

.arrow-container .arrow:nth-child(1) {
    opacity: calc(0.3 * 1);
    top: calc(15% + (100% * 1 / 5));
    width: 10px;
}

.arrow-container .arrow:nth-child(1)::before {
    height: 20px;
}

.arrow-container .arrow:nth-child(1)::after {
    height: 20px;
}

.arrow-container .arrow:nth-child(2) {
    opacity: calc(0.3 * 2);
    top: calc(15% + (100% * 2 / 5));
}

.arrow-container .arrow:nth-child(2)::before {
    height: 30px;
}

.arrow-container .arrow:nth-child(2)::after {
    height: 30px;
}

.arrow-container .arrow:nth-child(3) {
    opacity: calc(0.3 * 3);
    top: calc(15% + (100% * 3 / 5));
}

.arrow-container .arrow:nth-child(4) {
    opacity: calc(0.3 * 4);
    top: calc(15% + (100% * 4 / 5));
}

.arrow-container .arrow:nth-child(5) {
    opacity: calc(0.3 * 5);
    top: calc(15% + (100% * 5 / 5));
}

@media screen and ((min-width:1025px) and (max-width:1367px)) {

    .arrow-container .arrow:before,
    .arrow-container .arrow:after {
        width: 4px;
        height: 40px;
    }

    .arrow-container {
        width: 100%;
        height: 130px;
    }

    .arrow-container:hover .arrow:after {
        width: 3px;
    }

    .arrow-container:hover .arrow:before {
        width: 3px;
    }
}

@media screen and (max-width:1024px) {
    .INCPlacelan_container {

        flex-direction: column;
    }

    .INCPlacelan_part.part1 {
        width: 80%;
        padding-left: 10%;
        height: 52%;
    }

    .INCPlacelan_part.part2 {
        width: 100%;
        height: 40%;
        justify-content: center;
    }

    .INCPlacelan_part.part2 img {
        width: 67%;
        /* height: 80%; */
    }

    .container {
        width: auto;
        height: auto;
    }

}

@media screen and (max-width:620px) {
    .INCPlacelan_text_container {
        width: 100%;
    }

    .INCPlacelan_part.part1 {

        padding-left: 0%;
    }

    .INCPlacelan_background_highlight {
        top: 0%;
        left: 50%;
        box-shadow: 1px 0px 200px 130px rgb(0 137 236 / 31%);
    }

    .INCPlacelan_part.part2 img {
        width: 90%;
        /* height: 80%; */
    }

    .INCPlacelan_heading {
        font-size: 29.95px;
        line-height: 42.33px;
    }

    .INCPlacelan_heading.heading2 {

        font-size: 29.95px;
        line-height: 42.33px;
    }

    .INCPlacelan_heading.heading3 {

        font-size: 35px;
        line-height: 50px;
    }

    .INCPlacelan_text {
        font-size: 13.18px;
        line-height: 23.06px;
    }
}
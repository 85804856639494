.INCAbout {
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container.INCAbout_container {
    /* width: 200vw; */
    width: auto;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}



.INCLanding_heading .img2 {
    margin-top: 5px;
}

.INCLanding_heading {
    position: absolute;
    top: 110px;
    left: 40%;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-family: Poppins;
    font-size: 31.5px;
    font-weight: 600;
    line-height: 29.95px;
    text-align: center;
    color: var(--primary-text-color);
    gap: 15px;
}

.AboutPanel {
    /* width: 100vw; */
    height: 100vh;
    position: sticky;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Aboutline {
    position: absolute;
    top: 0;
}

.container.INCAbout_container {
    background: url('/public/assets/images/about/landingPage/Frame\ 1116606855.png');
    background-size: 100% 100%;
}

.INCAboutPage {
    overflow-y: scroll;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
}

@media screen and (max-width:1537px) {
    .INCLanding_heading {
        top: 90px;
    }

    .INCLanding_heading .img1 {
        width: 300px;
    }

    .INCLanding_heading {
        top: 82px;
    }
}

@media screen and (max-width:1024px) {
    .container.INCAbout_container {
        width: auto;
        height: auto;
        position: relative;
    }

    .AboutPanel {
        /* width: 100vw; */
        height: auto;

    }

    .INCLanding_heading {
        display: none;
    }

}

@media screen and (max-width:620px) {
    .INCLanding_heading {
        display: none;
    }

    .INCLanding_heading .img1 {
        width: 230px;
    }
}
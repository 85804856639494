.INCFlutterRoadmap {
    height: 100%;
    width: 100%;
}

.INCFlutterRoadmap_container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.INCFlutterRoadmap_part_holder {
    height: 80%;
    width: 85%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
}


.INCFlutterRoadmap_heading_container p {
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 3.2rem;
    text-align: left;
    color: #ffffff;
}

.INCFlutterRoadmap_part .part1 {
    height: 100%;
    width: 60%;
}

.INCFlutterRoadmap_part .part2 {
    height: 100%;
    width: 60%;
}

.INCFlutterRoadmap_heading_container h1 {
    font-family: Poppins;
    font-size: 2.3em;
    font-weight: 600;
    line-height: 55px;
    letter-spacing: -0.0024em;
    text-align: left;
    color: var(--primary-text-color);
}

.INCFlutterRoadmap_heading_container span {
    color: rgba(0, 194, 255, 1);

}

.INCFlutterRoadmap_heading_container {
    width: 80%;
}

.INCFlutterRoadmap_part.part1 {
    width: 60%;
}

.INCFlutterRoadmap_part.part1 img {
    width: 95%;
}

.INCFlutterRoadmap_part.part2 {
    width: 40%;
}

.INCFlutterRoadmap_heading_container button {
    font-family: Poppins;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.8em;
    letter-spacing: -0.0024em;
    text-align: left;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 10px 30px 10px 30px;
    border-radius: 40px;
    color: var(--primary-text-color);
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 15%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15%;
}

.INCFlutterRoadmap_heading_container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.INCFlutterRoadmap_heading_container button:hover {

    background: linear-gradient(180deg, #EE4661 41.09%, #92161A 145.93%);
    transition: 1000ms ease;
}

.INCRoadmap_button svg {
    width: 24px;
    height: auto;
}

.INCRoadmap_button {
    cursor: pointer;
}

@media screen and (max-width:1367px) {
    .INCFlutterRoadmap_heading_container h1 {
        font-size: 2rem;
        line-height: 48px;
    }

    .INCFlutterRoadmap_heading_container p {
        font-size: 1.2rem;
        line-height: 3.2rem;
    }
}

@media screen and (max-width:1024px) {
    .INCFlutterRoadmap_part_holder {
        height: 90%;
        width: 98%;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        justify-content: center;
    }

    .INCFlutterRoadmap_part.part1 img {
        width: 95%;
    }

    .INCFlutterRoadmap_part.part2 {
        width: 85%;
    }

    .INCFlutterRoadmap_heading_container button {
        margin-top: 8%;
    }

    .INCFlutterRoadmap_heading_container {
        align-items: flex-end;
    }

    .INCFlutterRoadmap_heading_container p {
        width: 100%;
    }

    .INCFlutterRoadmap_part.part1 {
        width: 85%;
    }

    .INCFLutterPrequisite_heading_text {
        font-size: 1.4rem;
        margin-top: 7px;
        line-height: 35px;
    }


}

@media screen and (max-width:620px) {
    .INCFlutterRoadmap_heading_container h1 {
        font-size: 18px;
        line-height: 28px;
        text-align: center;
    }

    .INCFlutterRoadmap_heading_container p {
        font-size: 12px;
        line-height: 2.2rem;
    }

    .INCFlutterRoadmap_heading_container button {
        font-size: 0.8rem;
        line-height: 1.8em;
        padding: 7px 25px 7px 25px;
    }

    .INCRoadmap_button svg {
        width: 15px;
        height: auto;
    }

    .INCFlutterRoadmap_part.part1 img {
        width: 100%;
    }

    .INCFlutterRoadmap {
        height: unset;
    }

    .INCFlutterRoadmap_part_holder {
        margin-top: 50px;
    }

}
.INCAboutLanding_ {
    display: block;
    position: relative;
}

.INCAboutLanding_journey {
    position: relative;
}

.INCAblouLanding_journey_content_holder {
    position: relative;
    display: flex;
    flex-direction: column;
    width: max-content;
    position: absolute;
    gap: 65px;
}

.INCAblouLanding_journey_content_holder.j1 {
    top: 125px;
}

.INCAblouLanding_journey_content_holder.j2-rev {
    top: 55px;
    left: 222px;
}

.INCAblouLanding_journey_content_holder.j3 {
    top: 230px;
    left: 455px;
}

.j4-rev {
    left: 680px;
    top: 50px;
    gap: 30px;
}

.j5 {
    left: 890px;
    top: 120px;
    gap: 50px;
}

.j6 {
    left: 1140px;
    top: 129px;
    gap: 70px;
}

.j7-rev {
    left: 1352px;
    top: 129px;
    gap: 40px;
}

.j8 {
    left: 1140px;
    top: 129px;
    gap: 70px;
}

.j8 {
    left: 1597px;
    top: -50px;
    gap: 25px;
}

.j9 {
    left: 1830px;
    top: 0px;
    gap: 55px;
}

.j10 {
    left: 1830px;
    top: 0px;
    gap: 55px;
}

.INCAboutLanding_container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.INCAboutLanding_container .jShashiSir_image {
    margin-bottom: 100px;
    width: 240px;
}

.INCAblouLanding_journey_second_content_holder h1 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.002em;
    text-align: center;
    color: var(--primary-text-color);
    margin: 0px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.INCAblouLanding_journey_second_content_holder h3 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: -0.002em;
    text-align: center;
    color: #00C2FF;
    margin: 0px;
    margin-bottom: 0px;
}

.INCAblouLanding_journey_second_content_holder p {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 17.4px;
    letter-spacing: -0.0024em;
    text-align: center;
    color: #FFFFFFB2;
    margin-bottom: 2px;
}

.INCAblouLanding_journey_second_content_holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
}

.j1 img {
    width: 150px;
}

.j3 img {
    width: 150px;
}


.j4-rev img {
    width: 200px;
}

.j5 img {
    margin-left: 70px;
    width: 145px;
}

.j6 img {
    width: 195px;
}

.j7-rev img {
    width: 220px;
    margin-left: 115px;
}

.j8 img {
    width: 405px;
}

.j9 img {
    margin-left: 85px;
    width: 335px;
}

.INCAblouLanding_journey_second_content_holder img {
    margin-bottom: 10px;
}

.j10 {
    left: 2057px;
    top: 240px;
    gap: 55px;
}

.INCAblouLanding_journey_second_content_holder p {
    width: 190px;
}

.INCAblouLanding_journey_content_holder.j11 {
    top: 85px;
    left: 2280px;
}

.j11 img {
    width: 100px;
}

.INCAboutLanding_journey_mob {
    height: 80%;
    /* width: 100%; */
    display: flex;
    justify-content: center;
    overflow: scroll;
    border-bottom: 1px solid rgb(56 62 70);
}

.INCAboutLanding_journey_mob {
    display: flex;
    justify-content: center;
    overflow: scroll;
}

.INCAboutLanding_journey_mob_container .verticalLine {
    margin-top: 100px;
}

.INCAboutLanding_journey_mob_container {
    position: relative;
}

.INCAblouLanding_journey_content_holder.j_mob {
    top: 170px;
    left: -10px;
}

.INCAboutLanding_journey_mob p {
    margin-top: 0;
}

.INCAboutLanding_journey_mob h1 {
    margin-bottom: 1px;
}

.INCAboutLanding_journey_mob h3 {
    font-size: 14px;
    width: 240px;
    line-height: 18px;
    margin-bottom: 3px;
}

.j_mob img {
    margin-bottom: 10px;
    width: 135px;
}

.j_mob-2 {
    top: 445px;
    left: -10px;
}

.j_mob-2 img {
    margin-top: 10px;
    width: 170px;
}

.j_mob-3 {
    top: 725px;
    left: -15px;
}

.j_mob-3 img {
    margin-top: 10px;
    width: 139px;
}

.j_mob-4 {
    top: 990px;
    left: -10px;
}

.j_mob-5 img {
    margin-top: 10px;
    width: 139px;
}

.j_mob-5 {
    top: 1269px;
    left: -15px;
}

.j_mob-6 img {
    margin-top: 10px;
    width: 115px;
}

.j_mob-6 {
    top: 1542px;
    left: -15px;
}

.j_mob-7 img {
    margin-top: 10px;
    width: 175px;
}

.j_mob-7 {
    top: 1821px;
    left: -15px;
}

.j_mob-8 img {
    /* margin-top: 10px; */
    width: 205px;
}

.j_mob-8 {
    top: 2100px;
    left: -15px;
}

.j_mob-9 img {
    /* margin-top: 10px; */
    width: 205px;
}

.j_mob-9 {
    top: 2375px;
    left: -15px;
}

.j_mob-10 {
    top: 2770px;
    left: -15px;
}

.j_mob-11 {
    top: 2770px;
    left: -15px;
}

.j_mob-11 {
    top: 2920px;
    left: -15px;
}

.incHeading {
    display: none;
}

@media screen and (max-width:1537px) {
    .INCAboutLanding_container .jShashiSir_image {
        width: 190px;
    }

    .timeline_svg {
        width: 1840px;
    }

    .INCAblouLanding_journey_content_holder {
        gap: 40px;
    }

    .j1 img {
        width: 135px;
    }

    .INCAblouLanding_journey_content_holder.j1 {
        top: 137px;
        left: -42px;
    }

    .INCAblouLanding_journey_second_content_holder h3 {
        font-size: 13px;
        line-height: 20px;
        width: 12pc;
    }

    .INCAblouLanding_journey_second_content_holder h1 {
        margin-bottom: 3px;
        font-size: 16px;
        line-height: 28px;
        margin-top: 7px;
    }

    .INCAblouLanding_journey_first_content_holder .img1 {
        width: 152px;
    }

    .INCAblouLanding_journey_content_holder.j2-rev {
        top: 84px;
        left: 117px;
    }

    .INCAblouLanding_journey_content_holder.j3 {
        top: 199px;
        left: 284px;
    }

    .j4-rev img {
        width: 160px;
    }

    .j4-rev img {
        width: 160px;
    }

    .j4-rev {
        left: 445px;
        top: 83px;
        gap: 20px;
    }

    .j5 {
        left: 599px;
        top: 130px;
        gap: 35px;
    }

    .j5 img {
        margin-left: 27px;
        width: 130px;
    }

    .j6 {
        left: 767px;
        top: 159px;
        gap: 40px;
    }

    .j6 img {
        width: 150px;
    }

    .j7-rev {
        left: 922px;
        top: 129px;
        gap: 40px;
    }

    .j7-rev img {
        width: 204px;
        margin-left: 98px;
    }

    .j8 {
        left: 1168px;
        top: 35px;
        gap: 25px;
    }

    .j8 img {
        width: 295px;
    }

    .j8 .INCAblouLanding_journey_second_content_holder {
        align-items: flex-start;

    }

    .j9 {
        left: 1261px;
        top: 90px;
        gap: 40px;
    }

    .j9 img {
        margin-left: 104px;
        width: 220px;
    }

    .INCAblouLanding_journey_second_content_holder img {
        margin-bottom: 5px;
        width: 115px;
    }

    .INCAblouLanding_journey_second_content_holder p {
        width: 160px;
    }

    .j10 {
        left: 1420px;
        top: 240px;
        gap: 55px;
    }

    .INCAblouLanding_journey_content_holder.j11 {
        top: 103px;
        left: 1582px;
    }

    .j11 img {
        width: 88px;
    }
}

@media screen and (max-width:1024px) {
    .INCAblouLanding_journey_second_content_holder h3 {
        width: 14pc;
    }

    .incHeading {
        width: 370px;
        height: auto;
        position: absolute;
        top: 35px;
        left: -16%;
        display: block;
    }

    .INCAboutLanding_journey_mob_container {
        position: relative;
        margin-top: 50px;
    }
}

@media screen and (max-width:620px) {
    .INCAboutLanding_journey_mob {
        overflow-x: hidden;
    }


    .incHeading {
        width: 248px;
        top: 30px;
        left: 7%;

    }

}
.FlutterResources {
    width: 100%;
    height: 100%;

}

.FlutterResources_container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("/public//assets//images//flutter//flutterResources/parabolic\ background.png");
    background-size: 100%;
    background-repeat: no-repeat;
}

.FlutterResources_holder {
    height: 80%;
    width: 90%;
    display: flex;
    gap: 0%;
    flex-direction: column;
    margin-top: 5%;
    position: relative;
}

.FlutterResource_s_svg_holder {
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.1);
}

.FlutterResource_holder {
    background: rgb(28 35 44);
    display: flex;
    font-family: Poppins;
    font-size: 25px;
    font-weight: 500;
    line-height: 48.63px;
    letter-spacing: -0.0024em;
    text-align: left;
    color: var(--primary-text-color);
    align-items: center;
    gap: 20px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 20px 80px 20px 40px;
    border-radius: 20px;
    position: relative;
    height: -moz-fit-content;
    height: fit-content;
}

.FlutterResource_s_svg_holder svg {
    width: auto;
    height: 50%;
}

.FlutterResource_holder_container {
    height: 135px;
    display: flex;
    width: 50%;
}



.FlutterResource_holder_container.s1 {
    justify-content: flex-end;
    width: 35%;
}

.FlutterResource_holder_container.s3 {
    width: 38%;
    justify-content: flex-end;
}

.FlutterResource_holder_container.s4 {
    height: 182px;
    gap: 7%;
    display: flex;
    justify-content: flex-end;
    width: 55%;
}

.FlutterResource_holder_container.s5 {
    width: 85%;
    height: 182px;
    gap: 4%;
    display: flex;
    justify-content: flex-end;
}

.FlutterResource_holder.s42 {
    margin-top: 10%;
}

.FlutterResource_holder.s52 {
    margin-top: 4%;
}

.FlutterResource_holder_inner {
    width: fit-content;
    position: relative;
    height: fit-content;
}

.FlutterResource_holder_inner .left {
    position: absolute;
    left: 5%;
    top: 87%;
    height: 114px;
    width: 18px;
}

.FlutterResource_holder_inner .right {
    position: absolute;
    right: 5%;
    top: 98%;
    height: 114px;
    width: 18px;
}

.FlutterResources_text_content {
    color: #ffffff;
    width: 30%;
    /* background-color: aliceblue; */
    position: absolute;
    right: 10%;
    top: 25%;
}

.FlutterResources_text {
    font-family: Poppins;
    font-size: 45px;
    font-weight: 600;
    line-height: 80px;
    letter-spacing: -0.0024em;
    text-align: left;
}

.FlutterResources_text span {
    color: rgba(0, 194, 255, 1);
}

.FlutterResources_holder {
    height: 80%;
    width: 90%;
    display: flex;
    gap: 0%;
    flex-direction: column;
    margin-top: 5%;
    position: relative;
    align-items: flex-start;
}

.INCFluterResourse_content_container svg {
    height: auto;
    width: 100%;
    margin-left: 5%;
    margin-top: 5%;
}

@media screen and (max-width:1600px) {
    .FlutterResources_text {
        font-size: 2.3rem;
        line-height: 65px;
    }

    .FlutterResources_text_content {
        right: 8%;
    }

    .FlutterResource_holder {
        padding: 8px 80px 8px 40px;
    }

    .FlutterResource_s_svg_holder {
        height: 45px;
        width: 45px;
        font-size: 20px;
    }

    .INCFluterResourse_content_container svg {
        width: 76%;
        margin-left: 0%;
    }

    .FlutterResource_holder_inner .right {
        top: 80%;
        width: 13px;
    }

    .FlutterResource_holder_container.s4 {
        width: 62%;
    }

    .FlutterResource_holder_container.s5 {
        width: 90%;
    }

    .FlutterResource_holder.s52 {
        margin-top: 3%;
    }
}

@media screen and (max-width:1367px) {
    .FlutterResources_text {
        font-size: 2rem;
        line-height: 50px;
    }

    .FlutterResource_holder {
        font-size: 17px;
        line-height: 20.63px;
        border-radius: 10px;
    }

    .FlutterResource_s_svg_holder {
        height: 40px;
        width: 40px;
        font-size: 20px;
        border-radius: 8px;
    }


    .FlutterResource_holder_inner .left {
        left: 5%;
        top: 87%;
        height: 75px;
        width: 10px;
    }

    .FlutterResource_holder_inner .right {
        top: 82%;
        width: 4%;
        height: 105px;
    }

    .INCFluterResourse_content_container svg {
        height: auto;
        width: 85%;
        margin-left: 0%;
        margin-top: 5%;
    }
}

@media screen and (max-width:1024px) {
    .FlutterResources {
        width: 100%;
        height: 40%;
    }

    .FlutterResource_holder {
        padding: 9px 74px 9px 25px;
    }

    .FlutterResource_holder_container {
        height: 130px;
        display: flex;
        width: 50%;
    }

    .FlutterResource_holder_container.s1 {
        width: 45%;
    }

    .FlutterResource_holder_container.s4 {
        height: 150px;
        width: 70%;
    }

    .FlutterResource_holder_container.s3 {
        width: 45%;
    }

    .FlutterResource_holder_container.s5 {
        width: 100%;
    }

    .FlutterResources_text {
        font-size: 2rem;
        line-height: 40px;
    }

    .FlutterResources_holder {
        height: 85%;
    }

    .FlutterResources_text_content {
        color: #ffffff;
        width: 44%;
        position: absolute;
        right: 0px;
        top: 25%;
    }
}

@media screen and (max-width:825px) {

    .FlutterResource_holder {
        padding: 9px 40px 9px 15px;
        font-size: 15px;
    }

    .FlutterResource_holder_inner .right {
        top: 67%;
    }

    .FlutterResources_holder {
        width: 95%;
    }

    .FlutterResource_holder_container.s5 {
        height: 135px;
    }

}

@media screen and (max-width:620px) {
    .FlutterResource_holder {
        font-size: 8px;
        line-height: 10px;
        padding: 3px 15px 3px 9px;
        border-radius: 5px;
        gap: 8px;
    }

    .FlutterResources_text {
        font-size: 1.2rem;
        line-height: 22px;
        text-align: right;
    }

    .FlutterResource_s_svg_holder {
        height: 19px;
        width: 19px;
        font-size: 20px;
        border-radius: 4px;
    }

    .FlutterResource_holder_container {
        height: 50px;
    }

    .FlutterResource_holder_container.s4 {
        width: 77%;
        height: 65px;
    }

    .FlutterResource_holder_container.s5 {
        height: 65px;
    }

    .FlutterResources_text_content {
        width: 45%;
    }

    .FlutterResources_text_content {
        right: 5%;
        top: 12%;
    }

    .FlutterResources {
        width: 100%;
        height: 35%;
    }

    .FlutterResource_holder_inner .left {
        left: 0%;
        top: 87%;
        height: 100%;
        width: 26%;
    }

    .FlutterResource_holder_inner .right {
        top: 82%;
        width: 4%;
        height: 170%;
    }

    .INCFluterResourse_content_container svg {
        width: 100%;
    }

    .INCFlutterFeature_svg_container .svg1 {
        height: 40px;
        width: 40px;
    }


}
.INCFlutterYoutube {
    width: 100%;
    position: relative;
}

.INCYoutubeFrame_container {
    height: 100vh;
    width: 100vw;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000067;
    top: 0;
    left: 0;
    z-index: 20;
    position: fixed;
}

.INCYoutubeFrame_holder {
    height: 85%;
    width: 78%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
}

.INCYoutubeFrame_container iframe {
    height: 100%;
    width: 100%;
    border-radius: 20px;
    position: relative;
    z-index: 2;
}

.INCYoutubeFrame_holder svg {
    position: absolute;
    z-index: 2;
    margin-right: 10px;
    margin-bottom: 2px;
    top: -40px;
}

.INCYoutubeFrame_blur_container {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.INCYoutubeFrame_blur_container {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
}

.INCFlutterYoutube_uppar_part {
    /* height: 7.57vh; */
    width: 85%;
    margin-bottom: 3%;
    margin-top: 3%;
    position: relative;
}

.INCFlutterYoutube_container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.INCFlutterYoutube_pagename {
    font-family: Poppins;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 60px;
    text-align: left;
    color: var(--primary-text-color);
}

.INCFlutterYoutube_heading {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -0.0024em;
    text-align: left;
    font-size: clamp(2.3rem, 3.5vw, 2.3em);
    color: var(--primary-text-color);
    width: 70%;
}

.INCFlutterYoutube_heading span {
    color: rgba(0, 194, 255, 1);
}

.INCFlutterYoutube_view_more_button {
    position: absolute;
    right: 0;
    top: 0;
    width: 117px;
    height: 50px;
    border-radius: 25px;
    background: rgba(255, 255, 255, 0.2);
    color: var(--primary-text-color);
    top: 25%;
}


.INCFlutterYoutube_lower_part {
    width: 85%;
    display: flex;
    /* justify-content: flex-start; */
}

.INCFlutterYoutube_list_container {
    width: 100%;
    /* height: 100%; */
    border-radius: 10px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    justify-items: center;
    color: #ffffff;
    gap: 5%;
    margin-bottom: 5%;
}

.INCFlutterYoutube_info_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.INCFlutterYoutube_info {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
    width: 100%;
    margin-bottom: 5%;
}

.INCFlutterYoutube_name {
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    line-height: 32.5px;
    text-align: left;
    /* max-width: 65%; */
}

.INCFlutterYoutube_logo_similar img {
    margin-right: 15px;
    width: 50px;
}

.INCFlutterYoutube_textinfo {
    font-family: Poppins;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #FFFFFF99;
}

@media screen and (max-width:1367px) {
    .INCFlutterYoutube_pagename {
        font-size: 1.2rem;
    }

    .INCFlutterYoutube_heading {
        font-size: 40px;
        line-height: 55px;
        font-size: clamp(2rem, 3.5vw, 2em);
        line-height: 48px;
    }

    .INCFlutterYoutube_name {
        font-size: 25px;
        line-height: 30.5px;
    }

    .INCFlutterYoutube_textinfo {
        font-size: 1.1rem;
        line-height: 25px;
    }
}

@media screen and (max-width:1024px) {
    .INCFlutterYoutube_list_container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .INCFlutterYoutube_name {
        font-size: 22px;
        line-height: 25.5px;
    }

    .INCFlutterYoutube_textinfo {
        font-size: 1rem;
        line-height: 22px;
    }
}

@media screen and (max-width:620px) {
    .INCFlutterYoutube_pagename {
        font-size: 12px;
        margin-top: 25px;
        line-height: 18px;
    }

    .INCFlutterYoutube_heading {
        font-size: 18px;
        line-height: 28px;
        width: 100%;
    }

    .INCFlutterYoutube_list_container {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 50px;

    }

    .INCFlutterYoutube_uppar_part {
        margin-top: 15%;
    }

    .INCFlutterYoutube_name {
        font-size: 18px;
        line-height: 28px;
    }

    .INCFlutterYoutube_textinfo {
        font-size: 12px;
        line-height: 16px;
    }

    .INCFlutterYoutube_info {
        margin-top: 2%;
        margin-bottom: 2%;
    }
}
.INCFlutterCertificate {
    width: 100%;
    height: 100%;
}

.INCFluttercertificate_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.INCFluttercertificate_certificate_holder {
    position: relative;
}

.INCFluttercertificate_blur_container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.INCFluttercertificate_blur_container .svg1,
.INCFluttercertificate_blur_container .svg2,
.INCFluttercertificate_blur_container .svg3 {
    position: absolute;
}

.INCFluttercertificate_blur_container .svg1 {
    left: -10%;
    top: -5%;
}

.INCFluttercertificate_blur_container .svg2 {
    right: -10%;
    bottom: 20%;
}

.INCFluttercertificate_blur_container .svg3 {
    left: -12%;
    bottom: 10%;
}

.INFlutterCertificate_img {
    width: 45vw;
    height: auto;
    position: relative;
    z-index: 1;
}

@media screen and (max-width:1600px) {
    .INFlutterCertificate_img {
        width: 35vw;
    }
}

@media screen and (max-width:1024px) {
    .INFlutterCertificate_img {
        width: 75vw;
    }

    .INCFlutterFAQ_heading_Container h1 {
        line-height: 40px;
    }
}

@media screen and (max-width:620px) {
    .INCFluttercertificate_blur_container .svg2 {
        right: -22%;
        bottom: 20%;
    }

    .INCFlutterCertificate {
        width: 100%;
        height: 70%;
    }
}
.INCFlutterFeature {
    height: 100%;
    width: 100%;
}

.INCFlutterFeature_container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.INCFlutterFeature_svg_container {
    height: 80%;
    width: 85%;
    position: relative;
}

.INCFlutterFeature_svg_container .svg1 {
    position: absolute;
    left: 40%;
    height: 80px;
    width: 80px;
    top: 5%;
}

.INCFlutterFeature_svg_container .svg2 {
    right: 0%;
    position: absolute;
    bottom: 0%;
    height: 140px;
    width: 140px;
}

.INCFlutterFeature_info_part {
    width: 48%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.INCFlutterFeature_features_info_container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.INCFlutterFeature_heading_container p {
    font-family: Poppins;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    color: var(--primary-text-color);
}

.INCFlutterFeature_heading_container h1 {
    font-family: Poppins;
    font-size: 2.3rem;
    font-weight: 600;
    line-height: 55px;
    letter-spacing: -0.0024em;
    text-align: left;
    color: var(--primary-text-color);
}

.INCFlutterFeature_heading_container span {
    color: rgba(0, 194, 255, 1);

}

.INCFlutterFeature_heading_container {
    width: 70%;
}

.INCFlutterFeature_feature_list_li {
    display: flex;
    color: #ffff;
    align-items: center;
    flex-direction: row;
    background: rgba(23, 35, 50, 1);
    border-radius: 10px;
    margin-right: 30%;
}

.INCFlutterFeature_feature_list_li img {
    /* background: aliceblue; */
    margin: 11px 30px;
    width: 40px;
    height: auto;
}

.INCFlutterFeature_text {
    font-family: Poppins;
    font-size: 1.4em;
    font-weight: 500;
    line-height: 80px;
    letter-spacing: -0.0024em;
    text-align: left;
}

.INCFlutterFeature_feature_list_container {
    display: flex;
    flex-direction: column;
    gap: 3vh;
    margin-top: 12%;
}

.INCFlutterRoadmap_heading_container button {
    border: 0px solid;
}

@media screen and (max-width:1600px) {
    .INCFlutterFeature_text {
        font-size: 1.3em;
    }

    .INCFlutterFeature_feature_list_li {
        margin-right: 15%;
    }

    .INCFlutterRoadmap_heading_container p {
        margin-bottom: 0px;
    }

    .INCFlutterRoadmap_heading_container h1 {
        margin-top: 5px;
    }

    .INCFlutterRoadmap_heading_container button {
        margin-bottom: 15%;
        border: 0px solid;
    }

    .INCFlutterRoadmap_part.part1 img {
        width: 85%;
    }
}

@media screen and (max-width: 1367px) {
    .INCFlutterFeature_heading_container {
        width: 80%;
    }

    .INCFlutterFeature_info_part {
        width: 40%;
        height: 100%;
    }

    .INCFlutterFeature_heading_container p {
        font-size: 1.2rem;
    }

    .INCFlutterFeature_info_part.part2 {
        width: 60%;
    }

    .INCFlutterFeature_heading_container h1 {
        font-size: 2rem;
        line-height: 48px;
    }

    .INCFlutterFeature_feature_list_li img {
        margin: 9px 30px;
        width: 30px;
    }

    .INCFlutterFeature_text {
        font-size: 1.2rem;
        line-height: 60px;
    }

    .INCFlutterFeature_svg_container .svg1 {
        left: 30%;
        top: 15%;
    }
}

@media screen and (max-width: 1024px) {
    .INCFlutterFeature {
        height: 70%;
        width: 100%;
    }

    .INCFlutterFeature_heading_container {
        width: 100%;
        text-align: center;
    }

    .INCFlutterFeature_features_info_container {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .INCFlutterFeature_info_part {
        width: 90%;
    }

    .INCFlutterFeature_heading_container p {
        text-align: center;
    }

    .INCFlutterFeature_heading_container h1 {
        text-align: center;
    }

    .INCFlutterFeature_info_part.part2 {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .INCFlutterFeature_feature_list_li {
        margin-right: 0px;
    }

    .INCFlutterFeature_feature_list_li img {
        margin: 5px 23px;
        width: 22px;
    }

    .INCFlutterFeature_feature_list_container {
        width: 75%;
    }

    .INCFlutterFeature_svg_container .svg1 {
        left: 0%;
        top: 22%;
    }

    .INCFlutterFeature_svg_container .svg2 {
        right: -3%;
        bottom: -1%;
    }
}

@media screen and (max-width:820px) {
    .INCFlutterFeature {
        height: 95%;
        width: 100%;
    }
}

@media screen and (max-width:620px) {
    .INCFlutterFeature_heading_container p {
        font-size: 12px;
        line-height: 21.6px;
    }

    .INCFlutterFeature_heading_container h1 {
        font-size: 18px;
        line-height: 28.8px;

    }

    .INCFlutterFeature_text {
        font-size: 0.7rem;
        line-height: 50px;
    }

    .INCFlutterFeature_feature_list_container {
        width: 100%;
    }

    .INCFlutterFeature_feature_list_li img {
        margin: 5px 20px;
    }

    .INCFlutterFeature_svg_container .svg2 {
        right: -5%;
        bottom: -18%;
        width: 85px;
    }
}
html,
body {
    height: unset;
    width: unset;
    font-family: "Poppins", sans-serif;
    background-color: var(--primary-color);
}

#root {
    height: unset;
}

.App {
    text-align: center;
    height: unset;
    width: unset;
    background-color: var(--primary-color);
    position: relative;
}

.INCPlacementPage_dot_container {
    position: absolute;
    z-index: 5;
    left: 9vw;
    top: 14vh;
    display: flex;
    gap: 1.5vh;
}

.INCPlacementPage {
    overflow-y: scroll;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
}

.INCPlacementPage_scroll_container {
    overflow-y: visible;
    position: relative;
    overflow-x: hidden;
}

.INCPlacementPage,
.INCPlacementPage_scroll_container {
    margin: 0;
}

.INCPlacementFeature_subHeading {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.002em;
    text-align: left;
    color: rgba(255, 255, 255, 0.8);

}

.container {
    width: 900vw;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.firstContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px);
    position: sticky;
    left: 0;
}

.lastContainer {
    display: flex;
    height: 100vh;
    background: yellow;
}

.INCPlacementFeature_heading span {
    color: rgba(0, 194, 255, 1);

}

.panel {
    width: 100vw;
    height: 100vh;
    position: sticky;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.red {
    background: red;
}

.orange {
    background: orange;
}

.purple {
    background: purple;
}

.INCPlacement_background_vector {
    position: absolute;
    bottom: 0;
    left: 60vw;
}


.INCPlacementPage::-webkit-scrollbar {
    width: 0;
    /* width of the scrollbar */
    position: absolute;
    left: 0;
}

/* Track */
.INCPlacementPage::-webkit-scrollbar-track {
    background: transparent;
    /* color of the track */
}

/* Handle */
.INCPlacementPage::-webkit-scrollbar-thumb {
    background: #007aff;
    border-radius: 15px;
    /* color of the scrollbar handle */
}

/* Handle on hover */
.INCPlacementPage::-webkit-scrollbar-thumb:hover {
    background: #f77802;
    /* color of the scrollbar handle when hovered */
}
.INCPBLearning {
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
}

.INCPBLearning_container {
    margin-top: 60px;
    height: calc(100% - 60px);
    width: 100%;
}

.INCPBLearning_container {
    margin-top: 60px;
    height: calc(100% - 60px);
    width: 100%;
    display: flex;
    flex-direction: row;
}

.INCPBLearning_info {
    width: 40%;
    height: 100%;
}

.INCPBLearning_assets {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
}

.INCPBLearning_info {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.INCPBLearning_mobiles_container {
    width: 1000px;
    height: 590px;
    margin-top: 75px;
}

.INCProjectbased_learning_heading {
    font-family: Poppins;
    font-size: 50px;
    font-weight: 600;
    line-height: 70px;
    text-align: left;
    color: var(--primary-text-color);
    margin-bottom: 30px;
}

.INCProjectbased_learning_heading span {
    color: rgba(0, 194, 255, 1);

}

.INCProjectBased_learning_info_container {
    width: 515px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20%;
}

.INCProjectbased_learning_moto {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 400;
    line-height: 37.8px;
    text-align: left;
    color: var(--primary-text-color);
}

.INCProjectbased_learning_btn .INCProgram_btn {
    align-items: center;
    margin-top: 60px;
}

.INCProjectbased_learning_btn .INCBackground_hover {
    height: 68%;
    width: 20%;
    background: aliceblue;
    position: absolute;
    border-radius: 29px;
    background: linear-gradient(180deg, #EE4661 41.09%, #92161A 145.93%);
    transition: 1000ms ease-in-out;
}

.INCProjectbased_learning_btn .INCProgram_btn_text_holder {
    width: 210px;
    height: 65px;
    margin-right: 30px;
}

.INCPBFlutter_logo_container {
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(17, 24, 34, 1);
    border-radius: 8px;
}

.INCProjectbased_learning_btn .INCProgram_btn_text {
    font-size: 26.18px;
    font-weight: 400;
}

img.INCPBLearning_flutter_logo {
    width: auto;
    height: 40px;
}

.INCPBLearning_mobiles {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: flex-end;
}

.INCPBLearning_app_info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.INCPBLearning_app_info_container {
    height: 85%;
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;
    transition: opacity 1s ease-in-out;
    gap: 20px;
}

.INCPBLearning_app_info_container.fade {
    opacity: 0
}

.INCPBLearning_app_time {
    font-family: Poppins;
    font-size: 16.76px;
    font-weight: 500;
    text-align: center;
    width: 100px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: 0.88px solid rgba(56, 54, 54, 1);
    color: rgba(204, 204, 204, 1);
}

.INCApp_images {
    height: 345px;
    width: fit-content;
}

.INCPBLearning_app_info_container.bottomup-app {
    margin-bottom: 40px;
}

.INCCross_mobile_holder {
    position: relative;
}

.INCCross_mobiles_holder {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.INCMobileFrame {
    position: relative;
    position: absolute;
    top: 60px;
    left: 31%;
}

.INCMobileFrame .INCPBLearning_app_info {
    margin-left: 10px;
}

.INCMobileFrame .INCPBLearning_app_info {
    margin-left: 100px;
    margin-bottom: 20px;
}

.INCCross_mobile_holder img {
    height: 440px;
    width: auto;
}

.INCPBLearning_app_name {
    color: rgba(204, 204, 204, 1);
    font-family: Poppins;
    font-size: 17.52px;
    font-weight: 400;
    line-height: 38.78px;
    text-align: center;
}

.INCCross_mobiles_holder {
    width: 125%;
}

.INCCross_mobile_holder {
    position: relative;
    height: 100%;
    width: 100%;
}


.INCMobileFrame {
    position: relative;
    position: absolute;
    top: 2%;
    left: 25%;
    transition: 1000ms ease;
}

.INCMobileFrame.first {
    transition: 1000ms ease;
    left: -5%;
}

.INCMobileFrame.second {
    transition: 1000ms ease;
    left: 10%;
}

.INCMobileFrame.third {
    transition: 1000ms ease;
    left: 25%;
}

.INCMobileFrame.fourth {
    transition: 1000ms ease;
    left: 40%;
}

.INCMobileFrame.fifth {
    left: 58%;

}


.transition-image {
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
}

.transition-image.fade-out {
    opacity: 0.1;
}

@media screen and (max-width:1600px) {
    .INCProjectBased_learning_info_container {
        width: 75%;
    }

    .INCPBLearning_info {
        width: 30%;
        justify-content: flex-end;
    }

    .INCProjectbased_learning_heading {
        font-size: 2.5em;
        line-height: 55px;
    }

    .INCProjectbased_learning_moto {
        font-size: 20px;
    }

    .INCProjectbased_learning_btn .INCProgram_btn_text {
        font-size: 22.18px;
    }

    .INCProjectbased_learning_btn .INCProgram_btn_text_holder {
        width: 188px;
    }

    .INCProjectbased_learning_btn .INCBackground_hover {
        width: 24%;
    }

    .INCPBLearning_assets {
        width: 60%;
        margin-left: 4%;
    }

    .INCPBLearning_mobiles_container {
        width: 100%;
    }

    .INCApp_images {
        height: 65%;
    }

    .INCCross_mobile_holder img {
        height: auto;
        width: 100%;
    }
}

@media screen and (max-width:1367px) {
    .INCPBLearning_mobiles_container {
        height: 85%;
        margin-top: 30px;
    }

    .INCPBLearning_app_info svg {
        height: 45px;
    }

    .INCPBLearning_app_name {
        font-size: 13.52px;
    }

    .INCPBLearning_app_time {
        font-size: 13.76px;
        font-weight: 500;
        width: 80px;
        height: 30px;
    }


    .INCCross_mobile_holder img {
        width: 77%;
    }

    .INCMobileFrame .INCPBLearning_app_info {
        margin-bottom: 10px;
    }

    .INCProjectBased_learning_info_container {
        width: 75%;
        margin-bottom: 15%;
    }

    .INCPBLearning_app_info_container {
        height: 80%;
        width: 250px;
        gap: 10px;
    }

    .INCProjectbased_learning_heading {
        font-size: 2.3em;
        line-height: 55px;
    }

    .INCProjectbased_learning_moto {
        font-size: 18px;
        line-height: 33.8px;
    }

    .INCProjectbased_learning_btn .INCProgram_btn {
        align-items: center;
        margin-top: 40px;
    }

    .INCPBLearning_app_info_container.bottomup-app {
        margin-bottom: 30px;
    }

    .INCMobileFrame {
        bottom: 20px;
        left: 31%;
        top: unset;
    }
}


@media screen and (max-width:1024px) {
    .INCPBLearning_container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .INCPBLearning_tab_design_holder_container {
        height: 100%;
        width: 100%;
    }

    .INCPBLearning_responsive .INCPBLearning_app_time {
        font-size: 23.76px;
        font-weight: 500;
        width: 145px;
        height: 55px;
        border-radius: 50px;
    }

    .INCPBLearning_responsive .INCPBLearning_app_name {
        font-size: 20.52px;
        line-height: 50px;
    }

    .INCPBLearning_info {
        width: 100%;
        justify-content: center;
        margin-bottom: 0px;
    }

    .INCPBLearning_app_name {
        font-size: 20.52px;
        line-height: 50px;
    }

    .INCPBLearning_responsive .INCPBLearning_app_info svg {
        height: unset;
    }

    .INCProjectBased_learning_info_container {
        margin-bottom: 0%;
    }

    .INCProjectbased_learning_heading {
        font-size: 2.3em;
    }

    .INCProjectbased_learning_moto {
        font-size: 23px;
        line-height: 33.8px;
        text-align: center;
    }

    .INCProjectBased_learning_info_container {
        width: 60%;
        margin-bottom: 0%;
        text-align: center;
        align-items: center;
    }

    .INCProjectbased_learning_btn .INCProgram_btn_text {
        font-size: 30.18px;
    }

    .INCProjectbased_learning_btn .INCBackground_hover {
        width: 20%;
    }

    .INCProjectbased_learning_btn .INCProgram_btn_text_holder {
        width: 226px;
    }

    .INCPBLearning_assets {
        height: 50%;
        width: 90%;
    }

    .INCPBLearning_info {
        height: 35%;
    }

    .INCPBLearning_tab_design_holder {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .INCPBLearning_assets {
        width: 90%;
        margin-left: 0%;
    }

    .INCPBLearning_tab_design_holder .swiper-slide {
        position: relative;
    }

    .INCPBLearning_tab_design_img_holder img {
        width: 85%;
        transition: 1500ms ease;
    }

    .INCPBLearning_tab_design_holder .swiper-slide-active img {
        width: 100%;
        transition: 1500ms ease;
    }

    .INCPBLearning_tab_design_holder .swiper-slide-prev img,
    .INCPBLearning_tab_design_holder .swiper-slide-next img {
        width: 92%;
        transition: 1500ms ease;
    }

    .INCPBLearning_tab_design_holder .swiper-slide-prev,
    .INCPBLearning_tab_design_holder .swiper-slide-next {
        z-index: 2;
    }

    .INCPBLearning_tab_design_holder .swiper-slide-active {
        z-index: 3;
    }

    .INCPBLearning_tab_design_mobile_frame_holder .mobile_frame {
        width: auto;
        height: 102%;
        margin-top: -3%;
    }

    .INCPBLearning_tab_design_mobile_frame_holder {
        position: absolute;
        width: auto;
        height: 100%;
        z-index: 2;
        top: 0%;
        /* filter: drop-shadow(2px 2px 3px black); */
    }

    .INCPBLearning_assets {
        height: unset;
    }

    .INCPBLearning_tab_design_holder .swiper-slide img {
        box-shadow: -15px 4px 30px 0px rgba(0, 0, 0, 0.25);
    }

    .INCPBLearning_responsive {
        margin-bottom: 20px;
    }

}

@media screen and (max-width:768px) {

    .INCProjectBased_learning_info_container {
        width: 52%;
    }

    .INCPBLearning_tab_design_mobile_frame_holder .mobile_frame {
        width: auto;
        height: 102%;
        margin-top: -3%;
    }

    .INCProjectbased_learning_heading {
        font-size: 2em;
        line-height: 40px;
        margin-bottom: 20px;
    }

    .INCProjectbased_learning_btn .INCProgram_btn {
        align-items: center;
        margin-top: 20px;
    }

    .INCProjectbased_learning_moto {
        font-size: 20px;

    }

    .INCProjectbased_learning_btn .INCProgram_btn_text {
        font-size: 27.18px;
    }
}



@media screen and ((max-width:620px)) {
    .INCProjectBased_learning_info_container {
        width: 95%;
    }

    .INCProjectbased_learning_heading {
        font-size: 1.7rem;
        line-height: 25px;
        margin-bottom: 20px;
    }

    .INCProjectbased_learning_moto {
        font-size: 15px;
        line-height: 27px;
    }

    .INCProjectbased_learning_btn .INCProgram_btn_text_holder {
        width: 140px;
    }

    .INCProjectbased_learning_btn .INCProgram_btn_text {
        font-size: 16.18px;
    }

    .INCProgram_btn_text svg {
        width: 35px;
    }

    .INCProjectbased_learning_btn .INCProgram_btn_text_holder {
        margin-right: 10px;
        height: 44px;
    }

    .INCPBFlutter_logo_container {
        height: 45px;
        width: 45px;
    }

    img.INCPBLearning_flutter_logo {
        width: auto;
        height: 26px;
    }

    .INCPBLearning_assets {
        height: 60%;
    }

    .INCPBLearning_assets .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden {
        height: 100%;
        width: 100%;
    }

    .INCPBLearning_tab_design_img_holder {
        height: 100%;
        width: 100%;
    }

    .INCPBLearning_tab_design_holder .swiper-slide-active img {
        width: unset;
        transition: 1500ms ease;
        height: 100%;
    }


    .INCPBLearning_tab_design_img_holder img {
        width: unset;
        height: 85%;
    }


    .INCPBLearning_tab_design_holder .swiper-slide-prev img,
    .INCPBLearning_tab_design_holder .swiper-slide-next img {
        width: unset;
        height: 87%;
        margin-top: 10%;
    }

    .INCPBLearning_container {
        height: 100%;
    }

    .INCPBLearning_tab_design_holder_container {
        height: 100%;
        width: 100%;
    }

    .INCPBLearning_tab_design_holder {
        width: 100%;
        height: 80%;
    }

    .INCPBLearning_app_time {
        font-size: 10.76px;
        width: 70px;
        height: 23px;
    }

    .INCPBLearning_app_name {
        font-size: 10.52px;
        line-height: 29.78px;
    }

    .INCPBLearning_info {
        height: 30%;
    }

    .INCPBLearning_app_info svg {
        height: unset;
    }

    .INCPBLearning_tab_design_holder {
        width: 100%;
        height: 70%;
        margin-top: 5%;
    }

    .INCPBLearning_assets {
        height: 70%;
        width: 100%;
    }

    .INCPBLearning {
        width: 100%;
        height: 90%;
    }

    .INCPBLearning_container {
        gap: 5%;
    }

    .INCPBLearning_tab_design_mobile_frame_holder .mobile_frame {
        width: auto;
        height: 102%;
        margin-top: -2%;
    }

    .INCPBLearning_tab_design_mobile_frame_holder {
        pointer-events: none;
    }
}
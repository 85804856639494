.INCEventGallary_images_img1_container {
    overflow: hidden;
    animation: flex-animation1 20000ms ease infinite;
    width: 680px;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    position: relative;
}

.INCEventGallary_images_img2_container {
    overflow: hidden;
    animation: flex-animation2 20000ms ease infinite;
    width: 680px;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    margin-top: 40px;
    position: relative;
}

.INCEventGallary_images_img3_container {
    overflow: hidden;
    animation: flex-animation3 20000ms ease infinite;
    width: 680px;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    position: relative;
}

.INCEventGallary_images_img4_container {
    overflow: hidden;
    animation: flex-animation4 20000ms ease infinite;
    width: 680px;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    margin-top: 40px;
    position: relative;
}

.INCEventGallary_images_img5_container {
    overflow: hidden;
    animation: flex-animation5 20000ms ease infinite;
    width: 680px;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    position: relative;
}

.INCEventGallary {
    width: 100%;
}

.INCEventGallary_container {
    width: 100%;
}

.INCEventGallary_images_container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 100px;
}

.INCEventGallary_images_faint_background1 {
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 1;
    width: 100%;
    height: 100%;
    background: #00000099;
    animation: faint-animation1 20000ms ease infinite;

}

.INCEventGallary_images_faint_background2 {
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 1;
    width: 100%;
    height: 100%;
    background: #00000099;
    animation: faint-animation2 20000ms ease infinite;

}

.INCEventGallary_images_faint_background3 {
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 1;
    width: 100%;
    height: 100%;
    background: #00000099;
    animation: faint-animation3 20000ms ease infinite;

}

.INCEventGallary_images_faint_background4 {
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 1;
    width: 100%;
    height: 100%;
    background: #00000099;
    animation: faint-animation4 20000ms ease infinite;

}

.INCEventGallary_images_faint_background5 {
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 1;
    width: 100%;
    height: 100%;
    background: #00000099;
    animation: faint-animation5 20000ms ease infinite;

}

@media screen and (min-width:1025px) {


    @keyframes flex-animation1 {
        0% {
            width: 120px;

        }

        5% {
            width: 680px;
        }


        20% {
            width: 680px;
        }

        25% {
            width: 120px;
        }

        100% {
            width: 120px;
        }

    }

    @keyframes faint-animation1 {
        0% {
            opacity: 1;
            width: 120px;
        }

        5% {
            opacity: 0;
            width: 680px;
        }


        20% {
            opacity: 0;
            width: 680px;
        }

        25% {
            opacity: 1;
            width: 120px;
        }

        100% {
            opacity: 1;
            width: 120px;
        }

    }

    @keyframes flex-animation2 {
        0% {
            width: 120px;

        }

        20% {
            width: 120px;
        }

        25% {
            width: 680px;
        }

        35% {
            width: 680px;
        }

        40% {
            width: 120px;
        }

        100% {
            width: 120px;
        }

    }

    @keyframes faint-animation2 {
        0% {
            opacity: 1;
            width: 120px;
        }

        20% {
            opacity: 1;
            width: 120px;
        }

        25% {
            opacity: 0;
            width: 680px;
        }

        35% {
            opacity: 0;
            width: 680px;
        }

        40% {
            opacity: 1;
            width: 120px;
        }

        100% {
            opacity: 1;
            width: 120px;
        }

    }

    @keyframes flex-animation3 {
        0% {
            width: 120px;

        }

        15% {
            width: 120px;
        }

        20% {
            width: 120px;
        }

        35% {
            width: 120px;
        }

        40% {
            width: 680px;
        }

        50% {
            width: 680px;
        }

        55% {
            width: 120px;
        }

        100% {
            width: 120px;
        }

    }

    @keyframes faint-animation3 {
        0% {
            opacity: 1;
            width: 120px;

        }

        15% {
            opacity: 1;
            width: 120px;
        }

        20% {
            opacity: 1;
            width: 120px;
        }

        35% {
            opacity: 1;
            width: 120px;
        }

        40% {
            opacity: 0;
            width: 680px;
        }

        50% {
            opacity: 0;
            width: 680px;
        }

        55% {
            opacity: 1;
            width: 120px;
        }

        100% {
            opacity: 1;
            width: 120px;
        }

    }


    @keyframes flex-animation4 {
        0% {
            width: 120px;

        }

        15% {
            width: 120px;
        }

        20% {
            width: 120px;
        }

        30% {
            width: 120px;
        }

        35% {
            width: 120px;
        }

        50% {
            width: 120px;
        }

        55% {
            width: 680px;
        }

        65% {
            width: 680px;
        }

        70% {
            width: 120px;
        }

        100% {
            width: 120px;
        }

    }

    @keyframes faint-animation4 {
        0% {
            opacity: 1;
            width: 120px;
        }

        15% {
            opacity: 1;
            width: 120px;
        }

        20% {
            opacity: 1;
            width: 120px;
        }

        30% {
            opacity: 1;
            width: 120px;
        }

        35% {
            opacity: 1;
            width: 120px;
        }

        50% {
            opacity: 1;
            width: 120px;
        }

        55% {
            opacity: 0;
            width: 680px;
        }

        65% {
            opacity: 0;
            width: 680px;
        }

        70% {
            opacity: 1;
            width: 120px;
        }

        100% {
            opacity: 1;
            width: 120px;
        }

    }

    @keyframes faint-animation5 {
        0% {
            opacity: 1;
            width: 120px;
            padding-right: 500px;

        }

        15% {
            opacity: 1;
        }

        20% {
            opacity: 1;
        }

        30% {
            opacity: 1;
        }

        35% {
            opacity: 1;
        }

        45% {
            opacity: 1;
        }

        50% {
            opacity: 1;
        }

        65% {
            opacity: 1;
            width: 120px;
        }

        70% {
            opacity: 0;
            width: 680px;
        }

        95% {
            opacity: 0;
            width: 680px;
        }

        /* 85% {
        width: 120px;
    } */

        100% {
            opacity: 1;
            width: 120px;
        }

    }

    @keyframes flex-animation5 {
        0% {
            width: 120px;

        }

        15% {
            width: 120px;
        }

        20% {
            width: 120px;
        }

        30% {
            width: 120px;
        }

        35% {
            width: 120px;
        }

        45% {
            width: 120px;
        }

        50% {
            width: 120px;
        }

        65% {
            width: 120px;
        }

        70% {
            width: 680px;
        }

        95% {
            width: 680px;
        }

        /* 85% {
        width: 120px;
    } */

        100% {
            width: 120px;
        }

    }
}

@media screen and (max-width:1024px) {
    .INCEventGallary_images_container {
        display: flex;
        flex-direction: column;
    }


    .INCEventGallary_images_img1_container,
    .INCEventGallary_images_img3_container,
    .INCEventGallary_images_img5_container {
        margin-top: 0px;
        margin-right: 40px;
    }

    .INCEventGallary_images_img1_container {
        overflow: hidden;
        animation: flex-animation1 20000ms ease infinite;
        width: 680px;
        height: 431px;
    }

    .INCEventGallary_images_img2_container,
    .INCEventGallary_images_img4_container {
        margin-top: 0px;
        margin-left: 40px;
        display: flex;
        align-items: center;
    }

    img.INCEventGallary_images_img {
        height: 431px;
        width: 680px;
    }

    @keyframes flex-animation1 {
        0% {
            height: 200px;

        }

        5% {
            height: 431px;
        }


        20% {
            height: 431px;
        }

        25% {
            height: 200px;
        }

        100% {
            height: 200px;
        }

    }

    @keyframes faint-animation1 {
        0% {
            opacity: 1;
            height: 200px;
        }

        5% {
            opacity: 0;
            height: 431px;
        }


        20% {
            opacity: 0;
            height: 431px;
        }

        25% {
            opacity: 1;
            height: 200px;
        }

        100% {
            opacity: 1;
            height: 200px;
        }

    }

    @keyframes flex-animation2 {
        0% {
            height: 200px;

        }

        20% {
            height: 200px;
        }

        25% {
            height: 431px;
        }

        35% {
            height: 431px;
        }

        40% {
            height: 200px;
        }

        100% {
            height: 200px;
        }

    }

    @keyframes faint-animation2 {
        0% {
            opacity: 1;
            height: 200px;
        }

        20% {
            opacity: 1;
            height: 200px;
        }

        25% {
            opacity: 0;
            height: 431px;
        }

        35% {
            opacity: 0;
            height: 431px;
        }

        40% {
            opacity: 1;
            height: 200px;
        }

        100% {
            opacity: 1;
            height: 200px;
        }

    }

    @keyframes flex-animation3 {
        0% {
            height: 200px;

        }

        15% {
            height: 200px;
        }

        20% {
            height: 200px;
        }

        35% {
            height: 200px;
        }

        40% {
            height: 431px;
        }

        50% {
            height: 431px;
        }

        55% {
            height: 200px;
        }

        100% {
            height: 200px;
        }

    }

    @keyframes faint-animation3 {
        0% {
            opacity: 1;
            height: 200px;

        }

        15% {
            opacity: 1;
            height: 200px;
        }

        20% {
            opacity: 1;
            height: 200px;
        }

        35% {
            opacity: 1;
            height: 200px;
        }

        40% {
            opacity: 0;
            height: 431px;
        }

        50% {
            opacity: 0;
            height: 431px;
        }

        55% {
            opacity: 1;
            height: 200px;
        }

        100% {
            opacity: 1;
            height: 200px;
        }

    }


    @keyframes flex-animation4 {
        0% {
            height: 200px;

        }

        15% {
            height: 200px;
        }

        20% {
            height: 200px;
        }

        30% {
            height: 200px;
        }

        35% {
            height: 200px;
        }

        50% {
            height: 200px;
        }

        55% {
            height: 431px;
        }

        65% {
            height: 431px;
        }

        70% {
            height: 200px;
        }

        100% {
            height: 200px;
        }

    }

    @keyframes faint-animation4 {
        0% {
            opacity: 1;
            height: 200px;
        }

        15% {
            opacity: 1;
            height: 200px;
        }

        20% {
            opacity: 1;
            height: 200px;
        }

        30% {
            opacity: 1;
            height: 200px;
        }

        35% {
            opacity: 1;
            height: 200px;
        }

        50% {
            opacity: 1;
            height: 200px;
        }

        55% {
            opacity: 0;
            height: 431px;
        }

        65% {
            opacity: 0;
            height: 431px;
        }

        70% {
            opacity: 1;
            height: 200px;
        }

        100% {
            opacity: 1;
            height: 200px;
        }

    }

    @keyframes faint-animation5 {
        0% {
            opacity: 1;
            height: 200px;
            padding-right: 500px;

        }

        15% {
            opacity: 1;
        }

        20% {
            opacity: 1;
        }

        30% {
            opacity: 1;
        }

        35% {
            opacity: 1;
        }

        45% {
            opacity: 1;
        }

        50% {
            opacity: 1;
        }

        65% {
            opacity: 1;
            height: 200px;
        }

        70% {
            opacity: 0;
            height: 431px;
        }

        95% {
            opacity: 0;
            height: 431px;
        }

        /* 85% {
        height:200px;
    } */

        100% {
            opacity: 1;
            height: 200px;
        }

    }

    @keyframes flex-animation5 {
        0% {
            height: 200px;

        }

        15% {
            height: 200px;
        }

        20% {
            height: 200px;
        }

        30% {
            height: 200px;
        }

        35% {
            height: 200px;
        }

        45% {
            height: 200px;
        }

        50% {
            height: 200px;
        }

        65% {
            height: 200px;
        }

        70% {
            height: 431px;
        }

        95% {
            height: 431px;
        }

        /* 85% {
        height:200px;
    } */

        100% {
            height: 200px;
        }

    }
}

@media screen and (max-width:620px) {
    .INCEventGallary_images_container {
        display: flex;
        flex-direction: column;
        margin-top: 60px;
    }

    .INCEventGallary_images_img1_container,
    .INCEventGallary_images_img3_container,
    .INCEventGallary_images_img5_container {
        margin-top: 0px;
        margin-right: 12px;
        border-radius: 15px;
    }

    .INCEventGallary_images_img1_container {
        overflow: hidden;
        animation: flex-animation1 20000ms ease infinite;
        height: 190px;
        width: 305px;
    }

    .INCEventGallary_images_img2_container,
    .INCEventGallary_images_img4_container {
        margin-top: 0px;
        margin-left: 12px;
        display: flex;
        align-items: center;
        border-radius: 15px;
        width: 305px;
    }

    .INCEventGallary_images_img3_container,
    .INCEventGallary_images_img5_container {
        width: 305px;
    }

    img.INCEventGallary_images_img {
        height: 190px;
        width: 305px;
    }

    @keyframes flex-animation1 {
        0% {
            height: 90px;

        }

        5% {
            height: 190px;
        }


        20% {
            height: 190px;
        }

        25% {
            height: 90px;
        }

        100% {
            height: 90px;
        }

    }

    @keyframes faint-animation1 {
        0% {
            opacity: 1;
            height: 90px;
        }

        5% {
            opacity: 0;
            height: 190px;
        }


        20% {
            opacity: 0;
            height: 190px;
        }

        25% {
            opacity: 1;
            height: 90px;
        }

        100% {
            opacity: 1;
            height: 90px;
        }

    }

    @keyframes flex-animation2 {
        0% {
            height: 90px;

        }

        20% {
            height: 90px;
        }

        25% {
            height: 190px;
        }

        35% {
            height: 190px;
        }

        40% {
            height: 90px;
        }

        100% {
            height: 90px;
        }

    }

    @keyframes faint-animation2 {
        0% {
            opacity: 1;
            height: 90px;
        }

        20% {
            opacity: 1;
            height: 90px;
        }

        25% {
            opacity: 0;
            height: 190px;
        }

        35% {
            opacity: 0;
            height: 190px;
        }

        40% {
            opacity: 1;
            height: 90px;
        }

        100% {
            opacity: 1;
            height: 90px;
        }

    }

    @keyframes flex-animation3 {
        0% {
            height: 90px;

        }

        15% {
            height: 90px;
        }

        20% {
            height: 90px;
        }

        35% {
            height: 90px;
        }

        40% {
            height: 190px;
        }

        50% {
            height: 190px;
        }

        55% {
            height: 90px;
        }

        100% {
            height: 90px;
        }

    }

    @keyframes faint-animation3 {
        0% {
            opacity: 1;
            height: 90px;

        }

        15% {
            opacity: 1;
            height: 90px;
        }

        20% {
            opacity: 1;
            height: 90px;
        }

        35% {
            opacity: 1;
            height: 90px;
        }

        40% {
            opacity: 0;
            height: 190px;
        }

        50% {
            opacity: 0;
            height: 190px;
        }

        55% {
            opacity: 1;
            height: 90px;
        }

        100% {
            opacity: 1;
            height: 90px;
        }

    }


    @keyframes flex-animation4 {
        0% {
            height: 90px;

        }

        15% {
            height: 90px;
        }

        20% {
            height: 90px;
        }

        30% {
            height: 90px;
        }

        35% {
            height: 90px;
        }

        50% {
            height: 90px;
        }

        55% {
            height: 190px;
        }

        65% {
            height: 190px;
        }

        70% {
            height: 90px;
        }

        100% {
            height: 90px;
        }

    }

    @keyframes faint-animation4 {
        0% {
            opacity: 1;
            height: 90px;
        }

        15% {
            opacity: 1;
            height: 90px;
        }

        20% {
            opacity: 1;
            height: 90px;
        }

        30% {
            opacity: 1;
            height: 90px;
        }

        35% {
            opacity: 1;
            height: 90px;
        }

        50% {
            opacity: 1;
            height: 90px;
        }

        55% {
            opacity: 0;
            height: 190px;
        }

        65% {
            opacity: 0;
            height: 190px;
        }

        70% {
            opacity: 1;
            height: 90px;
        }

        100% {
            opacity: 1;
            height: 90px;
        }

    }

    @keyframes faint-animation5 {
        0% {
            opacity: 1;
            height: 90px;
            padding-right: 500px;

        }

        15% {
            opacity: 1;
        }

        20% {
            opacity: 1;
        }

        30% {
            opacity: 1;
        }

        35% {
            opacity: 1;
        }

        45% {
            opacity: 1;
        }

        50% {
            opacity: 1;
        }

        65% {
            opacity: 1;
            height: 90px;
        }

        70% {
            opacity: 0;
            height: 190px;
        }

        95% {
            opacity: 0;
            height: 190px;
        }

        /* 85% {
        height:200px;
    } */

        100% {
            opacity: 1;
            height: 90px;
        }

    }

    @keyframes flex-animation5 {
        0% {
            height: 90px;

        }

        15% {
            height: 90px;
        }

        20% {
            height: 90px;
        }

        30% {
            height: 90px;
        }

        35% {
            height: 90px;
        }

        45% {
            height: 90px;
        }

        50% {
            height: 90px;
        }

        65% {
            height: 90px;
        }

        70% {
            height: 190px;
        }

        95% {
            height: 190px;
        }

        /* 85% {
        height:200px;
    } */

        100% {
            height: 90px;
        }

    }
}
html,
body {
  height: 100%;
  width: 100%;
  font-family: "Poppins", sans-serif;
  background-color: var(--primary-color);
  overflow-x: hidden;
}

#root {
  height: 100%;
}

.App {
  text-align: center;
  height: 100%;
  width: 100%;
  background-color: var(--primary-color);
  position: relative;
}

br {
  height: 0px;
  display: block;
}

/* img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: unset;
  vertical-align: unset;
}

img,
video {
  max-width: unset;
  height: unset;
} */

/* Target WebKit-based browsers */
::-webkit-scrollbar {
  width: 10px;
  /* width of the scrollbar */
  position: absolute;
  left: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  /* color of the track */
}

.no-scroll {
  overflow: hidden !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #007aff;
  border-radius: 15px;
  /* color of the scrollbar handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f77802;
  /* color of the scrollbar handle when hovered */
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: light-dark(rgb(232 240 254 / 0%), rgb(70 90 126 / 0%)) !important;
  color: fieldtext !important;
}

@media screen and ((max-width:620px)) {
  ::-webkit-scrollbar {
    width: 5px;
    /* width of the scrollbar */
    position: absolute;
    left: 0;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    /* color of the track */
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #007aff;
    border-radius: 15px;
    /* color of the scrollbar handle */
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #f77802;
    /* color of the scrollbar handle when hovered */
  }
}
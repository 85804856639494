.INCAppDownload {
    height: 80%;
    width: 100%;
    position: relative;
    z-index: 1;
    background-color: var(--primary-color);
}

.INCAppDownload_container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
}

.INCAppdownload_footer {
    height: 60%;
    width: 100%;
    background: rgba(3, 15, 30, 1);
    display: flex;
    align-items: flex-end;
}

.INCAppdownload_footer_container {
    height: 175px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 2%;
}

.INCAppdownload_footer_uppar_part {
    height: 50%;
    border-bottom: 2px solid rgba(234, 238, 243, 0.2);
    display: flex;
    justify-content: space-between;
}

.INCAppdownload_footer_lower_part {
    display: flex;
    align-items: flex-end;
    height: 40%;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.INCAppdownload_footer_lower_part_content {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 400;
    line-height: 25px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
}

.INCAppdownload_footer_uppar_content {
    width: 60%;
    display: flex;
    flex-direction: row;
}

.INCAppdownload_footer_uppar_content_text {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
    margin-right: 50px;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
}

.INCAppdownload_footer_uppar_content2 svg {
    height: 50px;
    width: auto;
    margin-right: 25px;
}

.INCAPPDownload_pannel {
    height: 50%;
    width: 85%;
    background: url("/public/assets/images/appDownload/appDownload_background.png");
    background-repeat: no-repeat;
    position: absolute;
    top: 10%;
    border-radius: 30px;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.INCAppDownload_heading {
    font-family: Poppins;
    font-size: 50px;
    font-weight: 600;
    line-height: 110px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    margin-top: 5px;
}

.INCAppDownload_heading span {
    color: rgba(0, 194, 255, 1);
}

.INCAppDownload_desctiption {
    font-family: Poppins;
    font-size: 23px;
    font-weight: 400;
    line-height: 50px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
}

.INCAppDownload_desctiption span {
    color: rgba(255, 255, 255, 1);
}

.INCAppDownload_buttons_container {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    margin-top: 30px;
}

.INCAppDownload_google {
    width: 300px;
    height: 75px;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.INCAppDownload_google svg {
    height: 36px;
    width: auto;
    margin: 0px 30px;
}

.INC_platformName {
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
}

.INC_getItOn {
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    margin-bottom: 5px;
}

.INCAppdownload_footer_uppar_content2 svg:hover .path-icon {
    fill: 'red ' !important;

}

@media screen and ((max-width: 1537px)) {
    .INCAppDownload {
        height: 100%;
    }
}

@media screen and ((max-width: 1440px)) {
    .INCAppDownload {
        height: 100%;
    }

    .INCAppdownload_footer_uppar_content_text {
        font-size: 20px;
    }

    .INCAppdownload_footer_uppar_content2 svg {
        height: 45px;
    }

    .INCAppdownload_footer_lower_part_content {
        font-size: 20px;
    }

    .INCAppDownload_desctiption {
        font-size: 20px;
    }

    .INCAppDownload_heading {
        font-size: 2.5rem;
        line-height: 85px;

    }

    .INCAppDownload_buttons_container {
        margin-top: 5px;
    }

    .INC_getItOn {
        font-size: 15px;
        margin-bottom: 0px;
    }

    .INCAppDownload_google {
        width: 230px;
        height: 60px;
    }

    .INCAppDownload_google svg {
        height: 36px;
        margin: 0px 23px;
    }
}

@media screen and ((max-width: 1024px)) {
    .INCAppDownload {
        height: 130%;
    }

    .INCAppdownload_footer {
        position: relative;
        height: 50%;
    }

    .INCAppdownload_footer_container {
        height: 80%;
    }

    .INCAppdownload_footer_lower_part {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 2vh;
        gap: 12%;
        margin-top: 20px;
        flex-direction: column;
    }

    .INCAppdownload_footer_lower_part_content {
        font-size: 25px;
        font-weight: 400;
        line-height: 45px;
    }

    .INCAppdownload_footer_uppar_part {
        justify-content: center;
        flex-wrap: wrap;
    }

    .INCAppdownload_footer_uppar_content2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 5vw;
    }

    .INCAppdownload_footer_uppar_content2 svg {
        margin-right: 0px;
        height: 60px;
    }

    .INCAppdownload_footer_uppar_content_text {
        font-size: 27px;
        margin-right: 25px;
        margin-left: 25px;
    }

    .INCAppdownload_footer_uppar_content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
    }



    /*app download*/

    .INCAppDownload_google svg {
        height: 45px;
        width: auto;
        margin: 0px 25px;
    }

    .INC_getItOn {
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 5px;
    }

    .INC_platformName {
        font-size: 22px;
        line-height: 27px;
    }

    .INCAppDownload_google {
        width: 255px;
        height: 75px;
        background: rgba(255, 255, 255, 1);
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .INCAPPDownload_pannel {
        height: 42%;
        width: 90%;
        top: 15%;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 3%;
        border-radius: 50px;
    }

    .INCAppDownload_heading {
        font-size: 2.5rem;
        line-height: 76px;
        margin-top: 0px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .INCAppDownload_desctiption {
        font-size: 22px;
        font-weight: 400;
        line-height: 25px;
        margin-left: 11%;
        margin-right: 11%;
        line-height: 50px;
    }

    .INCAppDownload_buttons_container {
        height: 125px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-top: 30px;
        flex-direction: row;
    }

    .INC_app_content {
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }

}

@media screen and ((max-width: 620px)) {
    .INCAppDownload {
        height: 130%;
    }

    .INCAppdownload_footer {
        position: relative;
        height: 50%;
    }

    .INCAppdownload_footer_container {
        height: 80%;
    }

    .INCAppdownload_footer_lower_part {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2vh;
        gap: 15px;
        /* margin-top: 20px; */
        flex-direction: column;
    }

    .INCAppdownload_footer_lower_part_content {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        text-align: center;
    }

    .INCAppdownload_footer_uppar_part {
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        padding-bottom: 3%;
    }

    .INCAppdownload_footer_uppar_content2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 7vw;
    }

    .INCAppdownload_footer_uppar_content2 svg {
        margin-right: 0px;
        height: 35px;
    }

    .INCAppdownload_footer_uppar_content {
        width: 100%;
    }

    .INCAppdownload_footer_uppar_content_text {
        font-size: 16px;
        margin-right: 10px;
        margin-left: 10px;
    }

    .INCAppdownload_footer_uppar_content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }



    /*app download*/

    .INCAppDownload_google svg {
        height: 30px;
        width: auto;
        margin: 0px 25px;
    }

    .INC_getItOn {
        font-family: Open Sans;
        font-size: 7px;
        font-weight: 600;
        line-height: 10px;
        text-align: left;
        margin-bottom: 5px;
    }

    .INC_platformName {
        font-family: Open Sans;
        font-size: 4px;
        font-weight: 700;
        line-height: 2px;
        text-align: left;
    }

    .INCAppDownload_google {
        width: 190px;
        height: 55px;
        background: rgba(255, 255, 255, 1);
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .INCAPPDownload_pannel {
        height: 55%;
        width: 90%;
        top: 2%;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 3%;
        border-radius: 25px;
    }

    .INCAppDownload_heading {
        font-size: 1.5rem;
        line-height: 55px;
        margin-top: 0px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .INCAppDownload_desctiption {
        font-size: 12px;
        font-weight: 400;
        line-height: 25px;
        margin-left: 23px;
        margin-right: 23px;
        line-height: 25px;
    }

    .INCAppDownload_buttons_container {
        height: 125px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-top: 30px;
        flex-direction: column;
    }

    .INC_app_content {
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }

    .INC_getItOn {
        font-size: 10px;
        line-height: 14px;
        margin-bottom: 5px;
    }

    .INC_platformName {
        font-size: 15px;
        line-height: 18px;
    }

}
.INCLanding_page {
    height: 130%;
    width: 100%;
    background: url("/public/assets/images/landingPage/landingBackground.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-color: #010913;
    position: relative;
    overflow: hidden;
}

.INCLanding_page_gradient {
    height: 75%;
    width: 100%;
    background: linear-gradient(225deg, rgba(2, 10, 20, 0) 0%, rgba(2, 10, 20, 0.81) 52.25%, #020A14 100%);
}

.INCLanding_container {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.INCLanding_bottomgredient_container {
    height: 35%;
    width: 100%;
    background: linear-gradient(180deg, rgba(2, 10, 20, 0) 0%, rgba(2, 10, 20, 0.81) 36.25%, #020A14 100%);
}

.INCLanding_content_container {
    width: 100%;
    height: 60%;
    display: flex;
}

.INCLanding_text_Container {
    height: 100%;
    width: 56%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    animation: landTextContent 2s 1;
    position: relative;
}

/* @keyframes landTextContent {
    0% {
        left: -60vw;
    }


    87% {
        left: 10px;
    }

    100% {
        left: 0px;
    }
} */
@keyframes landTextContent {
    0% {
        left: -60vw;
    }

    40% {
        left: 1vw;
    }

    70% {
        left: -5px;
    }

    100% {
        left: 0px;
    }
}


.INCLanding_kidImage_Container {
    height: 100%;
    width: 44%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    position: relative;
    animation: landKidContent 2s 1;
}

/* @keyframes landKidContent {
    0% {
        left: 60vw;
    }


    87% {
        left: -10px;
    }

    90% {
        left: 0px;

    }

    100% {
        left: -10px;
    }
} */

@keyframes landKidContent {
    0% {
        left: 60vw;
    }

    40% {
        left: -1vw;
    }

    70% {
        left: 5px;
    }

    100% {
        left: 0px;
    }
}

/* .INCLanding_text {
    width: 712.35px;
    height: 286px;
    opacity: 0px;
    color: rgba(186, 186, 186, 1);
    margin-bottom: 75px;
    background: red;
} */

p.INCLanding_tagline {
    font-family: DM Sans;
    font-size: 35px;
    font-weight: 400;
    line-height: 50.75px;
    letter-spacing: 0.06em;
    text-align: center;
    margin: 0px;
    margin-bottom: 25px;
    position: relative;
    color: #BABABA;
}

p.INCLanding_tagline span {
    font-family: DM Sans;
    font-size: 35px;
    font-weight: 400;
    line-height: 50.75px;
    letter-spacing: 0.06em;
    text-align: center;
    color: rgba(255, 255, 255, 1);
}

.INCLanding_offer {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 500;
    line-height: 60px;
    letter-spacing: 0.02em;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 15px;
}

.INCLanding_moto {
    font-family: DM Serif Display;
    font-size: 54px;
    font-weight: 400;
    line-height: 81px;
    letter-spacing: 0.02em;
    text-align: left;
    color: rgba(0, 194, 255, 1);
    text-align: left;
    margin-left: 90px;
    margin-bottom: 12px;
}

.INCLanding_tagline_paraEnd {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 500;
    line-height: 60px;
    letter-spacing: 0.02em;
    text-align: center;
    color: rgba(255, 255, 255, 1);
}

.INCLanding_tagline_paraEnd span {
    color: rgba(0, 131, 204, 1);
    font-family: Poppins;
    font-size: 40px;
    font-weight: 500;
    line-height: 60px;
    letter-spacing: 0.02em;
    text-align: center;
}

.INCLandingswiper_holder {
    height: 100%;
    width: 100%;
}

.INCLandingswiper_holder .swiper {

    height: 96%;
    overflow: visible;
    /* overflow-x: hidden; */
}

svg.INCLandingPage_text_bigStar {
    position: absolute;
    top: -16px;
    right: 10px;
}

svg.INCLandingPage_text_smallStar {
    position: absolute;
    left: 99px;
    bottom: -22px;
}

.INCLanding_kidImage_outerLayer {
    width: 414px;
    height: 421.59px;
    display: block;
    position: relative;
    margin-left: 36px;
}



img.INCLanding_kidImage_outerLayer_containerImage {
    margin-top: 40px;
    margin-left: 32px;
}

.INCLanding_kidImage_overlayer {
    width: 362.16px;
    height: 362.16px;
    left: 42.19px;
    gap: 1px;
    border-radius: 181.08px 0.08px 31.04px 181.08px;
    /* opacity: 0px; */
    position: absolute;
    bottom: 10px;
    overflow: hidden;
}

img.INCLanding_boy_image {
    position: absolute;
    right: -10px;
    top: -44px;
    width: 413.8px;
    height: 406px;

}

img.INCLanding_boy_image {

    position: absolute;
    right: -10px;
    top: -44px;
    transition: 1000ms;
    width: 413.8px;
    height: auto;

}

img.INCLanding_boy_image_head {
    position: absolute;
    right: -1px;
    top: 5px;
    transition: 1000ms;
    width: 413.8px;
    height: auto;
}

.INCLanding_kidImage_outerLayer:hover img.INCLanding_boy_image {
    position: absolute;
    right: -10px;
    top: -50px;
    width: 425.8px;
    height: auto;
    transition: 1000ms ease-in-out;
}

.INCLanding_kidImage_outerLayer:hover img.INCLanding_boy_image_head {
    position: absolute;
    right: -0.25px;
    top: 0px;
    width: 425.8px;
    height: auto;
    transition: 1000ms ease-in-out;
}

img.INCLanding_logoImages {
    position: absolute;
    top: 50px;
    left: -10px;
}

.INCPrograms_container {
    width: 100%;
    height: 40%;
    position: absolute;
    bottom: 0px;
    animation: landProgramsContent 2s 1;
}

@keyframes landProgramsContent {
    0% {
        bottom: -500px;
    }

    100% {
        bottom: 0px;
    }
}

.INCProgram_info_holder {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.INCProgram_info_card {
    background: url('/public/assets/images/landingPage/currentProgramBackground.png');
    height: 100%;
    width: 95%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    background-size: 100% 98%;
    position: relative;
}


.INCUpcommingProgram {
    background: url("/public/assets/images/landingPage/UpcomingProgramBackground.png");
    background-repeat: no-repeat;
    background-size: 100% 98%;
}

.INCUpcommingProgram .INCProgram_heading {

    margin-top: 52px;

}

.INCUpcommingProgram .INCProgram_moto {

    margin-top: 20px;
}

.INCUpcommingProgram .INCProgram_content {
    margin-top: 18px;

}

.INCUpcommingProgram .INCProgram_btn {
    margin-top: 18px;

}

.INCUpcommingProgram .INCProgram_btn_text_holder {
    width: 222px;
    height: 50px;
    margin-right: 44px;
}

.INCUpcommingProgram .INCProgram_content {
    height: 68px;

}

.INCUpcommingProgram .INCBackground_hover {
    width: 20%;
    background: rgba(255, 255, 255, 0.25);
}

.INCProgram_templete_part2 {
    height: 97%;
    width: 52%;
    display: flex;
    flex-direction: column;
    gap: 2%;
}

.INCProgram_templete_part {
    height: 85%;
    width: 48%;
    position: relative;
}

.INCUpcommingProgram .INCProgram_techLogo {
    margin-left: 0px;
}

img.INCProgram_templete_thumbnail_img {
    position: absolute;
    left: 65px;
    bottom: 0px;
}

.INCProgram_heading {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 30px;
    justify-content: flex-end;
}

.INCProgram_techLogo img {
    margin-right: 30px;
}

.INCProgram_techLogo {
    margin-left: 10px;
}


.INCProgram_techHead {
    color: rgba(255, 255, 255, 1);
    font-family: Poppins;
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 40px;
    text-align: left;
    margin-right: 65px;
}

.INCProgram_btn {
    text-align: right;
    color: #ffffff;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.INCProgram_moto {
    text-align: right;
    margin-right: 65px;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 400;
    line-height: 30.8px;
    letter-spacing: -0.0024em;
    text-align: right;
    color: rgba(255, 255, 255, 0.8);
    margin-top: 30px;
}

.INCProgram_btn_text_holder {
    width: 170px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 65px;
    position: relative;
}

.INCContent_count_holder {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.INCBackground_hover {
    height: 89%;
    width: 27%;
    background: aliceblue;
    position: absolute;
    border-radius: 29px;
    background: linear-gradient(180deg, #EE4661 41.09%, #92161A 145.93%);
    transition: 1000ms ease-in-out;
}

.INCProgram_btn_text_holder:hover .INCBackground_hover {
    transition: 1000ms ease-in-out;
    width: 92%;
}

.INCProgram_btn_text {
    font-family: Poppins;
    font-size: 18.18px;
    font-weight: 500;
    line-height: 32.73px;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 15px;
    height: 100%;
    width: 92%;
    justify-content: center;
    position: absolute;
    cursor: pointer;
}

.INCProgram_content {
    display: flex;
    color: rgba(255, 255, 255, 1);
    width: 635px;
    height: 68px;
    /* opacity: 0px; */
    margin-top: 30px;
    justify-content: flex-end;
    gap: 100px;
    margin-right: 70px;
}

.INCContent_count {
    margin-left: 17px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
    text-align: left;
}

.INCContent_count span {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 40px;
    text-align: left;

}

.vl {
    width: 3px;
    height: 90%;
    background: #ffffff;
    border-radius: 2px;
}



@media screen and ((min-width:1025px) and (max-width:1367px)) {
    .INCLanding_content_container {
        width: 100%;
        height: 65%;
        display: flex;
        margin-top: 5%;
    }

    p.INCLanding_tagline {
        font-size: 1.6rem;
        margin-bottom: 4px;

    }

    p.INCLanding_tagline span {
        font-size: 1.6rem;
    }

    .INCLanding_offer {
        font-size: 1.9rem;
        margin-bottom: 5px;
    }

    .INCLanding_moto {
        font-size: 2.5rem;
        line-height: 37px;
    }

    .INCLanding_tagline_paraEnd {
        font-size: 1.9rem;
    }

    .INCLanding_tagline_paraEnd span {
        font-size: 1.8rem;
    }

    .INCLanding_text {
        width: 589.35px;
        height: 235px;
        margin-bottom: 129px;
        margin-right: 64px;
    }

    .INCLanding_kidImage_overlayer {
        width: 273.16px;
        height: 272.16px;
        left: 45.19px;
        gap: 1px;
        border-radius: 181.08px 0.08px 31.04px 181.08px;
        /* opacity: 0px; */
        position: absolute;
        bottom: 27px;
        overflow: hidden;
    }

    img.INCLanding_kidImage_outerLayer_containerImage {
        margin-top: 40px;
        margin-left: 32px;
        height: 84%;
        width: auto;
    }

    img.INCLanding_boy_image {
        position: absolute;
        right: -7px;
        top: -34px;
        transition: 1000ms;
        width: 312.8px;
        height: auto;
    }

    .INCLanding_kidImage_outerLayer {
        width: 331px;
        height: 353.59px;
        display: block;
        position: relative;
        margin-left: 36px;
    }

    img.INCLanding_boy_image_head {
        position: absolute;
        right: 6px;
        top: 20px;
        transition: 1000ms;
        width: 314.8px;
        height: auto;
    }

    img.INCLanding_logoImages {
        position: absolute;
        top: 50px;
        left: 0px;
        width: 157px;
        height: auto;
    }

    .INCLanding_kidImage_outerLayer:hover img.INCLanding_boy_image {
        position: absolute;
        right: -16px;
        top: -41px;
        transition: 1000ms;
        width: 332.8px;
        height: auto;
    }

    .INCLanding_kidImage_outerLayer:hover img.INCLanding_boy_image_head {
        right: -3.25px;
        top: 12px;
    }

    img.INCProgram_templete_thumbnail_img {
        position: absolute;
        left: 65px;
        bottom: 0px;
        height: 140%;
        width: auto;
    }

    .INCProgram_heading {
        margin-top: 22px;
    }

    .INCProgram_techHead {
        font-size: 2rem;
    }

    .INCProgram_techLogo img {
        margin-right: 30px;
        height: 35px;
    }

    .INCProgram_moto {
        font-size: 19px;
        margin-top: 17px;
    }

    .INCProgram_content {
        display: flex;
        color: rgba(255, 255, 255, 1);
        width: 635px;
        height: 68px;
        /* opacity: 0px; */
        margin-top: 20px;
        justify-content: flex-end;
        gap: 100px;
        margin-right: 70px;
    }

    .INCContent_count {
        font-size: 25px;
    }

    .INCContent_count span {
        font-family: Poppins;
        font-size: 17px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
    }

    .INCProgram_content {
        display: flex;
        color: rgba(255, 255, 255, 1);
        width: 635px;
        height: 65px;
        /* opacity: 0px; */
        margin-top: 16px;
        justify-content: flex-end;
        gap: 85px;
        margin-right: 70px;
    }

    .INCProgram_btn {
        text-align: right;
        color: #ffffff;
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .INCBackground_hover {
        height: 73%;
        width: 22%;
    }

    .INCLanding_kidImage_outerLayer {
        margin-bottom: 85px;
    }

    .INCUpcommingProgram .INCProgram_heading {
        margin-top: 45px;
    }

    .INCUpcommingProgram .INCProgram_moto {
        margin-top: 12px;
    }

    .INCUpcommingProgram .INCProgram_content {
        margin-top: 0px;
    }

    .INCUpcommingProgram .INCBackground_hover {
        width: 17%;
        background: rgba(255, 255, 255, 0.25);
    }

    .INCLanding_page_gradient {
        height: 80%;
    }

}

@media screen and ((max-width:1281px)) {
    .INCProgram_techHead {
        font-size: 1.9rem;
    }

    .INCProgram_techLogo img {
        margin-right: 24px;
        height: 35px;
    }

}

/*responsive for normal laptops*/
@media screen and ((min-width:1367px) and (max-width: 1537px)) {
    .INCLanding_content_container {
        margin-top: 35px;
    }



    .INCProgram_heading {
        margin-top: 17px;
    }

    .INCUpcommingProgram .INCProgram_heading {
        margin-top: 42px;
    }

    .INCUpcommingProgram .INCProgram_moto {
        margin-top: 10px;
    }

    .INCLanding_page {
        height: 150%;
    }

    .INCLanding_page_gradient {
        height: 85%;

    }

    .INCLanding_text {
        margin-right: 60px;
        margin-bottom: 135px;
    }

    .INCLanding_kidImage_outerLayer {
        margin-bottom: 65px;
    }
}

@media screen and ((min-width:1910px)) {
    .INCProgram_heading {
        margin-top: 6%;
    }

    .INCUpcommingProgram .INCProgram_heading {
        margin-top: 8% !important;
    }

    .INCUpcommingProgram .INCProgram_moto {
        margin-top: 5px;
    }

    .INCUpcommingProgram .INCProgram_templete_part2 {
        gap: 4%;
    }

    .INCUpcommingProgram .INCProgram_btn {
        margin-top: 5%;
    }
}

@media screen and ((min-width:1537px)) {
    .INCLanding_text {
        margin-bottom: 85px;

    }

    .INCLanding_kidImage_outerLayer {

        margin-bottom: 15px;
    }

    .INCLanding_page_gradient {
        height: 80%;

    }

    img.INCProgram_templete_thumbnail_img {
        left: 7.5vw;
        bottom: 0px;
        height: 480px;
        width: auto;
    }

    .INCProgram_techHead {
        font-size: 3rem;
    }

    .INCProgram_techLogo img {
        margin-right: 30px;
        width: auto;
        height: 50px;
    }

    .INCLanding_text {
        width: 864.35px;
        height: 343px;
        margin-bottom: 25px;
        margin-right: 45px;
    }

    p.INCLanding_tagline {
        font-size: 2.8rem;
        margin-bottom: 35px;
    }

    .INCLanding_offer {
        font-size: 3rem;

        margin-bottom: 22px;

    }

    .INCLanding_moto {
        font-size: 4rem;
        margin-bottom: 25px;
    }

    .INCLanding_tagline_paraEnd {
        font-size: 3rem;
    }

    p.INCLanding_tagline span {
        font-size: 2.8rem;
    }

    img.INCLanding_kidImage_outerLayer_containerImage {
        margin-top: 40px;
        margin-left: 32px;
        height: auto;
        width: 415px;
    }

    .INCLanding_kidImage_overlayer {
        width: 385.16px;
        height: 385.16px;
        left: 46.19px;
        bottom: -20px;
        /* background: white; */
        border-radius: 309.08px 88.08px 51.04px 309.08px;
    }

    img.INCLanding_boy_image {
        position: absolute;
        right: -11px;
        top: -48px;
        transition: 1000ms;
        width: 441.8px;
        height: auto;
    }

    .INCProgram_btn_text_holder {
        width: 205px;
        height: 59px;
    }

    .INCProgram_btn_text {
        font-size: 23.18px;
    }

    .INCProgram_moto {
        font-size: 1.7rem;
        line-height: 43.8px;

    }

    .INCContent_count {
        font-size: 35px;
    }

    .INCProgram_content {
        display: flex;
        color: rgba(255, 255, 255, 1);
        width: 697px;
        height: 97px;
        /* opacity: 0px; */
        margin-top: 24px;
        justify-content: flex-end;
        gap: 100px;
        margin-right: 70px;
    }

    .INCContent_count span {
        font-size: 23px;

    }

    .INCUpcommingProgram .INCProgram_btn_text_holder {
        width: 290px;
        height: 67px;
    }

    .INCUpcommingProgram .INCProgram_templete_part2 {
        gap: 10px;
    }

    .INCLanding_kidImage_outerLayer {
        margin-left: 89px;
    }

    img.INCLanding_logoImages {
        position: absolute;
        top: 50px;
        left: -15px;
        width: 222px;
        height: auto;
    }

    img.INCLanding_boy_image_head {
        position: absolute;
        right: -28px;
        top: 8px;
    }

    .INCLanding_kidImage_outerLayer:hover img.INCLanding_boy_image {
        position: absolute;
        right: -16px;
        top: -56px;
        transition: 1000ms;
        width: 452.8px;
        height: auto;
    }

    .INCLanding_kidImage_outerLayer:hover img.INCLanding_boy_image_head {
        right: -33.25px;
        top: -1px;
    }

    .INCLanding_kidImage_Container {
        padding-left: 60px;
    }


}

@media screen and ((max-width: 836px)) {
    .INCLanding_text_Container {
        width: 70%;

    }

    .INCLanding_text {
        width: unset;

    }

    .INCLanding_moto {
        margin-left: 23px;
        width: 100%;
    }

    .INCLanding_content_container {
        flex-direction: column;
        margin-top: 100px;
        display: flex;
        align-items: center;
    }

    .INCLanding_page_gradient {
        background: linear-gradient(0deg, rgba(2, 10, 20, 0) 0%, rgba(2, 10, 20, 0.81) 77.25%, #020A14 100%);
    }

    .INCLanding_page {
        background-size: 243% auto;
        background-position: right;
        background-size: auto 100%;
    }

    .INCLanding_bottomgredient_container {
        height: 35%;
        width: 100%;
        background: linear-gradient(180deg, rgba(2, 10, 20, 0) 0%, rgba(2, 10, 20, 0.81) 29.25%, #020A14 100%);
    }

    .INCLanding_kidImage_Container {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        animation: landKidContent 2s 1;
        margin-top: 80px;
        margin-right: 70px;
    }
}

@media screen and ((max-width: 769px)) {

    .INCLanding_moto {
        margin-left: 12px;
        width: 100%;
    }

    .INCLanding_moto {
        font-size: 50px;

    }
}

@media screen and ((max-width: 1024px)) {
    .INCPrograms_container {
        width: 100%;
        height: 40%;
        position: absolute;
        bottom: 0px;
        animation: landProgramsContent 2s 1;
        background: #030916;
        background: linear-gradient(177deg, rgba(2, 10, 20, 0) 21%, rgba(2, 10, 20, 0.81) 16.25%, #020A14 34%);
    }

    .INCLanding_page_gradient {
        height: 75%;
        width: 100%;
        background: linear-gradient(225deg, rgba(2, 10, 20, 0) 0%, rgba(2, 10, 20, 0.81) 52.25%, #020A14 100%);
    }

    .INCLanding_text_Container {
        width: 70%;

    }

    .INCLanding_text {
        width: unset;
        margin-bottom: 38px;
    }

    .INCLanding_moto {
        margin-left: 23px;
        width: 100%;
    }

    .INCLanding_content_container {
        flex-direction: column;
        margin-top: 100px;
        display: flex;
        align-items: center;
    }

    .INCLanding_page_gradient {
        height: 65%;
        width: 100%;
        background: linear-gradient(0deg, rgba(2, 10, 20, 0) 0%, rgba(2, 10, 20, 0.81) 77.25%, #020A14 100%);
    }

    .INCLanding_content_container {
        width: 100%;
        height: 50%;
        display: flex;
    }

    .INCLanding_page {
        background-position: top right;
        background-size: auto 50%;
    }

    .INCLanding_bottomgredient_container {
        height: 56%;
        width: 100%;
        background: linear-gradient(180deg, rgba(2, 10, 20, 0) 0%, rgba(2, 10, 20, 0.81) 29.25%, #020A14 100%);
    }

    .INCLanding_kidImage_Container {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        animation: landKidContent 2s 1;
        margin-top: 80px;
        margin-right: 70px;
    }

    .INCLanding_kidImage_Container {
        margin-top: 27px;
    }

    .INCTab_program_info_container {
        height: 550px;
        width: 100%;
    }

    .INCPrograms_container {
        width: 100%;
        height: 59%;
        position: absolute;
        bottom: 0px;
        animation: landProgramsContent 2s 1;
        background: #030916;
        background: linear-gradient(rgba(2, 10, 20, 0) 13%, rgba(2, 10, 20, 0.81) 0.25%, #020A14 34%);
    }

    .INCTabprogram_info_background_holder {
        height: 89%;
        width: 97%;
        margin-left: 1.5%;
        background: url("/public/assets/images/landingPage/tab_current\ background.png");
        background-repeat: no-repeat;
        background-size: 100% auto;

    }

    .INCLanding_page {
        height: 185%;
        width: 100%;
    }

    .INCProgram_moto {
        text-align: left;

    }

    .INCProgram_heading {
        justify-content: flex-start;
        position: relative;
    }



    .INCTabprogram_info_content_holder {
        height: 84%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 40px;
        position: relative;
    }

    .INCProgram_btn_text_holder {
        width: 200px;
        margin-right: 15px;
    }

    .INCBackground_hover {
        height: 89%;
        width: 92%;
    }

    .INCProgram_btn_text {
        font-size: 28.18px;
        gap: 22px;
    }

    .INCProgram_techLogo {
        margin-left: 10px;
        position: absolute;
        right: 23px;
        top: -47px;
    }

    .INCProgram_techLogo img {
        margin-right: 51px;
        height: 50px;
    }

    .INCProgram_btn {
        margin-top: 0px;
    }

    .INCTabprogram_info_content_holder_upcomming .INCProgram_btn_text_holder {
        width: 290px;
        margin-right: 15px;
        margin-top: 20px;
        height: 60px;
    }

    .INCTabprogram_info_content_holder_upcomming .INCBackground_hover {
        background: rgba(255, 255, 255, 0.25);
    }

    .INCProgramtags {
        color: #ffffff;
        width: 177px;
        height: 54px;
        position: absolute;
        top: -19px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Poppins;
        font-size: 25.02px;
        font-weight: 500;
        line-height: 24.03px;
        letter-spacing: -0.0024em;
        text-align: left;
        box-shadow: 0px 8.01px 40.06px 0px rgba(238, 70, 97, 0.3);
        background: linear-gradient(180deg, #EE4661 41.09%, #92161A 145.93%);
    }

}

@media screen and ((max-width: 820px)) {
    .INCHomepage {
        height: 110%;
        width: 100%;
        overflow: unset;
        overflow-x: unset;
    }

    .INCProgram_heading {
        margin-top: 15px;
    }

    .INCProgram_techHead {
        font-size: 1.6rem;
    }

    .INCProgram_moto {
        font-size: 19px;
        line-height: 27.8px;
        margin-top: 15px;
    }

    .INCContent_count {
        font-size: 25px;
        line-height: 30px;
    }

    .INCContent_count span {
        font-size: 16px;
    }

    .INCProgram_content {
        justify-content: flex-start;
        gap: 10%;
    }

    .INCProgram_techLogo img {
        margin-right: 40px;
        height: 38px;
    }

    .INCTabprogram_info_content_holder {
        height: 90%;
    }

    .INCTab_program_info_container {
        height: 460px;
        width: 100%;
        margin-top: 10%;
    }

    .INCProgram_btn_text {
        font-size: 20.18px;
        gap: 22px;
    }

    .INCProgram_btn_text_holder {
        width: 170px;
    }

    .INCBackground_hover {
        width: 92%;
    }

    .INCProgramtags {
        width: 160px;
        height: 45px;
        top: -15px;
        font-size: 20.02px;
        line-height: 24.03px;
    }

    .INCTabprogram_info_content_holder_upcomming .INCProgram_btn_text_holder {
        width: 220px;
        margin-right: 15px;
        height: 50px;
    }
}

@media screen and ((max-width: 768px)) {
    .INCLanding_page {
        height: 215%;
        width: 100%;
    }

    .INCTabprogram_info_content_holder {
        height: 75%;
    }

    .INCTabprogram_info_content_holder {
        height: 85%;
    }

    .INCProgram_content {
        margin-top: 20px;
    }

    .INCProgram_content {
        width: 635px;
    }

    .INCProgram_btn_text_holder {
        width: 150px;
    }

    .INCProgram_btn_text_holder {
        height: 40px;
    }

    .INCProgram_btn_text {
        font-size: 18.18px;
        gap: 10px;
    }

    .INCTabprogram_info_content_holder_upcomming .INCProgram_btn_text_holder {
        width: 200px;
        margin-right: 15px;
        height: 40px;
        margin-top: 0px;
    }



}

@media screen and ((max-width: 620px)) {

    p.INCLanding_tagline {
        font-family: DM Sans;
        font-size: 18px;
        font-weight: 400;
        line-height: 26.1px;
        letter-spacing: 0.06em;
        text-align: center;
    }

    p.INCLanding_tagline span {
        font-family: DM Sans;
        font-size: 18px;
        font-weight: 400;
        line-height: 26.1px;
        letter-spacing: 0.06em;
        text-align: center;
    }

    svg.INCLandingPage_text_bigStar {
        position: absolute;
        top: -20px;
        right: -10px;
    }

    svg.INCLandingPage_text_smallStar {
        position: absolute;
        left: 58px;
        bottom: -15px;
    }

    .INCLanding_offer {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0.02em;
        text-align: center;
    }

    .INCLanding_moto {
        font-family: DM Serif Display;
        font-size: 26px;
        font-weight: 400;
        line-height: 48px;
        letter-spacing: 0.02em;
        text-align: left;
        margin-left: 7px;
    }

    .INCLanding_text_Container {
        width: 75%;
    }

    .INCLanding_moto {
        font-family: DM Serif Display;
        font-size: 26px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0.02em;
        text-align: left;
        margin-left: 7px;
    }

    .INCLanding_tagline_paraEnd {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0.02em;
        text-align: center;

    }

    .INCLanding_tagline_paraEnd span {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0.02em;
        text-align: center;
    }

    .INCLanding_content_container {
        margin-top: 80px;
    }

    .INCLanding_text {
        height: unset;
        margin-bottom: unset;
    }

    .INCLanding_kidImage_Container {
        margin-top: 45px;
        margin-right: 0px;
    }

    /* .INCLanding_kidImage_Container img {
        width: 20vw;
        height: auto;
    } */
}

@media screen and ((max-width:620px)) {
    .INCProgram_techLogo img {
        margin-right: 20px;
        height: 25px;
    }

    .INCProgram_techHead {
        font-size: 1.3rem;
        line-height: 22px;
        margin-right: 30px;
    }

    .INCTabprogram_info_content_holder {
        margin-left: 15px;
    }

    .INCProgram_moto {
        font-size: 13px;
        line-height: 16.8px;
        margin-top: 8px;
        margin-right: 30px;
    }

    .INCContent_count {
        font-size: 12px;
        line-height: 13px;
        margin-left: 7px;
    }

    .INCContent_count span {
        font-size: 20px;
        font-weight: 400;
        line-height: 20px;
    }

    .INCProgram_content {
        width: 635px;
        height: 45px;
        margin-top: 12px;
    }

    .INCProgram_content {
        justify-content: flex-start;
        gap: 4%;
        width: 100%;
        margin-right: 0px;
    }


    .INCProgram_heading {
        margin-top: 10px;
    }

    .INCContent_count span {
        font-size: 8px;
    }

    .INCProgram_btn_text {
        font-size: 10.18px;
        gap: 2px;
    }

    .INCProgram_btn_text_holder {
        height: 27px;
        width: 100px;
    }

    .INCTabprogram_info_background_holder {
        height: 60%;
        width: 97%;
        background-size: 100% 80%;
    }

    .INCProgram_techLogo {
        right: 0px;
        top: -50px;
    }

    .INCProgramtags {
        width: 100px;
        height: 33px;
        top: -15px;
        font-size: 14.02px;
        line-height: 24.03px;
        border-radius: 5px;
    }

    .INCTabprogram_info_content_holder_upcomming .INCProgram_btn_text_holder {
        width: 150px;
        height: 30px;
        margin-top: 0px;
    }
}
.INCWhoWeAre {
    min-height: 80vh;
    width: 100vw;
    /*gredient box background*/
    background: url("/public/assets/images/about/whoweare/6.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
}

.INCWhoWeAre_heading {
    font-family: Poppins;
    font-size: 42px;
    font-weight: 600;
    line-height: 60px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 20px;
}

.INCWhoWeAre_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 7%;
}

.INCWhoWeAre_heading span {
    color: #00C2FF;
}

.INCWhoWeAre_text {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 400;
    line-height: 30.6px;
    letter-spacing: 0.03em;
    text-align: center;
    color: #FFFFFFCC;
}

.INCWhoWeAre_text_container {
    width: 80%;
}

.INCWhoWeAre_boxes_container {
    display: flex;
    justify-content: center;
    margin-top: 3%;
    gap: 3%;
    width: 100%;
}

.INCWhoWeAre_box_container {
    width: 550px;
    height: 280px;
    border-radius: 30px;
    background: linear-gradient(112.08deg, #313843 0%, #101926 100%);
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    gap: 5%;
    position: relative;
}

.INCWhoWeAre_box_text_container {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 25.6px;
    letter-spacing: 0.03em;
    text-align: center;
    color: #FFFFFFCC;
    margin: 0px 20px;
}

.INCWhoWeAre_vertical {
    rotate: 90deg;
    width: 60px;
    box-shadow: 0px 0px 3px 0px #00C2FF;
    background: #00C2FF;
    margin: 0;
    position: absolute;
    height: 5px;
    border-radius: 30px;
    left: -6%;
    top: 25%;
    border: 0px;
}

@media screen and (max-width:1600px) {
    .INCWhoWeAre_heading {
        font-size: 38px;
        line-height: 45px;
        margin-bottom: 16px;
    }

    .INCWhoWeAre_text {
        font-size: 18px;
        line-height: 28.6px;

    }

    .INCWhoWeAre_box_heading_container .svg1 {
        width: 135px;
        height: auto;
    }

    .INCWhoWeAre_box_heading_container .svg2 {
        width: 152px;
        height: auto;
    }

    .INCWhoWeAre_box_text_container {
        font-size: 16px;
        line-height: 23.6px;
        margin: 0px 24px;
    }

    .INCWhoWeAre_box_container {
        width: 540px;
        height: 250px;

    }
}

@media screen and ((max-width:1367px)) {
    .INCWhoWeAre_heading {
        font-size: 32px;
        line-height: 41px;
    }

    .INCWhoWeAre_text {
        font-size: 16px;
        line-height: 25.6px;
    }

    .INCWhoWeAre_box_heading_container .svg1 {
        width: 122px;
    }

    .INCWhoWeAre_box_heading_container .svg2 {
        width: 140px;
        height: auto;
    }

    .INCWhoWeAre_box_text_container {
        font-size: 14px;
        line-height: 20.6px;
        margin: 0px 24px;
    }

    .INCWhoWeAre_box_container {
        width: 460px;
        height: 225px;
    }
}

@media screen and ((max-width:1024px)) {

    .INCWhoWeAre_text {
        font-size: 22px;
        line-height: 37.6px;
    }

    .INCWhoWeAre_boxes_container {
        margin-top: 3%;
        gap: 25px;
        flex-direction: column;
        align-content: center;
        align-items: center;
    }

    .INCWhoWeAre_box_heading_container .svg1 {
        width: 140px;
    }

    .INCWhoWeAre_box_text_container {
        font-size: 18px;
        line-height: 24.6px;
        margin: 0px 24px;
    }

    .INCWhoWeAre_box_container {
        width: 460px;
        height: 270px;
    }

    .INCWhoWeAre_vertical {
        width: 70px;
        left: -8%;
    }

    .INCWhoWeAre_heading {
        font-size: 35px;
        line-height: 41px;
        margin-top: 50px;
    }
}

@media screen and (max-width:620px) {
    .INCWhoWeAre_heading {
        font-size: 25px;
        line-height: 55px;
        margin-top: 30px;
        margin-bottom: 0px;
    }

    .INCWhoWeAre_text {
        font-size: 12px;
        line-height: 21.6px;
    }

    .INCWhoWeAre_box_heading_container .svg1 {
        width: 100px;
    }

    .INCWhoWeAre_box_heading_container .svg2 {
        width: 110px;
        height: auto;
    }

    .INCWhoWeAre_box_text_container {
        font-size: 12px;
        line-height: 19.2px;
        margin: 0px 24px;
    }

    .INCWhoWeAre_box_container {
        width: 300px;
        height: 200px;
    }

    .INCWhoWeAre_vertical {
        width: 53px;
        left: -9%;
        height: 3px;
    }

    .INCWhoWeAre_box_container {
        width: 300px;
        height: 200px;
        border-radius: 18px;
    }

    .INCWhoWeAre_boxes_container {
        margin-top: 7%;

    }
}